import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const screenWidth = window.innerWidth;
// console.log("Screen width:", screenWidth);

const PEODUCT_CATEGORY_LIST = [
  //   { value: "all", label: "ทั้งหมด" },
  { value: "64d3230f92e632cfcf7be58c", label: "ปูนซีเมนต์, อิฐมวลเบา" },
  { value: "64d3251c92e632cfcf7be5a0", label: "ผลิตภัณฑ์คอนกรีต" },
  { value: "64d3254392e632cfcf7be5a4", label: "ถังเก็บน้ำ, ถังบำบัด" },
  { value: "64d3255f92e632cfcf7be5aa", label: "ปั้มน้ำ" },
  { value: "64d3256e92e632cfcf7be5ae", label: "ผลิตภัณฑ์ตราเพชร" },
  { value: "652cc2fee6867d835d1b4046", label: "ตราเพชร (บอร์ด)" },
  { value: "652cc3e0f9d95317b7642cad", label: "ตราเพชร (ไม้สังเคราะห์)" },
  { value: "64d3257492e632cfcf7be5b2", label: "อื่น ๆ" },
];

const formatMainCategoryQuotations = (quotations) => {
  let allCartList = [];
  for (const thisQuotation of quotations) {
    allCartList = allCartList?.concat(thisQuotation.cart_list);
  }

  let cartListGroupCategory = _.groupBy(allCartList, "main_category_id");

  let finalData = [];
  for (const categoryItem of PEODUCT_CATEGORY_LIST) {
    if (cartListGroupCategory?.[categoryItem?.value]) {
      let totalQty = cartListGroupCategory?.[categoryItem?.value]?.reduce(
        (perv, curr) => {
          // return perv + curr?.product_qty;
          const amountCoverRoof = curr?.amount_cover_roof || 0;
          return perv + curr?.product_qty + amountCoverRoof;
        },
        0
      );
      finalData?.push(totalQty);
    } else {
      finalData?.push(0);
    }
  }
  return finalData;
};

const ChartBar1 = ({ quotations, textMonth }) => {
  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          font: {
            size: screenWidth <= 1440 ? 7 : 10,
          },
          maxRotation: 0,
          autoSkip: false,
          minRotation: 0,
        },
      },
      y: {
        ticks: {
          font: {
            size: screenWidth <= 1440 ? 10 : 12,
          },
        },
        title: {
          display: true,
          text: "จำนวน",
          color: "#807F80",
          // align: "end",
          font: {
            // family: "Times",
            size: screenWidth <= 1440 ? 12 : 16,
            style: "normal",
            lineHeight: 1.2,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart",
      // },
    },
  };

  const resultData = formatMainCategoryQuotations(quotations);

  const data = {
    labels: PEODUCT_CATEGORY_LIST?.map((categoryItem) => categoryItem.label),
    datasets: [
      {
        label: `ยอดสั่งซื้อ (${textMonth})`,
        data: resultData,
        backgroundColor: "#31338E",
        barThickness: 30,
      },
    ],
  };

  return (
    <div className="">
      {/* <img src="/example-graph.png" /> */}
      <Bar options={options} data={data} />
    </div>
  );
};

const formatSubCategoryQuotations = (quotations, productCategory) => {
  let allCartList = [];
  for (const thisQuotation of quotations) {
    allCartList = allCartList?.concat(thisQuotation.cart_list);
  }

  let cartListGroupSubCategory = _.groupBy(allCartList, "curr_category_id");

  let finalData = [];
  const subCategoryProducts = Object.keys(cartListGroupSubCategory)
    ?.map((key) => {
      return {
        main_category_id:
          cartListGroupSubCategory?.[key]?.[0]?.main_category_id,
        label: cartListGroupSubCategory?.[key]?.[0]?.curr_category_name,
        value: key,
      };
    })
    ?.filter((item) => {
      return item?.main_category_id === productCategory;
    });

  for (const categoryItem of subCategoryProducts) {
    if (cartListGroupSubCategory?.[categoryItem?.value]) {
      let totalQty = cartListGroupSubCategory?.[categoryItem?.value]?.reduce(
        (perv, curr) => {
          // return perv + curr?.product_qty;
          const amountCoverRoof = curr?.amount_cover_roof || 0;
          return perv + curr?.product_qty + amountCoverRoof;
        },
        0
      );
      finalData?.push(totalQty);
    } else {
      finalData?.push(0);
    }
  }
  return {
    finalData,
    subCategoryProducts,
  };
};

const ChartBar2 = ({ quotations, textMonth, productCategory }) => {
  const options = {
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
          },
          maxRotation: 0,
          autoSkip: false,
          minRotation: 0,
        },
        title: {
          display: true,
          text: "จำนวน",
          color: "#807F80",
          // align: "end",
          font: {
            // family: "Times",
            size: screenWidth <= 1440 ? 12 : 16,
            style: "normal",
            lineHeight: 1.2,
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 9,
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart",
      // },
    },
  };

  const resultData = formatSubCategoryQuotations(quotations, productCategory);

  const data = {
    labels: resultData?.subCategoryProducts?.map(
      (categoryItem) => categoryItem.label
    ),
    datasets: [
      {
        label: `ยอดสั่งซื้อ (${textMonth})`,
        data: resultData?.finalData,
        backgroundColor: "#31338E",
        barThickness: 30,
      },
    ],
  };

  return (
    <div className="">
      {/* <img src="/example-graph.png" /> */}
      <Bar options={options} data={data} />
    </div>
  );
};

const ChartBar = ({ quotations, textMonth, productCategory }) => {
  if (productCategory === "all") {
    return <ChartBar1 quotations={quotations} textMonth={textMonth} />;
  }
  return (
    <ChartBar2
      quotations={quotations}
      textMonth={textMonth}
      productCategory={productCategory}
    />
  );
};

export default ChartBar;
