import { S3_URL } from "./constants";

export const FileType = {
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  mp4: "video/mp4",
  pdf: "application/pdf",
};

export const TypeFile = Object.keys(FileType).reduce((acc, key) => {
  const value = FileType[key];
  acc[value] = key;
  return acc;
}, {});

export const s3File = (key) => (key ? S3_URL + key : null);
