import React from "react";
import { s3File } from "../../utils/fileFormater";
import { CardProduct } from "../../components/Card/Cardmain";
import { ButtonBlue } from "../../components/Button/buttonmain";
import { useNavigate } from "react-router-dom";
import { ICON_ADD } from "../../utils/useIcons";
import SubCategoryLevel4View from "../ProductCategory/SubCategoryLevel4View";

const OtherSanitaryWaveProducts = ({
  categoryL2Id,
  categoryPath,
  subCategoryPath,
  isSanitaryWare,
  isHydrant,
  isShowerSet,
  //   isBathTub,
  //   isWaterHeater,
  isWashBasin,
  //   isHoseCleaner,
  isBathroomAccessories,
  products,
  clickViewProduct,
  clickEditProduct,
  clickDeleteProduct,
}) => {
  const navigate = useNavigate();

  const groupSenitaryWareProducts = _.groupBy(
    products,
    "group_sanitary_ware_name"
  );

  // Group สุขภัณฑ์
  const onePieceToiletProducts = groupSenitaryWareProducts.one_piece_toilet;
  const twoPieceToiletProducts = groupSenitaryWareProducts.two_piece_toilet;
  const forPublicRestroomProducts =
    groupSenitaryWareProducts.for_public_restroom;

  // Group ก๊อกน้ำ
  const coldWaterFaucetProducts = groupSenitaryWareProducts.cold_water_faucet;
  const mixerFaucetProducts = groupSenitaryWareProducts.mixer_faucet;

  // Group ชุดฝักบัว
  const softShowerProducts = groupSenitaryWareProducts.soft_shower;
  const rainShowerProducts = groupSenitaryWareProducts.rain_shower;

  // Check อ่างล้างหน้า
  const washBasinPlaceOnCounterProducts =
    groupSenitaryWareProducts.wash_basin_place_on_counter;
  const washBasinHungWallProducts =
    groupSenitaryWareProducts.wash_basin_hung_wall;
  const washBasinUnderCounterProducts =
    groupSenitaryWareProducts.wash_basin_under_counter;
  const washBasinHalfCounterProducts =
    groupSenitaryWareProducts.wash_basin_half_counter;
  const washBasinTopCounterProducts =
    groupSenitaryWareProducts.wash_basin_top_counter;

  return (
    <>
      {isSanitaryWare ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  สุขภัณฑ์แบบชิ้นเดียว
                </div>
              </div>
              {!onePieceToiletProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {onePieceToiletProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?onePieceToilet_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  สุขภัณฑ์แบบสองชิ้น
                </div>
              </div>
              {!twoPieceToiletProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {twoPieceToiletProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?twoPieceToilet_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  สุขภัณฑ์สำหรับห้องน้ำสาธารณะ
                </div>
              </div>
              {!forPublicRestroomProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {forPublicRestroomProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?forPublicRestroom_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isHydrant ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  ก๊อกน้ำเย็น
                </div>
              </div>
              {!coldWaterFaucetProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {coldWaterFaucetProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?coldWaterFaucet_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  ก๊อกน้ำผสม
                </div>
              </div>
              {!mixerFaucetProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {mixerFaucetProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?mixerFaucet_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isShowerSet ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  ฝักบัวสายอ่อน
                </div>
              </div>
              {!softShowerProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {softShowerProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?softShower_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  เรนชาวเวอร์
                </div>
              </div>
              {!rainShowerProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {rainShowerProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?rainShower_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isWashBasin ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  อ่างล้างหน้าวางบนเคาน์เตอร์
                </div>
              </div>
              {!washBasinPlaceOnCounterProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {washBasinPlaceOnCounterProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?washBasinPlaceOnCounter_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  อ่างล้างหน้าแบบแขวนผนัง
                </div>
              </div>
              {!washBasinHungWallProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {washBasinHungWallProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?washBasinHungWall_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  อ่างล้างหน้าแบบฝังใต้เคาน์เตอร์
                </div>
              </div>
              {!washBasinUnderCounterProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {washBasinUnderCounterProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?washBasinUnderCounter_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  อ่างล้างหน้าแบบฝังครึ่งเคาน์เตอร์
                </div>
              </div>
              {!washBasinHalfCounterProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {washBasinHalfCounterProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?washBasinHalfCounter_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="flex flex-col gap-6 justify-center items-center">
              <div className="w-full flex justify-start ">
                <div className="text-[28px] font-bold text-primary">
                  อ่างล้างหน้าแบบฝังบนเคาน์เตอร์
                </div>
              </div>
              {!washBasinTopCounterProducts ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {washBasinTopCounterProducts
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() => clickEditProduct(product._id)}
                          onClickDelete={() => clickDeleteProduct(product)}
                        />
                      );
                    })}
                </div>
              )}
              <div className="flex flex-col items-center gap-2 mt-5 w-full">
                <ButtonBlue
                  onClick={() => {
                    navigate(
                      `/product/${categoryPath}/${subCategoryPath}/add?washBasinTopCounter_${categoryL2Id}`
                    );
                  }}
                  title="เพิ่มสินค้า"
                  icon={<ICON_ADD className=" text-white w-5 h-5" />}
                />
                <div className="text-primary_gray text-sm font-light">
                  (สามารถเพิ่มสินค้าเองได้)
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isBathroomAccessories ? (
        <SubCategoryLevel4View />
      ) : (
        <div>Page Not Found.</div>
      )}
    </>
  );
};

export default OtherSanitaryWaveProducts;
