import React, { useState, useEffect } from "react";
import { useUserStore } from "../../_store/userStore";
import { AppApi } from "../../api";
import { useNavigate } from "react-router-dom";
import { STYLE_CARD } from "../../utils/useStyle";
import { InputMain } from "../../components/Input/inputmain";
import { ButtonBlue } from "../../components/Button/buttonmain";
import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

const Signin = () => {
  const navigate = useNavigate();

  const isMobileMode = window.innerWidth < 767;

  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/overview");
    }
  }, [user, navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const username = e.target.username.value;
      const password = e.target.password.value;
      const { data } = await AppApi.post(`/users/login`, {
        username,
        password,
      });

      if (data._id) {
        setUser(data);
      } else {
        setError(true);
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpen = () => setOpen(!open);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div
      className=" bg-fixed  w-full min-h-screen object-cover relative "
      style={{
        backgroundImage: `url('/background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // paddingTop: "251px",
        zindex: "100",
      }}
    >
      {isMobileMode ? (
        <div className="flex flex-col items-center justify-center px-6 mx-auto h-screen">
          <div className={`${STYLE_CARD} max-w-2xl w-full`}>
            <img
              src="/Logo-sidebar.png"
              className=" w-[115px] mx-auto"
              alt="Thananan Logo"
            />
            <div
              className="mt-8 space-y-6 text-center font-light text-primary_gray text-xl"
              action="#"
            >
              ไม่รองรับขนาด Mobile
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center px-6 mx-auto h-screen">
          <div className={`${STYLE_CARD} max-w-2xl w-full`}>
            <img
              src="/Logo-sidebar.png"
              className=" w-[115px] mx-auto"
              alt="Thananan Logo"
            />
            <form className="mt-8 space-y-6" action="#" onSubmit={onSubmit}>
              <InputMain
                title="Username"
                name="username"
                id="username"
                placeholder="กรุณากรอก Username"
                error={error}
              />
              <div className="relative">
                <InputMain
                  title="Password"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="กรุณากรอก Password"
                  error={error}
                />
                <div className=" absolute bottom-8 right-10 cursor-pointer">
                  {passwordShown ? (
                    <svg
                      onClick={togglePassword}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 absolute"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      onClick={togglePassword}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 absolute"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  )}
                </div>
              </div>
              <ButtonBlue type="submit" title="LOGIN" isLoading={isLoading} />

              <Dialog
                size="sm"
                open={open}
                handler={handleOpen}
                animate={{
                  mount: { scale: 1, y: 0 },
                  unmount: { scale: 0.9, y: -100 },
                }}
                className="p-[20px] rounded-[20px] relative"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className=" absolute right-[20px] cursor-pointer"
                  onClick={handleOpen}
                >
                  <path
                    d="M15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5Z"
                    stroke="#4A4A4A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.4624 18.5375L18.5374 11.4625"
                    stroke="#4A4A4A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.5374 18.5375L11.4624 11.4625"
                    stroke="#4A4A4A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <DialogHeader className="text-primary_red text-[36px] flex justify-center">
                  <span>เข้าสู่ระบบไม่สำเร็จ</span>
                </DialogHeader>
                <DialogBody className="bg-[#f8f8f8] rounded-[10px] pt-[20px] pb-[20px] text-[16px] text-primary_gray flex flex-col items-center">
                  <span>Username หรือ Password ไม่ถูกต้อง</span>
                  <span>กรุณาลองใหม่อีกครั้ง</span>
                </DialogBody>
                <div className="flex justify-center mt-[20px]">
                  <button
                    onClick={handleOpen}
                    className="bg-primary border-2 border-primary rounded-full w-full h-[45px] text-white text-[16px] hover:text-primary hover:bg-white duration-300"
                  >
                    <span>ยืนยัน</span>
                  </button>
                </div>
              </Dialog>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signin;
