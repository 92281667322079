import { Outlet, useLocation } from "react-router-dom";
import AppSidebar from "./Sidebar";
import AppNavbar from "./Navbar";
import ScrollToTop from "./ScrollToTop";

const Layout = () => {
  const { pathname } = useLocation();

  const hideNavbar = pathname === "/";
  const hideSidebar = pathname === "/";

  return (
    <div className="flex">
      <ScrollToTop />
      {!hideSidebar && <AppSidebar />}
      {/* {!hideSidebar && <AppSidebar2 />} */}
      <div className="flex-1">
        {!hideNavbar && <AppNavbar />}
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
