import React from "react";

const PEODUCT_CATEGORY_LIST = [
  // { value: "all", label: "ทั้งหมด" },
  { value: "64d3230f92e632cfcf7be58c", label: "ปูนซีเมนต์, อิฐมวลเบา" },
  { value: "64d3251c92e632cfcf7be5a0", label: "ผลิตภัณฑ์คอนกรีต" },
  { value: "64d3254392e632cfcf7be5a4", label: "ถังเก็บน้ำ, ถังบำบัด" },
  { value: "64d3255f92e632cfcf7be5aa", label: "ปั้มน้ำ" },
  { value: "64d3256e92e632cfcf7be5ae", label: "ผลิตภัณฑ์ตราเพชร" },
  { value: "652cc2fee6867d835d1b4046", label: "ตราเพชร (บอร์ด)" },
  { value: "652cc3e0f9d95317b7642cad", label: "ตราเพชร (ไม้สังเคราะห์)" },
  { value: "64d3257492e632cfcf7be5b2", label: "อื่น ๆ" },
];

const formatDataSubCategory = (dataQuotations) => {
  let allCartList = [];
  for (const quotation of dataQuotations) {
    allCartList = allCartList?.concat(quotation.cart_list);
  }

  let cartListGroupSubCategory = _.groupBy(allCartList, "curr_category_id");

  const subCategoryProducts = Object.keys(cartListGroupSubCategory)?.map(
    (key) => {
      return {
        main_category_id:
          cartListGroupSubCategory?.[key]?.[0]?.main_category_id,
        label: cartListGroupSubCategory?.[key]?.[0]?.curr_category_name,
        value: key,
      };
    }
  );

  let dataSubCategory = [];

  for (const categoryItem of subCategoryProducts) {
    if (cartListGroupSubCategory?.[categoryItem?.value]) {
      let totalQty = cartListGroupSubCategory?.[categoryItem?.value]?.reduce(
        (perv, curr) => {
          // return perv + curr?.product_qty;
          const amountCoverRoof = curr?.amount_cover_roof || 0;
          return perv + curr?.product_qty + amountCoverRoof;
        },
        0
      );
      dataSubCategory?.push({
        main_category_id: categoryItem?.main_category_id,
        sub_category_name: categoryItem?.label,
        product_qty: totalQty,
      });
    } else {
      dataSubCategory?.push({
        main_category_id: categoryItem?.main_category_id,
        sub_category_name: categoryItem?.label,
        product_qty: 0,
      });
    }
  }

  let dataMainCategory = _.groupBy(dataSubCategory, "main_category_id");

  let finalData = [];

  for (const keyId of Object.keys(dataMainCategory)) {
    const findMainCategory = PEODUCT_CATEGORY_LIST?.find((item) => {
      return keyId === item?.value;
    });

    dataMainCategory[keyId] = {
      ...findMainCategory,
      items: dataMainCategory?.[keyId],
    };

    finalData.push(dataMainCategory[keyId]);
  }

  return finalData;
};

const ProductList = ({ quotations, productCategory, loading }) => {
  const notConfirmQuotations = quotations?.filter(
    (item) => item?.quotation_status === "not-confirm"
  );

  const resultData = formatDataSubCategory(notConfirmQuotations);

  const finalResultData =
    productCategory !== "all"
      ? resultData?.filter((item) => {
          return item?.value === productCategory;
        })
      : resultData;

  return (
    <div className="text-primary_gray h-full overflow-y-auto p-2.5">
      <div className="flex flex-col gap-2.5">
        <div className="flex justify-between items-center font-semibold text-sm">
          <div className="flex justify-start">รายการสินค้า</div>
          <div className="flex justify-end">จำนวน</div>
        </div>

        {loading ? (
          <div>Loading . . .</div>
        ) : (
          finalResultData?.map((itemData, index) => {
            return (
              <div key={index} className="text-xs">
                <div className="font-semibold">{itemData?.label}</div>
                {itemData?.items?.map((item, index) => {
                  return (
                    <ul
                      key={index}
                      className="flex justify-between items-center list-disc pl-4"
                    >
                      <li>{item?.sub_category_name}</li>
                      <div>{item?.product_qty}</div>
                    </ul>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ProductList;
