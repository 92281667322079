import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useSweetAlert from "../../_hooks/useSweetAlert";
import { AppApi } from "../../api";
import { HiPencil, HiTrash } from "react-icons/hi2";
import { TypeFile, s3File } from "../../utils/fileFormater";
import { Storage } from "aws-amplify";
import Container from "../../components/Container";
import {
  IMAGE_TYPE_LIST,
  CEMENT_CATEGORY_ID,
  GROUP_CEMENT,
  CONCRETE_CATEGORY_ID,
  PRESET_SIZE_CONCRETE_LIST,
  CONCRETE_PILES_ID,
  I_PILES_ID,
  SQUARE_PILES_ID,
  HOLLOW_HEXAGONAL_PILES_ID,
  PUMP_CATEGORY_ID,
  TANK_CATEGORY_ID,
  NATURA_WATER_PAC_ID,
  DIAMOND_CATEGORY_ID,
  COLOR_TYPE_LIST,
  DIAMOND_DECHRA_SENATOR_SHINGLE_ID,
  DIAMOND_DECHRA_MILANO_ID,
  COLOR_TYPE_LIST_OF_DECHRA,
  DIAMOND_BOARD_ID,
  DIAMOND_SYNTHETIC_WOOD_ID,
  DIAMOND_TOOLS_ID,
  OTHER_PRODUCT_CATEGORY_ID,
  BATH_TUB_ID,
  WATER_HEATER_ID,
  HOSE_CLEANER_ID,
  BATHROOM_ACCESSORIES_ID,
  OTHER_CHEMICAL_PRODUCT_ID,
  OTHER_DOOR_AND_FRAMEDOOR_ID,
  THAI_MODERN_ID,
  THAI_CLASSIC_ID,
  ELEGANT_ID,
} from "../../utils/constants";
import { Select, Option } from "@material-tailwind/react";
import { InputMain, InputMainTextArea } from "../../components/Input/inputmain";
import {
  errorScroll,
  formatBytes,
  functionFormProductCheckIsNaturaWaterPac,
  functionFormProductCheckPumpMode,
  functionFormProductCheckSanitaryProductMode,
} from "../../utils/functions";
import FieldWysiwyg from "../../components/Wysiwyg";
import { ICON_DELETE } from "../../utils/useIcons";

const FormProduct = () => {
  const params = useParams();
  const location = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const formMode = location?.search?.split("?")[1];
  const formSenitaryWareMode = formMode?.split("_")[0];

  // Check สุขภัณฑ์ mode
  const isOnePieceToiletMode = formSenitaryWareMode === "onePieceToilet";
  const isTwoPieceToiletMode = formSenitaryWareMode === "twoPieceToilet";
  const isForPublicRestroomMode = formSenitaryWareMode === "forPublicRestroom";

  // Check ก๊อกน้ำ mode
  const isColdWaterFaucetMode = formSenitaryWareMode === "coldWaterFaucet";
  const isMixerFaucetMode = formSenitaryWareMode === "mixerFaucet";

  // Check ชุดฝักบัว mode
  const isSoftShowerMode = formSenitaryWareMode === "softShower";
  const isRainShowerMode = formSenitaryWareMode === "rainShower";

  // Check อ่างล้างหน้า mode
  const isWashBasinPlaceOnCounterMode =
    formSenitaryWareMode === "washBasinPlaceOnCounter";
  const isWashBasinHungWallMode = formSenitaryWareMode === "washBasinHungWall";
  const isWashBasinUnderCounterMode =
    formSenitaryWareMode === "washBasinUnderCounter";
  const isWashBasinHalfCounterMode =
    formSenitaryWareMode === "washBasinHalfCounter";
  const isWashBasinTopCounterMode =
    formSenitaryWareMode === "washBasinTopCounter";

  //---------------------------------- Check Cement Mode ----------------------------------------//
  const isFormCementMode = formMode === "cement";
  const isFormMortarMode = formMode === "mortar";

  //----------------------------------- Check Pump Mode ----------------------------------------//
  const isMitsubishiConstantPressureMode =
    formMode === "mitsubishi_constant_pressure";
  const isMitsubishiAutomaticMode = formMode === "mitsubishi_automatic";

  const isHitachiXxSeriesMode = formMode === "hitachi_xx_series";
  const isHitachiAutomaticInverterMode =
    formMode === "hitachi_automatic_inverter";
  const isHitachiAutomaticXxSeriesMode =
    formMode === "hitachi_automatic_xx_series";
  const isHitachiPressureStainlessMode =
    formMode === "hitachi_pressure_stainless";
  const isHitachiSuckSmallMode = formMode === "hitachi_suck_small";

  const isGrundfosAutomaticMode = formMode === "grundfos_automatic";
  const isGrundfosAutomaticInverterMode =
    formMode === "grundfos_automatic_inverter";
  //--------------------------------------------------------------------------------------------//

  const locationSreach = location.search.split("_");
  const categoryL2Id = locationSreach[1];
  const hasNaturaPumpName = locationSreach[2];
  const isNaturaPump =
    hasNaturaPumpName === "NaturaGrundfosPump" ||
    hasNaturaPumpName === "NaturaHitachiPump" ||
    hasNaturaPumpName === "NaturaMitsubishiPump";

  const productId = params.id;
  const categoryPath = params.categoryPath;
  const subCategoryPath = params.subCategoryPath;

  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryL2Id, setSubCategoryL2Id] = useState("");

  // Check Main Category
  const isCementProduct = categoryId === CEMENT_CATEGORY_ID;
  const isConcreteProduct = categoryId === CONCRETE_CATEGORY_ID;
  const isPumpProduct = categoryId === PUMP_CATEGORY_ID;
  const isTankProduct = categoryId === TANK_CATEGORY_ID;
  const isDiamondProduct = categoryId === DIAMOND_CATEGORY_ID;
  const isDiamondBoardProduct = categoryId === DIAMOND_BOARD_ID;
  const isDiamondSyntheticWoodProduct =
    categoryId === DIAMOND_SYNTHETIC_WOOD_ID;
  const isOtherProduct = categoryId === OTHER_PRODUCT_CATEGORY_ID;

  // Other Product Category Level 2
  const isChemicalProduct = subCategoryL2Id === OTHER_CHEMICAL_PRODUCT_ID;
  const isDoorAndFrameDoorProduct =
    subCategoryL2Id === OTHER_DOOR_AND_FRAMEDOOR_ID;

  // Concrete Piles
  const isConcretePiles = categoryL2Id === CONCRETE_PILES_ID;
  const isIPiles = subCategoryId === I_PILES_ID;
  const isSquarePiles = subCategoryId === SQUARE_PILES_ID;
  const isHollowHexaonalPiles = subCategoryId === HOLLOW_HEXAGONAL_PILES_ID;

  // Natura Water PAC
  const isNaturaWaterPac = subCategoryId === NATURA_WATER_PAC_ID;

  // Sub Diamond Category
  const isDiamondTools = categoryL2Id === DIAMOND_TOOLS_ID;

  // Check Diamond Jiaranai Sub Category
  const isThaiModern = subCategoryId === THAI_MODERN_ID;
  const isThaiClassic = subCategoryId === THAI_CLASSIC_ID;
  const isElegant = subCategoryId === ELEGANT_ID;

  const isDiamondDechraSenatorShingle =
    subCategoryId === DIAMOND_DECHRA_SENATOR_SHINGLE_ID;
  const isDiamondDechraMilano = subCategoryId === DIAMOND_DECHRA_MILANO_ID;

  // Diamond Roof Cover
  const isRoofCover = locationSreach[2] === "isRoofCover";
  const isSkuRoofCover = locationSreach[2] === "isSkuRoofCover";
  const masterId = locationSreach[3];
  const roofCoverStandardType = locationSreach[3] === "standard";
  const roofCoverCurvedRidgeType = locationSreach[3] === "curvedRidge";

  // Door And Frame Door Sku Product
  const isSkuDoorFrameDoor = locationSreach[2] === "isSkuDoorFrameDoor";

  // Check Sub Category Level 3 ID
  const isBathTubProduct = subCategoryId === BATH_TUB_ID;
  const isWaterHeaterProduct = subCategoryId === WATER_HEATER_ID;
  const isHoseCleanerProduct = subCategoryId === HOSE_CLEANER_ID;
  const isBathroomAccessories = categoryL2Id === BATHROOM_ACCESSORIES_ID;

  const [parentCategoryTitle, setParentCategoryTitle] = useState("");
  const [subCategoryTitle, setSubCategoryTitle] = useState("");

  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();

  const isCreate = productId === "add";
  const isEdit = productId.length === 24;
  const [saving, setSaving] = useState(false);

  const [title, setTitle] = useState("");
  const [numberTis, setNumberTis] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [feature, setFeature] = useState("");
  const [indexNumber, setIndexNumber] = useState("1");
  const [hashtag, setHashtag] = useState("");

  const [colorType, setColorType] = useState("standard_color");
  const [colorName, setColorName] = useState("");

  const [groupCementName, setGroupCementName] = useState("cement");

  const [groupPumpName, setGroupPumpName] = useState("");
  const [pumpName, setPumpName] = useState("");

  const [groupSanitaryWareName, setGroupSanitaryWareName] = useState("");
  const [sanitaryWareName, setSanitaryWareName] = useState("");

  // NATURA WATER PAC PUMP
  const [groupNaturaPumpName, setGroupNaturaPumpName] = useState("none");
  const [naturaPumpName, setNaturaPumpName] = useState("");
  const [isEditNaturaPump, setIsEditNaturaPump] = useState(false);

  //-------------------------------------------------------------------------------------//

  const [filePDF, setFilePDF] = useState(null);
  const [filePDFName, setFilePDFName] = useState("");

  const [groupCategoryName, setGroupCategoryName] = useState("");

  const [sizeList, setSizeList] = useState([
    {
      size_name: "",
    },
  ]);

  const [hasImage, setHasImage] = useState(false);
  const [hasFeature, setHasFeature] = useState(false);

  const inputImageRef = useRef();
  const inputPDFRef = useRef();

  useEffect(() => {
    let ignore = false;
    if (!ignore && isEdit) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`products/${productId}`);
          const product = data.product;
          setTitle(product.product_name);

          if (product.number_tis === "none") {
            setNumberTis("");
          } else {
            setNumberTis(product.number_tis);
          }

          setImage(s3File(product.image_key));
          setFilePDF(s3File(product?.pdf_file?.pdf_key));
          setFilePDFName(product?.pdf_file?.pdf_name);
          setDescription(product.description);
          setFeature(product.feature);
          setIndexNumber(product.number_index);
          setSizeList(product.size);
          setGroupCementName(product.group_cement_name);
          setGroupPumpName(product.group_pump_name);
          setGroupSanitaryWareName(product.group_sanitary_ware_name);
          setGroupNaturaPumpName(product.group_natura_pump_name);
          setColorType(product.color_type);
          setHashtag(product.hashtag?.join(","));

          checkIsEditNatura(product.group_natura_pump_name);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [productId]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`categorys/${categoryPath}`);
          const category = data.category;
          setCategoryId(category._id);
          setParentCategoryTitle(category.category_name);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [categoryPath]);

  useEffect(() => {
    let ignore = false;
    setLoading(true);
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`categorys/${subCategoryPath}`);
          const category = data.category;
          setSubCategoryId(category._id);
          setSubCategoryTitle(category.category_name);
          setGroupCategoryName(category.group_category_name);

          const { data: dataSubCategoryL3 } = await AppApi.get(
            `categorys/${category.parent_category_id}`
          );
          setSubCategoryL2Id(dataSubCategoryL3.category.parent_category_id);

          if (category.group_category_name === "brick" && !isEdit) {
            setSizeList([
              {
                size_name: "20x60x7.5 ซม.",
              },
            ]);
          }

          if (isConcreteProduct && !isEdit) {
            let sizeProduct = [];

            sizeProduct = PRESET_SIZE_CONCRETE_LIST.find((item) => {
              return category._id === item.id;
            });

            if (sizeProduct?.presetSize) {
              setSizeList(sizeProduct?.presetSize);
            }
          }

          if (isFormCementMode) {
            setGroupCementName("cement");
          } else if (isFormMortarMode) {
            setGroupCementName("mortar");
          }

          functionFormProductCheckPumpMode({
            isMitsubishiConstantPressureMode,
            isMitsubishiAutomaticMode,
            isHitachiXxSeriesMode,
            isHitachiAutomaticInverterMode,
            isHitachiAutomaticXxSeriesMode,
            isHitachiPressureStainlessMode,
            isHitachiSuckSmallMode,
            isGrundfosAutomaticMode,
            isGrundfosAutomaticInverterMode,
            setGroupPumpName,
            setPumpName,
          });

          functionFormProductCheckIsNaturaWaterPac({
            isNaturaWaterPac,
            hasNaturaPumpName,
            setGroupNaturaPumpName,
            setNaturaPumpName,
          });

          if (isDiamondDechraSenatorShingle) {
            setColorType("senator_shingle");
          } else if (isDiamondDechraMilano) {
            setColorType("milano");
          }

          functionFormProductCheckSanitaryProductMode({
            isOnePieceToiletMode,
            isTwoPieceToiletMode,
            isForPublicRestroomMode,
            isColdWaterFaucetMode,
            isMixerFaucetMode,
            isSoftShowerMode,
            isRainShowerMode,
            isWashBasinPlaceOnCounterMode,
            isWashBasinHungWallMode,
            isWashBasinUnderCounterMode,
            isWashBasinHalfCounterMode,
            isWashBasinTopCounterMode,
            setGroupSanitaryWareName,
            setSanitaryWareName,
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      if (categoryId) {
        load();
      }
    }
    return () => {
      ignore = true;
    };
  }, [subCategoryPath, categoryId, subCategoryId]);

  if (isSkuRoofCover) {
    useEffect(() => {
      let ignore = false;
      if (!ignore) {
        const load = async () => {
          try {
            const { data } = await AppApi.get(`products/${masterId}`);
            setColorName(data.product.product_name);
          } catch (error) {
            console.log(error);
          }
        };
        load();
      }
      return () => {
        ignore = true;
      };
    }, [masterId]);
  }

  const checkIsEditNatura = (groupNaturaPumpName) => {
    if (
      groupNaturaPumpName === "natura_grundfos_pump" ||
      groupNaturaPumpName === "natura_hitachi_pump" ||
      groupNaturaPumpName === "natura_mitsubishi_pump"
    ) {
      setIsEditNaturaPump(true);
    }
  };

  const clearForm = () => {
    setTitle("");
    setNumberTis("");
    setImage(null);
    setDescription("");
    setFeature("");
    setGroupCementName("");
    setSizeList([]);
  };

  const onChangeImage = (e) => {
    if (!e.target.value) return;
    const type = e.target.files[0].type;
    if (!IMAGE_TYPE_LIST.includes(type)) {
      alertError("รองรับไฟล์ PNG หรือ JPEG");
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;

        if (width <= 334 && height <= 334) {
          // Valid image file
          // Perform further actions here
          setImage(file);
          setHasImage(false);
        } else {
          // Invalid image file
          alertError("รองรับไฟล์ขนาดไม่เกิน 334x334");
        }
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const onChangePDF = (e) => {
    if (!e.target.value) return;

    const type = e.target.files[0].type;

    if (!["application/pdf"].includes(type)) {
      alertError("รองรับไฟล์ PDF");
      return;
    }

    const fileSize = e.target.files[0].size; // Get the size of the file in bytes

    // Convert bytes to human-readable format (e.g., KB, MB)
    const fileSizePdfFormatted = formatBytes(fileSize);

    // console.log(`fileSizePdfFormatted : ${fileSizePdfFormatted} MB`);

    if (fileSizePdfFormatted > 50) {
      alertError("รองรับไฟล์ขนาดไม่เกิน 50 MB");
      return;
    }

    setFilePDFName(e.target.files[0].name);
    setFilePDF(e.target.files[0]);
  };

  if (loading) {
    return (
      <div className="bg-[#f9f9f9] w-full h-full">
        <Container>
          <div>loading . . .</div>
        </Container>
      </div>
    );
  }

  if (!isCreate && !isEdit) {
    return <div>Not Found</div>;
  }

  const handleGroupChange = (val) => {
    setGroupCementName(val);
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!image) {
      setHasImage(true);
      errorScroll("errorImage");
      return;
    }

    if (!feature && !isRoofCover) {
      setHasFeature(true);
      errorScroll("errorFeature");
      return;
    }

    setSaving(true);

    if (isCreate) {
      try {
        const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;

        await Storage.put(keyUpload, image);

        const payload = {
          category_ids: `${categoryId},${subCategoryId}`,
          product_name: title,
          // description,
          // feature,
          number_index: indexNumber,
          image_key: keyUpload,
        };

        if (!isRoofCover) {
          payload.description = description;
          payload.feature = feature;
        }

        if (
          isConcretePiles ||
          isTankProduct ||
          isDiamondProduct ||
          isOtherProduct
        ) {
          if (
            isBathroomAccessories ||
            isChemicalProduct ||
            isDoorAndFrameDoorProduct ||
            isSkuDoorFrameDoor
          ) {
            payload.category_ids = `${categoryId},${subCategoryL2Id},${categoryL2Id},${subCategoryId}`;
          } else {
            payload.category_ids = `${categoryId},${categoryL2Id},${subCategoryId}`;
          }
        }

        if (isIPiles) {
          payload.group_concrete_piles_name = "ipiles";
        } else if (isSquarePiles) {
          payload.group_concrete_piles_name = "squarepiles";
        } else if (isHollowHexaonalPiles) {
          payload.group_concrete_piles_name = "hollowhexaonalpiles";
        }

        if (isCementProduct && groupCategoryName === "cement") {
          payload.group_cement_name = groupCementName;
        }

        if (
          groupCategoryName === "brick" ||
          isConcreteProduct ||
          isTankProduct ||
          isDiamondProduct ||
          isDiamondBoardProduct ||
          isDiamondSyntheticWoodProduct ||
          isChemicalProduct ||
          (isDoorAndFrameDoorProduct && !isSkuDoorFrameDoor)
        ) {
          // payload.size = sizeList;
          if (!isNaturaPump) {
            payload.size = sizeList;
          }
        }

        if (isPumpProduct) {
          payload.group_pump_name = groupPumpName;
        }

        if (
          isOtherProduct &&
          !(
            isBathTubProduct ||
            isWaterHeaterProduct ||
            isHoseCleanerProduct ||
            isBathroomAccessories ||
            isChemicalProduct ||
            isDoorAndFrameDoorProduct ||
            isSkuDoorFrameDoor
          )
        ) {
          payload.group_sanitary_ware_name = groupSanitaryWareName;
        }

        if (isNaturaWaterPac) {
          payload.group_natura_pump_name = groupNaturaPumpName;
        }

        if (isDiamondProduct) {
          if (isRoofCover) {
            payload.is_roof_cover = "is_cover";
          } else if (isSkuRoofCover) {
            payload.master_id = masterId;
          } else {
            payload.color_type = colorType;
            payload.is_roof_cover = "is_roof";
          }
        }

        if (isSkuDoorFrameDoor) {
          payload.master_id = masterId;
        }

        if (roofCoverStandardType) {
          payload.roof_cover_type = "roof_cover_standard";
        } else if (roofCoverCurvedRidgeType) {
          payload.roof_cover_type = "roof_cover_curved_ridge";
        }

        if (numberTis) {
          payload.number_tis = numberTis;
        } else {
          payload.number_tis = "none";
        }

        if (hashtag) {
          const thisHashtag = hashtag?.split(",");
          const resultHashtag = thisHashtag?.map((el) =>
            el?.replace(/^\s+/gm, "")
          );
          payload.hashtag = resultHashtag;
        } else {
          payload.hashtag = [];
        }

        if (filePDF?.size > 0) {
          const keyUploadPdf = `${new Date().getTime()}.${
            TypeFile[filePDF.type]
          }`;

          await Storage.put(keyUploadPdf, filePDF, {
            contentType: "application/pdf",
            contentDisposition: "inline",
          });

          payload.pdf_file = {
            pdf_name: filePDFName,
            pdf_key: keyUploadPdf,
          };
        }

        await AppApi.post(`products`, payload);

        clearForm();
        e.target.reset();

        if (categoryL2Id) {
          alertSuccess("Create Success", "", () => {
            if (isSkuRoofCover) {
              navigate(
                `/roofCoverSkuProduct/${categoryPath}/${subCategoryPath}/${categoryL2Id}?masterId_${masterId}`
              );
            } else if (isSkuDoorFrameDoor) {
              navigate(
                `/doorFrameDoorSkuProduct/${categoryPath}/${subCategoryPath}/${categoryL2Id}?masterId_${masterId}`
              );
            } else {
              navigate(
                `/category/${categoryPath}/${subCategoryPath}?view?categoryL2Id_${categoryL2Id}`
              );
            }
          });
        } else {
          alertSuccess("Create Success", "", () =>
            navigate(`/category/${categoryPath}/${subCategoryPath}?view`)
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    } else {
      try {
        let payload = {
          category_ids: `${categoryId},${subCategoryId}`,
          product_name: title,
          // description,
          // feature: JSON.stringify(feature),
          // feature,
          number_index: indexNumber,
        };

        if (!isRoofCover) {
          payload.description = description;
          payload.feature = feature;
        }

        if (
          isConcretePiles ||
          isTankProduct ||
          isDiamondProduct ||
          isOtherProduct
        ) {
          if (
            isBathroomAccessories ||
            isChemicalProduct ||
            isDoorAndFrameDoorProduct ||
            isSkuDoorFrameDoor
          ) {
            payload.category_ids = `${categoryId},${subCategoryL2Id},${categoryL2Id},${subCategoryId}`;
          } else {
            payload.category_ids = `${categoryId},${categoryL2Id},${subCategoryId}`;
          }
        }

        if (isIPiles) {
          payload.group_concrete_piles_name = "ipiles";
        } else if (isSquarePiles) {
          payload.group_concrete_piles_name = "squarepiles";
        } else if (isHollowHexaonalPiles) {
          payload.group_concrete_piles_name = "hollowhexaonalpiles";
        }

        if (isCementProduct && groupCategoryName === "cement") {
          payload.group_cement_name = groupCementName;
        }

        if (
          groupCategoryName === "brick" ||
          isConcreteProduct ||
          isTankProduct ||
          isDiamondProduct ||
          isDiamondBoardProduct ||
          isDiamondSyntheticWoodProduct ||
          isChemicalProduct ||
          (isDoorAndFrameDoorProduct && !isSkuDoorFrameDoor)
        ) {
          // payload.size = sizeList;
          if (!isNaturaPump) {
            payload.size = sizeList;
          }
        }

        if (isPumpProduct) {
          payload.group_pump_name = groupPumpName;
        }

        if (
          isOtherProduct &&
          !(
            isBathTubProduct ||
            isWaterHeaterProduct ||
            isHoseCleanerProduct ||
            isBathroomAccessories ||
            isChemicalProduct ||
            isDoorAndFrameDoorProduct ||
            isSkuDoorFrameDoor
          )
        ) {
          payload.group_sanitary_ware_name = groupSanitaryWareName;
        }

        if (isNaturaWaterPac) {
          payload.group_natura_pump_name = groupNaturaPumpName;
        }

        if (isDiamondProduct) {
          if (isRoofCover) {
            payload.is_roof_cover = "is_cover";
          } else if (isSkuRoofCover) {
            payload.master_id = masterId;
          } else {
            payload.color_type = colorType;
            payload.is_roof_cover = "is_roof";
          }
        }

        if (isSkuDoorFrameDoor) {
          payload.master_id = masterId;
        }

        if (roofCoverStandardType) {
          payload.roof_cover_type = "roof_cover_standard";
        } else if (roofCoverCurvedRidgeType) {
          payload.roof_cover_type = "roof_cover_curved_ridge";
        }

        if (image?.size > 0) {
          const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;

          await Storage.put(keyUpload, image);

          payload.image_key = keyUpload;
        }

        if (numberTis) {
          payload.number_tis = numberTis;
        } else {
          payload.number_tis = "none";
        }

        if (hashtag) {
          const thisHashtag = hashtag?.split(",");
          const resultHashtag = thisHashtag?.map((el) =>
            el?.replace(/^\s+/gm, "")
          );
          payload.hashtag = resultHashtag;
        } else {
          payload.hashtag = [];
        }

        if (filePDF?.size > 0) {
          const keyUploadPdf = `${new Date().getTime()}.${
            TypeFile[filePDF.type]
          }`;

          await Storage.put(keyUploadPdf, filePDF, {
            contentType: "application/pdf",
            contentDisposition: "inline",
          });

          payload.pdf_file = {
            pdf_name: filePDFName,
            pdf_key: keyUploadPdf,
          };
        }

        if (!filePDF) {
          payload.pdf_file = {
            pdf_name: "",
            pdf_key: "",
          };
        }

        await AppApi.put(`products/${productId}`, payload);

        clearForm();
        e.target.reset();

        alertSuccess("Update Success", "", () => navigate(-1));
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    }
  };

  const addSize = () => {
    const updateSizeList = [...sizeList];

    updateSizeList.push({
      size_name: "",
    });

    setSizeList(updateSizeList);
  };

  const deleteSize = (index) => {
    const updateSizeList = [...sizeList];

    if (updateSizeList.length === 1) {
      return setSizeList(updateSizeList);
    } else {
      updateSizeList.splice(index, 1);

      setSizeList(updateSizeList);
    }
  };

  const handleColorTypeChange = (val) => {
    setColorType(val);
  };

  return (
    <div className="bg-[#f9f9f9] w-full h-full">
      {isDoorAndFrameDoorProduct ? (
        <Container>
          <div className="flex flex-col">
            <div className="flex justify-between items-center mb-10">
              <div className="text-primary text-[32px]">
                {isCreate ? (
                  <>
                    {!isSkuDoorFrameDoor ? (
                      <div className="flex items-center gap-3">
                        <div>เพิ่มสินค้า :</div>
                        <div className="font-bold text-[40px]">
                          {subCategoryTitle}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center gap-3">
                        <div>เพิ่มสี :</div>
                        {/* <div className="font-bold text-[40px]">
                          {subCategoryTitle}
                        </div> */}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex items-center gap-3">
                    <div>แก้ไขสินค้า :</div>
                    <span className="font-bold text-[40px]">{title}</span>
                  </div>
                )}
              </div>
              <div className="text-gray-500 bg-white border-2 rounded-full text-center px-5 py-2">
                {parentCategoryTitle}
              </div>
            </div>

            <div className="bg-white px-[60px] py-[32px] rounded-2xl drop-shadow-lg">
              <form className="flex flex-col gap-8" onSubmit={onSubmitForm}>
                {!isSkuDoorFrameDoor ? (
                  <div className="flex flex-col gap-5">
                    {/* <div className="text-primary font-bold text-[32px]">
                      รหัสสินค้า
                    </div> */}
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        ชื่อสินค้า
                      </div>
                      <InputMain
                        name="productname"
                        id="productname"
                        placeholder="กรอกชื่อสินค้า"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-5">
                    <div className="text-primary font-bold text-[32px]">สี</div>
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        ชื่อสี
                      </div>
                      <InputMain
                        name="productname"
                        id="productname"
                        placeholder="เช่น แดงรุ่งระวี"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/*-------------------- รูปตัวอย่างสินค้า --------------------*/}
                <div className="flex flex-col gap-2 relative">
                  <div className="flex flex-col">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      รูปตัวอย่างสินค้า
                    </div>
                    <div className=" text-tnn_gray_200">
                      รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                      334px*334px
                    </div>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    name="image"
                    onChange={onChangeImage}
                    ref={inputImageRef}
                  />
                  {image ? (
                    <div className=" rounded-lg w-full ">
                      <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                        <img
                          className="w-full h-full object-contain"
                          src={
                            image?.size > 0 ? URL.createObjectURL(image) : image
                          }
                          alt="image"
                        />
                        <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                          <HiPencil
                            size={16}
                            className=""
                            onClick={() => {
                              inputImageRef.current.click();
                            }}
                          />
                        </div>
                        <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                          <HiTrash
                            onClick={() => {
                              setImage(null);
                              inputImageRef.current.value = null;
                            }}
                            size={16}
                            className=""
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => inputImageRef.current.click()}
                      className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          รองรับไฟล์ PNG หรือ JPG ขนาดภาพไม่เกิน Ratio 1:1 /
                          334px*334px
                        </p>
                      </div>
                    </div>
                  )}
                  <div id="errorImage" className="absolute mt-4 right-0">
                    {hasImage ? (
                      <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                        *กรุณาใส่รูปภาพตัวอย่าง
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/*-------------------- ขนาดสินค้า --------------------*/}
                {!isSkuDoorFrameDoor ? (
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <div className="font-semibold">
                        {isConcreteProduct ? "ขนาด หรือ ชนิดผลิตภัณฑ์" : "ขนาด"}
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        onClick={addSize}
                        className="w-6 h-6 cursor-pointer"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="flex flex-col gap-2">
                      {sizeList?.map((row, index) => {
                        return (
                          <div className="flex items-center gap-2">
                            <div className="font-semibold">{index + 1}.</div>
                            <InputMain
                              type="text"
                              name="sizeproduct"
                              id="sizeproduct"
                              placeholder={
                                isConcreteProduct ||
                                isDiamondProduct ||
                                isDiamondBoardProduct ||
                                isDiamondSyntheticWoodProduct ||
                                isChemicalProduct ||
                                isDoorAndFrameDoorProduct
                                  ? "เช่น 30x10 ซม."
                                  : "700 L"
                              }
                              value={row?.size_name}
                              onChange={(e) => {
                                const value = e.target.value;
                                setSizeList((prev) => {
                                  let temp = [...prev];
                                  temp[index].size_name = value;
                                  return temp;
                                });
                              }}
                            />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 cursor-pointer"
                              onClick={() => deleteSize(index)}
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/*-------------------- คำอธิบายเบื้องต้น --------------------*/}
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    คำอธิบายเบื้องต้น
                  </div>
                  <InputMainTextArea
                    name="description"
                    id="description"
                    placeholder="เช่น ปูนซีเมนต์ผสม เหมาะสำหรับงานก่ออิฐและงานฉาบผนัง ผนังที่ได้แข็งแรง ไม่แตกร้าว เนื่องจากเนื้อปูน.."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                {/*-------------------- feature --------------------*/}
                <div className="flex flex-col gap-2 relative">
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    คุณสมบัติ
                  </div>
                  {/* <InputMainTextArea
                        name="feature"
                        id="feature"
                        placeholder="เช่น มีน้ำหนัก 50 กก. ขนาด 41x66x21 cm. เหมาะสำหรับงานก่ออิฐและฉาบผนัง"
                        value={feature}
                        onChange={(e) => setFeature(e.target.value)}
                        rows={6}
                      /> */}
                  <FieldWysiwyg
                    value={feature}
                    setValue={setFeature}
                    setHasFeature={setHasFeature}
                  />
                  <div id="errorFeature" className="absolute z-10 right-0">
                    {hasFeature ? (
                      <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                        *กรุณากรอกคุณสมบัติ
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/*-------------------- ลำดับ --------------------*/}
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    ลำดับ
                  </div>
                  <InputMain
                    type="number"
                    name="numberindex"
                    id="numberindex"
                    placeholder="1"
                    value={indexNumber}
                    onChange={(e) => setIndexNumber(e.target.value)}
                  />
                </div>

                {/*-------------------- Hashtag --------------------*/}
                {!isSkuDoorFrameDoor ? (
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      Hashtag
                    </div>
                    <input
                      type="text"
                      name="hashtag"
                      id="hashtag"
                      placeholder="เช่น ปูนซีเมนท์, ปูนมอร์ตาร์, ปูนอินทรี"
                      className="border border-tnn_gray_50 rounded-xl px-5 py-2"
                      value={hashtag}
                      onChange={(e) => setHashtag(e.target.value)}
                    />
                  </div>
                ) : (
                  ""
                )}

                {/*-------------------- Upload PDF --------------------*/}
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      อัพโหลดคู่มือการใช้งาน
                    </div>
                    <div className="text-tnn_gray_200">
                      รองรับไฟล์ PDF ขนาดไม่เกิน 50 MB
                    </div>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    name="pdf"
                    onChange={onChangePDF}
                    ref={inputPDFRef}
                  />
                  <div className="flex items-center gap-2">
                    <div
                      className="border-2 border-primary rounded-full px-5 py-2 cursor-pointer"
                      onClick={() => {
                        inputPDFRef.current.click();
                      }}
                    >
                      {filePDF ? (
                        <div className="flex justify-center items-center gap-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                              stroke="#31338E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                              stroke="#31338E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div className="font-semibold text-primary">
                            {filePDFName}
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center gap-1">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                              stroke="#31338E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                              stroke="#31338E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div className="font-semibold text-primary">
                            อัพโหลดไฟล์ PDF
                          </div>
                        </div>
                      )}
                    </div>
                    {filePDF ? (
                      <ICON_DELETE
                        onClick={() => {
                          setFilePDF(null);
                          inputPDFRef.current.value = null;
                        }}
                        className="w-7 h-7 text-tnn_gray_200  cursor-pointer hover:text-primary_red duration-300"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* ปุ่ม submit */}
                <button
                  isProcessing={saving}
                  className={
                    isCreate
                      ? "bg-primary text-white rounded-full p-2 w-full"
                      : "bg-primary text-white rounded-full p-2 w-full"
                  }
                  type="submit"
                  disabled={saving}
                >
                  {saving ? "Saving..." : isCreate ? "บันทึก" : "แก้ไข"}
                </button>
              </form>
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div className="flex flex-col">
            <div className="flex justify-between items-center mb-10">
              <div className="text-primary text-[32px]">
                {isCreate ? (
                  <div className="flex items-center gap-3">
                    {isRoofCover || isSkuRoofCover ? (
                      roofCoverStandardType ? (
                        <div>เพิ่มครอบมาตรฐาน :</div>
                      ) : roofCoverCurvedRidgeType ? (
                        <div>เพิ่มครอบสันโค้ง :</div>
                      ) : (
                        <div>เพิ่มครอบ :</div>
                      )
                    ) : (
                      <div>เพิ่มสินค้า :</div>
                    )}
                    {isSkuRoofCover ? (
                      <span className="font-bold text-[40px]">
                        สี{colorName}
                      </span>
                    ) : (
                      <span className="font-bold text-[40px]">
                        {groupNaturaPumpName && groupNaturaPumpName !== "none"
                          ? `${naturaPumpName}`
                          : `${subCategoryTitle}`}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center gap-3">
                    {isRoofCover || isSkuRoofCover ? (
                      <div>แก้ไขครอบ :</div>
                    ) : (
                      <div>แก้ไขสินค้า :</div>
                    )}
                    {isSkuRoofCover ? (
                      <span className="font-bold text-[40px]">
                        สี {colorName} - {title}
                      </span>
                    ) : (
                      <span className="font-bold text-[40px]">
                        {subCategoryTitle} - {title}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="text-gray-500 bg-white border-2 rounded-full text-center px-5 py-2">
                {parentCategoryTitle}
              </div>
            </div>

            {isPumpProduct || isOtherProduct ? (
              isCreate ? (
                <div className="font-semibold text-[20px] text-tnn_gray_200 -mt-5 mb-5">
                  {pumpName || sanitaryWareName
                    ? isPumpProduct
                      ? `( ${pumpName} )`
                      : `( ${sanitaryWareName} )`
                    : ""}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            <div className="bg-white pt-[32px] pb-[32px] pl-[60px] pr-[60px] rounded-2xl drop-shadow-lg">
              <form className="flex flex-col gap-8" onSubmit={onSubmitForm}>
                {isCementProduct && groupCategoryName === "cement" ? (
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      ประเภทปูนอินทรี
                    </div>
                    <Select
                      label="เลือกประเภทปูนอินทรี"
                      color="indigo"
                      placeholder="เลือกประเภทปูนอินทรี"
                      id="groupName"
                      className="bg-[#f9f9f9]"
                      value={groupCementName}
                      onChange={handleGroupChange}
                    >
                      {GROUP_CEMENT.map((groupName, index) => {
                        return (
                          <Option key={index} value={groupName.value}>
                            {groupName.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                ) : (
                  ""
                )}

                {isTankProduct ||
                (isDiamondProduct && !isDiamondTools) ||
                isDiamondBoardProduct ||
                isDiamondSyntheticWoodProduct ||
                isChemicalProduct ? (
                  <div className="flex flex-col gap-5">
                    {naturaPumpName ? (
                      ""
                    ) : isSkuRoofCover ? (
                      <div className="text-primary font-bold text-[32px]">
                        ครอบ
                      </div>
                    ) : (
                      <div className="text-primary font-bold text-[32px]">
                        สี
                      </div>
                    )}
                    {isDiamondProduct ? (
                      isRoofCover || isSkuRoofCover ? (
                        <div className="flex flex-col gap-2">
                          {isSkuRoofCover ? (
                            <div className="font-semibold text-[20px] text-tnn_gray_200">
                              ประเภทครอบ
                            </div>
                          ) : (
                            <div className="font-semibold text-[20px] text-tnn_gray_200">
                              ชื่อสี
                            </div>
                          )}
                          <InputMain
                            name="productname"
                            id="productname"
                            placeholder={
                              isSkuRoofCover
                                ? "เช่น ครอบข้าง"
                                : "กรอกชื่อสีสินค้า"
                            }
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      ) : isThaiModern || isThaiClassic || isElegant ? (
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold text-[20px] text-tnn_gray_200">
                            ชื่อสี
                          </div>
                          <InputMain
                            name="productname"
                            id="productname"
                            placeholder={
                              naturaPumpName
                                ? "กรอกชื่อสินค้า"
                                : "กรอกชื่อสีสินค้า"
                            }
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div className="grid grid-cols-2 gap-5">
                          <div className="flex flex-col gap-2">
                            <div className="font-semibold text-[20px] text-tnn_gray_200">
                              ประเภทสี
                            </div>
                            {isDiamondDechraSenatorShingle ? (
                              <select
                                label="เลือกประเภทสี"
                                id="color product"
                                className="border border-tnn_gray_50 focus:outline-none focus:right-1 focus:border-tnn_gray_200 rounded-[10px] block w-full px-5 py-2 placeholder:text-tnn_gray_50 text-tnn_gray_200 bg-white text-base"
                                required
                                value={colorType}
                                onChange={(e) =>
                                  handleColorTypeChange(e.target.value)
                                }
                              >
                                {COLOR_TYPE_LIST_OF_DECHRA.map(
                                  (colorType, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={colorType.value}
                                      >
                                        {colorType.label}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            ) : isDiamondDechraMilano ? (
                              <div className="border border-tnn_gray_50 focus:outline-none focus:right-1 focus:border-tnn_gray_200 rounded-[10px] block w-full px-5 py-2 placeholder:text-tnn_gray_50 text-tnn_gray_200 bg-white text-base">
                                MILANO
                              </div>
                            ) : (
                              <select
                                label="เลือกประเภทสี"
                                id="color product"
                                className="border border-tnn_gray_50 focus:outline-none focus:right-1 focus:border-tnn_gray_200 rounded-[10px] block w-full px-5 py-2 placeholder:text-tnn_gray_50 text-tnn_gray_200 bg-white text-base"
                                required
                                value={colorType}
                                onChange={(e) =>
                                  handleColorTypeChange(e.target.value)
                                }
                              >
                                {COLOR_TYPE_LIST.map((colorType, index) => {
                                  return (
                                    <option key={index} value={colorType.value}>
                                      {colorType.label}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>

                          <div className="flex flex-col gap-2">
                            <div className="font-semibold text-[20px] text-tnn_gray_200">
                              {naturaPumpName ? "ชื่อสินค้า" : "ชื่อสี"}
                            </div>
                            <InputMain
                              name="productname"
                              id="productname"
                              placeholder={
                                naturaPumpName
                                  ? "กรอกชื่อสินค้า"
                                  : "กรอกชื่อสีสินค้า"
                              }
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="flex flex-col gap-2">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          {naturaPumpName ? "ชื่อสินค้า" : "ชื่อสี"}
                        </div>
                        <InputMain
                          name="productname"
                          id="productname"
                          placeholder={
                            naturaPumpName
                              ? "กรอกชื่อสินค้า"
                              : "กรอกชื่อสีสินค้า"
                          }
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-5">
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        ชื่อสินค้า
                      </div>
                      <InputMain
                        name="productname"
                        id="productname"
                        placeholder="กรอกชื่อสินค้า"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        เลข มอก. (ถ้ามี)
                      </div>
                      <input
                        type="text"
                        name="numbertis"
                        id="numbertis"
                        placeholder="128-2560"
                        className="border border-tnn_gray_50 rounded-xl px-5 py-2"
                        value={numberTis}
                        onChange={(e) => setNumberTis(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/*-------------------- รูปตัวอย่างสินค้า --------------------*/}
                <div className="flex flex-col gap-2 relative">
                  <div className="flex flex-col">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      รูปตัวอย่างสินค้า
                    </div>
                    <div className=" text-tnn_gray_200">
                      รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                      334px*334px
                    </div>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    name="image"
                    onChange={onChangeImage}
                    ref={inputImageRef}
                  />
                  {image ? (
                    <div className=" rounded-lg w-full ">
                      <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                        <img
                          className="w-full h-full object-contain"
                          src={
                            image?.size > 0 ? URL.createObjectURL(image) : image
                          }
                          alt="image"
                        />
                        <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                          <HiPencil
                            size={16}
                            className=""
                            onClick={() => {
                              inputImageRef.current.click();
                            }}
                          />
                        </div>
                        <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                          <HiTrash
                            onClick={() => {
                              setImage(null);
                              inputImageRef.current.value = null;
                            }}
                            size={16}
                            className=""
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => inputImageRef.current.click()}
                      className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          รองรับไฟล์ PNG หรือ JPG ขนาดภาพไม่เกิน Ratio 1:1 /
                          334px*334px
                        </p>
                      </div>
                    </div>
                  )}
                  <div id="errorImage" className="absolute mt-4 right-0">
                    {hasImage ? (
                      <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                        *กรุณาใส่รูปภาพตัวอย่าง
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/*-------------------- ขนาดสินค้า --------------------*/}
                {groupCategoryName === "brick" ||
                isConcreteProduct ||
                isTankProduct ||
                isDiamondProduct ||
                isDiamondBoardProduct ||
                isDiamondSyntheticWoodProduct ||
                isChemicalProduct ? (
                  naturaPumpName ||
                  isEditNaturaPump ||
                  isRoofCover ||
                  isSkuRoofCover ? (
                    ""
                  ) : (
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-2">
                        <div className="font-semibold">
                          {isConcreteProduct
                            ? "ขนาด หรือ ชนิดผลิตภัณฑ์"
                            : "ขนาด"}
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          onClick={addSize}
                          className="w-6 h-6 cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col gap-2">
                        {sizeList?.map((row, index) => {
                          return (
                            <div className="flex items-center gap-2">
                              <div className="font-semibold">{index + 1}.</div>
                              <InputMain
                                type="text"
                                name="sizeproduct"
                                id="sizeproduct"
                                placeholder={
                                  isConcreteProduct ||
                                  isDiamondProduct ||
                                  isDiamondBoardProduct ||
                                  isDiamondSyntheticWoodProduct ||
                                  isChemicalProduct
                                    ? "เช่น 30x10 ซม."
                                    : "700 L"
                                }
                                value={row?.size_name}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setSizeList((prev) => {
                                    let temp = [...prev];
                                    temp[index].size_name = value;
                                    return temp;
                                  });
                                }}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer"
                                onClick={() => deleteSize(index)}
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}

                {/*-------------------- คำอธิบายเบื้องต้น --------------------*/}
                {isRoofCover ? (
                  ""
                ) : (
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      คำอธิบายเบื้องต้น
                    </div>
                    <InputMainTextArea
                      name="description"
                      id="description"
                      placeholder="เช่น ปูนซีเมนต์ผสม เหมาะสำหรับงานก่ออิฐและงานฉาบผนัง ผนังที่ได้แข็งแรง ไม่แตกร้าว เนื่องจากเนื้อปูน.."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                )}

                {/*-------------------- feature --------------------*/}
                {isRoofCover ? (
                  ""
                ) : (
                  <div className="flex flex-col gap-2 relative">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      คุณสมบัติ
                    </div>
                    {/* <InputMainTextArea
                  name="feature"
                  id="feature"
                  placeholder="เช่น มีน้ำหนัก 50 กก. ขนาด 41x66x21 cm. เหมาะสำหรับงานก่ออิฐและฉาบผนัง"
                  value={feature}
                  onChange={(e) => setFeature(e.target.value)}
                  rows={6}
                /> */}
                    <FieldWysiwyg
                      value={feature}
                      setValue={setFeature}
                      setHasFeature={setHasFeature}
                    />
                    <div id="errorFeature" className="absolute z-10 right-0">
                      {hasFeature ? (
                        <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                          *กรุณากรอกคุณสมบัติ
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                {/*-------------------- ลำดับ --------------------*/}
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    ลำดับ
                  </div>
                  <InputMain
                    type="number"
                    name="numberindex"
                    id="numberindex"
                    placeholder="1"
                    value={indexNumber}
                    onChange={(e) => setIndexNumber(e.target.value)}
                  />
                </div>

                {/*-------------------- Hashtag --------------------*/}
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    Hashtag
                  </div>
                  <input
                    type="text"
                    name="hashtag"
                    id="hashtag"
                    placeholder="เช่น ปูนซีเมนท์, ปูนมอร์ตาร์, ปูนอินทรี"
                    className="border border-tnn_gray_50 rounded-xl px-5 py-2"
                    value={hashtag}
                    onChange={(e) => setHashtag(e.target.value)}
                  />
                </div>

                {/*-------------------- Upload PDF --------------------*/}
                {isRoofCover ? (
                  ""
                ) : (
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        อัพโหลดคู่มือการใช้งาน
                      </div>
                      <div className="text-tnn_gray_200">
                        รองรับไฟล์ PDF ขนาดไม่เกิน 50 MB
                      </div>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      name="pdf"
                      onChange={onChangePDF}
                      ref={inputPDFRef}
                    />
                    <div className="flex items-center gap-2">
                      <div
                        className="border-2 border-primary rounded-full px-5 py-2 cursor-pointer"
                        onClick={() => {
                          inputPDFRef.current.click();
                        }}
                      >
                        {filePDF ? (
                          <div className="flex justify-center items-center gap-1">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                                stroke="#31338E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                                stroke="#31338E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div className="font-semibold text-primary">
                              {filePDFName}
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center gap-1">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                                stroke="#31338E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                                stroke="#31338E"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div className="font-semibold text-primary">
                              อัพโหลดไฟล์ PDF
                            </div>
                          </div>
                        )}
                      </div>
                      {filePDF ? (
                        <ICON_DELETE
                          onClick={() => {
                            setFilePDF(null);
                            inputPDFRef.current.value = null;
                          }}
                          className="w-7 h-7 text-tnn_gray_200  cursor-pointer hover:text-primary_red duration-300"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}

                <button
                  isProcessing={saving}
                  className={
                    isCreate
                      ? "bg-primary text-white rounded-full p-2 w-full"
                      : "bg-primary text-white rounded-full p-2 w-full"
                  }
                  type="submit"
                  disabled={saving}
                >
                  {saving ? "Saving..." : isCreate ? "บันทึก" : "แก้ไข"}
                </button>
              </form>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default FormProduct;
