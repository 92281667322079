import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const store = (set) => ({
   user: null,
   setUser: (user) => set(() => ({ user })),
   clearUser: () => set(() => ({ user: null })),
});

export const useUserStore = create(
   persist(store, {
      storage: createJSONStorage(() => sessionStorage), // (optional) by default the 'localStorage' is used
   })
);
