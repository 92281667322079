import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppApi } from "../../api";
import { TypeFile, s3File } from "../../utils/fileFormater";
import useSweetAlert from "../../_hooks/useSweetAlert";
import { HiPencil, HiTrash } from "react-icons/hi2";
import { Storage } from "aws-amplify";
import Container from "../../components/Container";
import { IMAGE_TYPE_LIST } from "../../utils/constants";

const FormImageBanner = () => {
  const params = useParams();
  const uploadId = params.uploadId;

  const navigate = useNavigate();

  const [image, setImage] = useState(null);

  const inputImageRef = useRef();

  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`media/${uploadId}`);

          setImage(s3File(data.media.value));
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const onChangeImage = (e) => {
    if (!e.target.value) return;
    const type = e.target.files[0].type;
    if (!IMAGE_TYPE_LIST.includes(type)) {
      alertError("รองรับไฟล์ PNG หรือ JPEG");
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;
        // console.log(`width: `, width);
        // console.log(`height: `, height);

        if (width <= 1440 && height <= 352) {
          // Valid image file
          // Perform further actions here
          setImage(file);
        } else {
          // Invalid image file

          alertError("รองรับไฟล์ขนาดไม่เกิน 1440x352");
        }
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const onSaveImage = async (e) => {
    e.preventDefault();
    setUploading(true);
    try {
      if (image?.size > 0) {
        const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;

        await Storage.put(keyUpload, image);

        const payload = {
          value: keyUpload,
        };

        await AppApi.put(`media/${uploadId}`, payload);
      }

      e.target.reset();
      alertSuccess("อัพโหลดสำเร็จ", "", () => navigate(-1));

      // const { data } = await AppApi.get(`media/${uploadId}`);
      // setImage(s3File(data.media.value));
    } catch (error) {
      console.log(error);
      alertError("อัพโหลดไม่สำเร็จ");
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      {uploadId === "64d1f59619d88687cb825c14" ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          <Container>
            <div className="flex flex-col gap-10">
              <div className="flex justify-between items-center">
                <div className="text-primary text-[48px]">
                  แก้ไข Image Banner
                </div>
                <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                  About Us
                </div>
              </div>

              <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                <form className="flex flex-col gap-20" onSubmit={onSaveImage}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <div className="text-primary font-bold text-[24px]">
                        Banner
                      </div>
                      <div className="text-gray-500">
                        รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9 /
                        1440px*352px
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className="font-semibold">รูปตัวอย่าง</div>

                      <input
                        type="file"
                        className="hidden"
                        name="image"
                        onChange={onChangeImage}
                        ref={inputImageRef}
                      />
                      {image ? (
                        <div className="relative rounded-lg p-2 w-full h-[278px] shadow-lg">
                          <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                            <HiPencil
                              size={20}
                              className=""
                              onClick={() => {
                                inputImageRef.current.click();
                              }}
                            />
                          </div>
                          <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                            <HiTrash
                              onClick={() => {
                                setImage(null);
                                inputImageRef.current.value = null;
                              }}
                              size={20}
                              className=""
                            />
                          </div>
                          <img
                            className="w-full h-full rounded-lg"
                            src={
                              image?.size > 0
                                ? URL.createObjectURL(image)
                                : image
                            }
                            alt="image"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => inputImageRef.current.click()}
                          className="flex flex-col items-center justify-center w-full h-[278px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9
                              / 1440px*352px
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <button
                      isProcessing={uploading}
                      type="submit"
                      className="bg-primary text-white rounded-full p-2"
                      disabled={uploading}
                    >
                      {uploading ? "Uploading..." : "บันทึก"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      ) : uploadId === "64d1feb2ad024f4e0302acf4" ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          <Container>
            <div className="flex flex-col gap-10">
              <div className="flex justify-between items-center">
                <div className="text-primary text-[48px]">
                  แก้ไข Image Banner
                </div>
                <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                  Contact Us
                </div>
              </div>

              <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                <form className="flex flex-col gap-20" onSubmit={onSaveImage}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <div className="text-primary font-bold text-[24px]">
                        Banner
                      </div>
                      <div className="text-gray-500">
                        รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9 /
                        1440px*352px
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className="font-semibold">รูปตัวอย่าง</div>

                      <input
                        type="file"
                        className="hidden"
                        name="image"
                        onChange={onChangeImage}
                        ref={inputImageRef}
                      />
                      {image ? (
                        <div className="relative rounded-lg p-2 w-full h-[278px] shadow-lg">
                          <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                            <HiPencil
                              size={20}
                              className=""
                              onClick={() => {
                                inputImageRef.current.click();
                              }}
                            />
                          </div>
                          <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                            <HiTrash
                              onClick={() => {
                                setImage(null);
                                inputImageRef.current.value = null;
                              }}
                              size={20}
                              className=""
                            />
                          </div>
                          <img
                            className="w-full h-full rounded-lg"
                            src={
                              image?.size > 0
                                ? URL.createObjectURL(image)
                                : image
                            }
                            alt="image"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => inputImageRef.current.click()}
                          className="flex flex-col items-center justify-center w-full h-[278px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9
                              / 1440px*352px
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <button
                      isProcessing={uploading}
                      type="submit"
                      className="bg-primary text-white rounded-full p-2"
                      disabled={uploading}
                    >
                      {uploading ? "Uploading..." : "บันทึก"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      ) : uploadId === "64d2004815780a04bc897e61" ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          <Container>
            <div className="flex flex-col gap-10">
              <div className="flex justify-between items-center">
                <div className="text-primary text-[48px]">
                  แก้ไข Image Banner
                </div>
                <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                  Product
                </div>
              </div>

              <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                <form className="flex flex-col gap-20" onSubmit={onSaveImage}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <div className="text-primary font-bold text-[24px]">
                        Banner
                      </div>
                      <div className="text-gray-500">
                        รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9 /
                        1440px*352px
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className="font-semibold">รูปตัวอย่าง</div>

                      <input
                        type="file"
                        className="hidden"
                        name="image"
                        onChange={onChangeImage}
                        ref={inputImageRef}
                      />
                      {image ? (
                        <div className="relative rounded-lg p-2 w-full h-[278px] shadow-lg">
                          <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                            <HiPencil
                              size={20}
                              className=""
                              onClick={() => {
                                inputImageRef.current.click();
                              }}
                            />
                          </div>
                          <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                            <HiTrash
                              onClick={() => {
                                setImage(null);
                                inputImageRef.current.value = null;
                              }}
                              size={20}
                              className=""
                            />
                          </div>
                          <img
                            className="w-full h-full rounded-lg"
                            src={
                              image?.size > 0
                                ? URL.createObjectURL(image)
                                : image
                            }
                            alt="image"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => inputImageRef.current.click()}
                          className="flex flex-col items-center justify-center w-full h-[278px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9
                              / 1440px*352px
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <button
                      isProcessing={uploading}
                      type="submit"
                      className="bg-primary text-white rounded-full p-2"
                      disabled={uploading}
                    >
                      {uploading ? "Uploading..." : "บันทึก"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      ) : uploadId === "64d312e3c64796cec3ef74db" ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          <Container>
            <div className="flex flex-col gap-10">
              <div className="flex justify-between items-center">
                <div className="text-primary text-[48px]">
                  แก้ไข Image Banner
                </div>
                <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                  News
                </div>
              </div>

              <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                <form className="flex flex-col gap-20" onSubmit={onSaveImage}>
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-2">
                      <div className="text-primary font-bold text-[24px]">
                        Banner
                      </div>
                      <div className="text-gray-500">
                        รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9 /
                        1440px*352px
                      </div>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className="font-semibold">รูปตัวอย่าง</div>

                      <input
                        type="file"
                        className="hidden"
                        name="image"
                        onChange={onChangeImage}
                        ref={inputImageRef}
                      />
                      {image ? (
                        <div className="relative rounded-lg p-2 w-full h-[278px] shadow-lg">
                          <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                            <HiPencil
                              size={20}
                              className=""
                              onClick={() => {
                                inputImageRef.current.click();
                              }}
                            />
                          </div>
                          <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                            <HiTrash
                              onClick={() => {
                                setImage(null);
                                inputImageRef.current.value = null;
                              }}
                              size={20}
                              className=""
                            />
                          </div>
                          <img
                            className="w-full h-full rounded-lg"
                            src={
                              image?.size > 0
                                ? URL.createObjectURL(image)
                                : image
                            }
                            alt="image"
                          />
                        </div>
                      ) : (
                        <div
                          onClick={() => inputImageRef.current.click()}
                          className="flex flex-col items-center justify-center w-full h-[278px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9
                              / 1440px*352px
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <button
                      isProcessing={uploading}
                      type="submit"
                      className="bg-primary text-white rounded-full p-2"
                      disabled={uploading}
                    >
                      {uploading ? "Uploading..." : "บันทึก"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <div>Not Found.</div>
      )}
    </>
  );
};

export default FormImageBanner;
