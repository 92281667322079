import { useCallback, useEffect, useState } from "react";
import Container from "../../components/Container";
import { STYLE_BG_CONTAIN } from "../../utils/useStyle";
import { useNavigate } from "react-router-dom";
import { AppApi } from "../../api";
import ChartBar from "../../components/ChartBar";
import ProductList from "../../components/ProductList";
import { ICON_LOADING } from "../../utils/useIcons";

// Get the current date
const currDate = new Date();

// Extract the month (0-indexed, so January is 0)
const currentMonth = currDate.getMonth() + 1; // Adding 1 to match human-readable month numbers

// Extract the year
const currentYear = currDate.getFullYear();

const MONTH_LIST = [
  // { value: "00", label: "ทั้งหมด" },
  { value: "01", label: "มกราคม" },
  { value: "02", label: "กุมภาพันธ์" },
  { value: "03", label: "มีนาคม" },
  { value: "04", label: "เมษายน" },
  { value: "05", label: "พฤษภาคม" },
  { value: "06", label: "มิถุนายน" },
  { value: "07", label: "กรกฎาคม" },
  { value: "08", label: "สิงหาคม" },
  { value: "09", label: "กันยายน" },
  { value: "10", label: "ตุลาคม" },
  { value: "11", label: "พฤศจิกายน" },
  { value: "12", label: "ธันวาคม" },
];

const YEAR_LIST = new Array(10).fill(null)?.map((_, index) => {
  let currentToDay = new Date();
  let currentYear = currentToDay.getFullYear() - index;
  return { value: currentYear, label: currentYear };
});

const PEODUCT_CATEGORY_LIST = [
  { value: "all", label: "ทั้งหมด" },
  { value: "64d3230f92e632cfcf7be58c", label: "ปูนซีเมนต์, อิฐมวลเบา" },
  { value: "64d3251c92e632cfcf7be5a0", label: "ผลิตภัณฑ์คอนกรีต" },
  { value: "64d3254392e632cfcf7be5a4", label: "ถังเก็บน้ำ, ถังบำบัด" },
  { value: "64d3255f92e632cfcf7be5aa", label: "ปั้มน้ำ" },
  { value: "64d3256e92e632cfcf7be5ae", label: "ผลิตภัณฑ์ตราเพชร" },
  { value: "652cc2fee6867d835d1b4046", label: "ตราเพชร (บอร์ด)" },
  { value: "652cc3e0f9d95317b7642cad", label: "ตราเพชร (ไม้สังเคราะห์)" },
  { value: "64d3257492e632cfcf7be5b2", label: "อื่น ๆ" },
];

const formatDate = (dateData) => {
  const date = new Date(dateData);

  // Get day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  // Format the date
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
};

const Overview = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const currentDate = formatDate(new Date().toDateString());

  const [year, setYear] = useState(String(YEAR_LIST[0].value));
  const [month, setMonth] = useState(MONTH_LIST[0].value);
  const [productCategory, setProductCategory] = useState("all");

  const [quotations, setQuotations] = useState([]);
  // console.log("quotations : ", quotations);
  const countQuotations = quotations?.length?.toLocaleString();

  const countConfirm = quotations.filter(
    (el) => el?.quotation_status === "confirm"
  );

  const countNotConfirm = quotations.filter(
    (el) => el?.quotation_status === "not-confirm"
  );

  const countInspecting = quotations.filter(
    (el) => el?.quotation_status === "inspecting"
  );

  const ordersHasPrice = quotations.filter((el) => el?.total_price > 0);

  const countOrdersPrice = ordersHasPrice?.reduce((prev, curr) => {
    return prev + curr?.total_price;
  }, 0);

  const textMonth = MONTH_LIST?.find((item) => {
    return item.value === month;
  });

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      load();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const load = async (
    year = String(YEAR_LIST[0].value),
    month = MONTH_LIST[0].value,
    productCategory = "all"
  ) => {
    try {
      setLoading(true);
      const { data } = await AppApi.get(
        `quotations?year=${year}&month=${month}&productCategory=${productCategory}`
      );
      setQuotations(data.quotations);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const loadDelay = useCallback(_.debounce(load, 500), [
  //   setYear,
  //   setMonth,
  //   setProductCategory,
  // ]);

  const handleYearChange = async (e) => {
    setYear(e.target.value);
    await load(e.target.value, month, productCategory);
  };

  const handleMonthChange = async (e) => {
    setMonth(e.target.value);
    await load(year, e.target.value, productCategory);
  };

  const handleProductCategoryChange = async (e) => {
    setProductCategory(e.target.value);
    await load(year, month, e.target.value);
  };

  return (
    <div
      className={STYLE_BG_CONTAIN}
      style={{
        backgroundImage: `url('/background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <div className="flex justify-between items-center text-white mb-6">
          <div className="text-[48px]">Overview</div>
          <div className="flex flex-col justify-end ">
            <div>TO DAY</div>
            <div className="text-3xl font-bold">{currentDate}</div>
          </div>
        </div>

        <div className="grid grid-cols-8 gap-5">
          {/*---------- colum 1 ----------*/}
          <div className=" col-span-5 bg-white rounded-2xl p-5 shadow-xl">
            <div className="bg-primary rounded-2xl p-5">
              <div className="flex justify-between text-white items-center">
                <div className="flex flex-col">
                  <div className=" font-semibold text-base">
                    ยอดสั่งซื้อสินค้าทั้งหมด
                  </div>
                  <div className=" font-thin text-xs">
                    *ประจำเดือนปัจจุบัน ({textMonth?.label})
                  </div>
                </div>

                <div className="flex flex-col items-end">
                  <div className="flex items-center gap-2">
                    <svg
                      width="24"
                      height="19"
                      viewBox="0 0 24 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.3 5.41998V10.57C19.3 13.65 17.54 14.97 14.9 14.97H6.10995C5.65995 14.97 5.22996 14.93 4.82996 14.84C4.57996 14.8 4.33996 14.73 4.11996 14.65C2.61996 14.09 1.70996 12.79 1.70996 10.57V5.41998C1.70996 2.33998 3.46995 1.02002 6.10995 1.02002H14.9C17.14 1.02002 18.75 1.97001 19.18 4.14001C19.25 4.54001 19.3 4.94998 19.3 5.41998Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22.3011 8.4201V13.5701C22.3011 16.6501 20.5411 17.9701 17.9011 17.9701H9.11105C8.37105 17.9701 7.70106 17.8701 7.12106 17.6501C5.93106 17.2101 5.12105 16.3001 4.83105 14.8401C5.23105 14.9301 5.66105 14.9701 6.11105 14.9701H14.9011C17.5411 14.9701 19.3011 13.6501 19.3011 10.5701V5.4201C19.3011 4.9501 19.2611 4.53014 19.1811 4.14014C21.0811 4.54014 22.3011 5.88011 22.3011 8.4201Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.4984 10.6399C11.9564 10.6399 13.1384 9.45791 13.1384 7.99988C13.1384 6.54185 11.9564 5.35986 10.4984 5.35986C9.04038 5.35986 7.8584 6.54185 7.8584 7.99988C7.8584 9.45791 9.04038 10.6399 10.4984 10.6399Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.77979 5.80005V10.2001"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.2217 5.80029V10.2003"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="font-bold text-3xl">
                      {loading ? (
                        <ICON_LOADING className="w-6 h-6" />
                      ) : (
                        countOrdersPrice.toLocaleString()
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 1.16675V5.25008L8.16667 4.08341"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.00016 5.24992L5.8335 4.08325"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.15479 7.58325H3.72729C3.94895 7.58325 4.14729 7.70575 4.24645 7.90409L4.92895 9.26909C5.12729 9.66575 5.52979 9.91659 5.97312 9.91659H8.03229C8.47562 9.91659 8.87812 9.66575 9.07645 9.26909L9.75895 7.90409C9.85812 7.70575 10.0623 7.58325 10.2781 7.58325H12.8215"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.08317 2.40918C2.01817 2.71251 1.1665 3.92585 1.1665 6.41668V8.75001C1.1665 11.6667 2.33317 12.8333 5.24984 12.8333H8.74984C11.6665 12.8333 12.8332 11.6667 12.8332 8.75001V6.41668C12.8332 3.92585 11.9815 2.71251 9.9165 2.40918"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className=" font-medium text-xl">
                      {loading ? <div>...</div> : <div>{countQuotations}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-2.5 py-5">
              <div className="flex flex-col">
                <div className="text-primary text-sm">
                  เลือกปีเพื่อดูยอดสั่งซื้อ
                </div>
                <select
                  id="selectYear"
                  className="bg-[#f9f9f9] px-5 py-1 rounded-xl border-2"
                  value={year}
                  onChange={handleYearChange}
                >
                  {YEAR_LIST?.map((year, index) => {
                    return (
                      <option key={index} value={year.value}>
                        {year.label}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="flex flex-col">
                <div className="text-primary text-sm">
                  เลือกเดือนเพื่อดูยอดสั่งซื้อ
                </div>
                <select
                  id="selectMonth"
                  className="bg-[#f9f9f9] px-5 py-1 rounded-xl border-2"
                  value={month}
                  onChange={handleMonthChange}
                >
                  {MONTH_LIST?.map((month, index) => {
                    return (
                      <option key={index} value={month.value}>
                        {month.label}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="flex flex-col">
                <div className="text-primary text-sm">เลือกประเภทสินค้า</div>
                <select
                  id="selectProductCategory"
                  className="bg-[#f9f9f9] px-5 py-1 rounded-xl border-2"
                  value={productCategory}
                  onChange={handleProductCategoryChange}
                >
                  {PEODUCT_CATEGORY_LIST?.map((category, index) => {
                    return (
                      <option key={index} value={category?.value}>
                        {category?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {/* <div className="flex justify-between items-center">
              <div className="text-primary_gray font-light text-[20px]">
                ยอดสั่งซื้อเดือน {textMonth?.label}
              </div>
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=" cursor-pointer"
                // onClick={()=>{}}
              >
                <ellipse
                  cx="12.7359"
                  cy="12"
                  rx="12.4747"
                  ry="12"
                  fill="#BDBDBD"
                />
                <rect
                  x="10.6567"
                  y="9.33325"
                  width="4.15821"
                  height="10.6666"
                  rx="2.07911"
                  fill="white"
                />
                <rect
                  x="10.6567"
                  y="4"
                  width="4.15821"
                  height="3.99999"
                  rx="2"
                  fill="white"
                />
              </svg>
            </div> */}
            <div className="text-primary_gray font-light text-[20px]">
              ยอดสั่งซื้อเดือน {textMonth?.label}
            </div>

            <div className="flex flex-col text-primary pb-2.5 gap-1 border-b-2">
              <div className="font-bold text-4xl">
                {loading ? (
                  <ICON_LOADING className="w-10 h-10" />
                ) : (
                  <div>{countOrdersPrice.toLocaleString()} บาท</div>
                )}
              </div>
              <div className="flex gap-1">
                <div>(รวมใบสั่งซื้อ</div>
                {loading ? <div>...</div> : <div>{countQuotations}</div>}
                <div> ใบ)</div>
              </div>
            </div>

            <div className="mt-5">
              {loading ? (
                <div className="flex justify-center mt-10">
                  <ICON_LOADING className="w-10 h-10" />
                </div>
              ) : (
                <ChartBar
                  quotations={quotations}
                  textMonth={textMonth?.label}
                  productCategory={productCategory}
                />
              )}
            </div>
          </div>

          {/*---------- colum 2 ----------*/}
          <div className="flex flex-col col-span-3 gap-5">
            {/* ส่งงานสำเร็จ */}
            <div className="bg-white rounded-2xl p-5 shadow-xl">
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="text-tnn_gray_200 font-semibold text-base">
                    ใบเสนอราคาที่ตรวจสอบแล้ว
                  </div>
                  <div className="text-primary_gray font-light text-xs">
                    *ประจำเดือนปัจจุบัน {textMonth?.label}
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke="#318E7D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.75 11.9999L10.58 14.8299L16.25 9.16992"
                      stroke="#318E7D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="text-[#318E7D] text-3xl font-bold">
                    {loading ? (
                      <ICON_LOADING className="w-6 h-6" />
                    ) : (
                      <div>{countConfirm?.length}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* กำลังตรวจสอบ */}
            <div className="bg-white rounded-2xl p-5 shadow-xl">
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="text-tnn_gray_200 font-semibold text-base">
                    ใบเสนอราคาที่กำลังตรวจสอบ
                  </div>
                  <div className="text-primary_gray font-light text-xs">
                    *ประจำเดือนปัจจุบัน {textMonth?.label}
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                      stroke="#E89C2A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.0399 3.02001L8.15988 10.9C7.85988 11.2 7.55988 11.79 7.49988 12.22L7.06988 15.23C6.90988 16.32 7.67988 17.08 8.76988 16.93L11.7799 16.5C12.1999 16.44 12.7899 16.14 13.0999 15.84L20.9799 7.96001C22.3399 6.60001 22.9799 5.02001 20.9799 3.02001C18.9799 1.02001 17.3999 1.66001 16.0399 3.02001Z"
                      stroke="#E89C2A"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.9099 4.1499C15.5799 6.5399 17.4499 8.4099 19.8499 9.0899"
                      stroke="#E89C2A"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="text-[#E89C2A] text-3xl font-bold">
                    {loading ? (
                      <ICON_LOADING className="w-6 h-6" />
                    ) : (
                      <div>{countInspecting?.length}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* ยังไม่ได้ตรวจสอบ */}
            <div className="bg-white rounded-2xl p-5 shadow-xl">
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <div className="text-tnn_gray_200 font-semibold text-base">
                    ใบเสนอราคาที่รอตรวจสอบ
                  </div>
                  <div className="text-primary_gray font-light text-xs">
                    *ประจำเดือนปัจจุบัน {textMonth?.label}
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2C17.3431 2 16 3.34315 16 5C16 6.65685 17.3431 8 19 8Z"
                      stroke="#A32330"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 13H12"
                      stroke="#A32330"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 17H16"
                      stroke="#A32330"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
                      stroke="#A32330"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="text-[#A32330] text-3xl font-bold">
                    {loading ? (
                      <ICON_LOADING className="w-6 h-6" />
                    ) : (
                      <div>{countNotConfirm?.length}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="border-2 rounded-xl mt-2.5 h-[272px]">
                <ProductList
                  quotations={quotations}
                  productCategory={productCategory}
                  loading={loading}
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                className="flex items-center text-white font-semibold text-base py-1.5 rounded-full hover:bg-primary hover:pl-5 hover:pr-2.5 hover:py-2.5 hover:shadow-md duration-300"
                onClick={() => navigate("/orderManagement")}
              >
                <div>ดูใบเสนอราคาทั้งหมด</div>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.90991 20.4201L15.4299 13.9001C16.1999 13.1301 16.1999 11.8701 15.4299 11.1001L8.90991 4.58008"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Overview;
