import { useEffect, useState } from "react";
import { PRODUCT_IMAGE_BANNER_ID } from "../../utils/constants";
import { AppApi } from "../../api";
import { s3File } from "../../utils/fileFormater";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container";
import { STYLE_BG_CONTAIN } from "../../utils/useStyle";

const ProductUsBanner = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  // console.log("image : ", image);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`media/${PRODUCT_IMAGE_BANNER_ID}`);

          setImage(s3File(data.media.value));
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div
      className={STYLE_BG_CONTAIN}
      style={{
        backgroundImage: `url('/background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <div className="flex flex-col gap-8">
          <div className="text-white text-[48px]">BANNER</div>
          <div className="border-2 rounded-full bg-[#f5f5f5] p-1 w-48 text-center">
            Product
          </div>

          <div className="bg-[#f9f9f9] rounded-2xl p-6 shadow-xl flex flex-col gap-8">
            <div className="flex flex-col">
              <div className="text-primary font-bold text-[24px]">Banner</div>
              <div>
                รองรับไฟล์ PNG หรือ JPG ขนาดภาพไม่เกิน Ratio 16:9 / 1440px*352px
              </div>
            </div>

            <div className="bg-white rounded-xl shadow-lg p-4 flex flex-col gap-2">
              <div className="flex justify-end">
                <div
                  className=" flex items-center text-[#A32330] gap-1 cursor-pointer"
                  onClick={() =>
                    navigate(`/formImageBanner/${PRODUCT_IMAGE_BANNER_ID}`)
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33337 1.33325H6.00004C2.66671 1.33325 1.33337 2.66659 1.33337 5.99992V9.99992C1.33337 13.3333 2.66671 14.6666 6.00004 14.6666H10C13.3334 14.6666 14.6667 13.3333 14.6667 9.99992V8.66659"
                      stroke="#A32330"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6934 2.01326L5.44004 7.26659C5.24004 7.46659 5.04004 7.85992 5.00004 8.14659L4.71337 10.1533C4.60671 10.8799 5.12004 11.3866 5.84671 11.2866L7.85337 10.9999C8.13337 10.9599 8.52671 10.7599 8.73338 10.5599L13.9867 5.30659C14.8934 4.39992 15.32 3.34659 13.9867 2.01326C12.6534 0.679924 11.6 1.10659 10.6934 2.01326Z"
                      stroke="#A32330"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993"
                      stroke="#A32330"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div>แก้ไข</div>
                </div>
              </div>
              <div className="flex justify-center">
                {image ? (
                  <img src={image} className="w-full h-full rounded-lg" />
                ) : (
                  <img src="./not-found.png" className="w-44 h-44" />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductUsBanner;
