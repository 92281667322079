import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import { useParams } from "react-router-dom";
import { AppApi } from "../../api";
import { s3File } from "../../utils/fileFormater";
import {
  CEMENT_CATEGORY_ID,
  CONCRETE_CATEGORY_ID,
  TANK_CATEGORY_ID,
  DIAMOND_BOARD_ID,
  DIAMOND_SYNTHETIC_WOOD_ID,
} from "../../utils/constants";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { ContentView } from "../Blog/BlogView";

const ProductView = () => {
  const params = useParams();
  const subCategoryId = params.subCategoryId;
  const productId = params.id;

  const [categoryId, setCategoryId] = useState("");

  // Check Category
  const isCementProduct = categoryId === CEMENT_CATEGORY_ID;
  const isConcreteProduct = categoryId === CONCRETE_CATEGORY_ID;
  const isTankProduct = categoryId === TANK_CATEGORY_ID;
  const isDiamondBoardProduct = categoryId === DIAMOND_BOARD_ID;
  const isDiamondSyntheticWoodProduct =
    categoryId === DIAMOND_SYNTHETIC_WOOD_ID;

  const [open, setOpen] = useState(false);

  const [subCategoryTitle, setSubCategoryTitle] = useState("");

  const [title, setTitle] = useState("");
  const [numberTis, setNumberTis] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [feature, setFeature] = useState("");
  const [indexNumber, setIndexNumber] = useState("");
  const [groupCementName, setGroupCementName] = useState("");
  const [filePDF, setFilePDF] = useState(null);
  const [filePDFName, setFilePDFName] = useState("");
  const [sizeList, setSizeList] = useState([]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`categorys/${subCategoryId}`);
          setSubCategoryTitle(data.category.category_name);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [subCategoryId]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`products/${productId}`);
          const product = data.product;
          setTitle(product.product_name);

          if (product.number_tis === "none") {
            setNumberTis("");
          } else {
            setNumberTis(product.number_tis);
          }

          setImage(s3File(product.image_key));
          setDescription(product.description);
          setFeature(product.feature);
          setIndexNumber(product.number_index);
          setGroupCementName(product.group_cement_name);
          setFilePDF(s3File(product?.pdf_file?.pdf_key));
          setFilePDFName(product?.pdf_file?.pdf_name);
          setCategoryId(product?.category_ids[0]);
          setSizeList(product.size);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [productId]);

  const handleOpen = () => setOpen(!open);

  return (
    <div className="bg-[#f9f9f9] w-full h-full">
      <Dialog open={open} handler={handleOpen} size="xl">
        <div className="font-semibold text-[20px] text-tnn_gray_200 px-5 py-2 border-b-2 border-primary_gray">
          {filePDFName}
        </div>
        <div className="h-[80vh]">
          <iframe src={filePDF} className="w-full h-full" />
        </div>
        <div className="flex justify-end px-5 py-2 border-t-2 border-primary_gray">
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </div>
      </Dialog>

      <Container>
        <div className="flex flex-col">
          <div className="flex justify-between items-center mb-10">
            <div className="text-primary text-[32px]">
              <div className="flex items-center gap-3">
                <div>รายละเอียดสินค้า :</div>
                {isTankProduct ||
                isDiamondBoardProduct ||
                isDiamondSyntheticWoodProduct ? (
                  <span className="font-bold text-[40px]">
                    {subCategoryTitle}
                  </span>
                ) : (
                  <span className="font-bold text-[40px]">{title}</span>
                )}
              </div>
            </div>
          </div>

          <div className="bg-white pt-[32px] pb-[32px] pl-[60px] pr-[60px] rounded-2xl drop-shadow-lg">
            <div className="flex flex-col gap-8">
              {groupCementName ? (
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    ประเภทปูนอินทรี
                  </div>
                  <div className="border-2 rounded-xl px-5 py-2">
                    {groupCementName === "cement"
                      ? "ปูนซีเมนต์"
                      : "ปูนซีเมนต์สำเร็จรูป (มอร์ตาร์)"}
                  </div>
                </div>
              ) : (
                ""
              )}

              {isTankProduct ||
              isDiamondBoardProduct ||
              isDiamondSyntheticWoodProduct ? (
                <div className="flex flex-col gap-2">
                  <div className="text-primary font-bold text-[32px] mb-3">
                    สี
                  </div>
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    ชื่อสี
                  </div>
                  <div className="border-2 rounded-xl px-5 py-2">{title}</div>
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-6">
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      ชื่อสินค้า
                    </div>
                    <div className="border-2 rounded-xl px-5 py-2">{title}</div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      เลข มอก. (ถ้ามี)
                    </div>
                    <div className="border-2 rounded-xl px-5 py-2">
                      {numberTis ? numberTis : "ไม่มี เลข มอก."}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-2 relative">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  รูปตัวอย่างสินค้า
                </div>
                <div className=" rounded-lg w-full ">
                  <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                    <img
                      className="w-full h-full object-contain"
                      src={image?.size > 0 ? URL.createObjectURL(image) : image}
                      alt="image"
                    />
                  </div>
                </div>
              </div>

              {isConcreteProduct || sizeList.length !== 0 ? (
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-[20px] text-tnn_gray_200">
                    {isCementProduct ||
                    isTankProduct ||
                    isDiamondBoardProduct ||
                    isDiamondSyntheticWoodProduct
                      ? "ขนาด"
                      : "ขนาด หรือ ชนิดผลิตภัณฑ์"}
                  </div>
                  <div className="bg-[#f2f2f2] rounded-md px-5 py-2">
                    <div className="flex flex-col gap-2">
                      {sizeList?.map((size, index) => {
                        return (
                          <div key={index}>
                            <div className="flex gap-2">
                              <span className="font-bold">{index + 1}.</span>
                              <span>{size.size_name}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="flex flex-col gap-2">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  คำอธิบายเบื้องต้น
                </div>
                <div className="border-2 rounded-xl px-5 py-2">
                  {description}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  คุณสมบัติ
                </div>
                {/* <div
                  className="border-2 rounded-xl px-5 py-2"
                  dangerouslySetInnerHTML={{
                    __html: feature?.replace(/\n/g, "<br>"),
                  }}
                /> */}
                <ContentView value={feature} />
              </div>

              <div className="flex flex-col gap-2">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  ลำดับ
                </div>
                <div className="border-2 rounded-xl px-5 py-2">
                  {indexNumber}
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  คู่มือการใช้งาน
                </div>
                {filePDF ? (
                  <div className="flex group">
                    <div
                      className="flex justify-center items-center gap-1 border-2 border-primary rounded-full px-5 py-2 cursor-pointer group-hover:bg-primary duration-500"
                      onClick={handleOpen}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary group-hover:text-white duration-500"
                      >
                        <path
                          d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                          stroke="CurrentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div className="font-semibold text-primary group-hover:text-white duration-500">
                        {filePDFName}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-[32px] pl-5">-</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductView;
