import React from "react";
import { Amplify } from "aws-amplify";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Signin from "./pages/Signin";
import User from "./pages/User";
import ProtectedRoute from "./components/ProtectedRoute";
import { Route, Routes } from "react-router-dom";

import Overview from "./pages/Overview";

import Blog from "./pages/Blog";
import FormBlog from "./pages/Blog/FormBlog";
import BlogView from "./pages/Blog/BlogView";

import FormUser from "./pages/User/FormUser";

import OrderManagement from "./pages/OrderManagement";
import QuotationView from "./pages/OrderManagement/QuotationView";

// import Category from "./pages/Category";
// import FormCategory from "./pages/Category/FormCategory";

import ProductCategory from "./pages/ProductCategory";
import FormSubCategory from "./pages/ProductCategory/FormSubCategory";
import FormSubCategoryLevel3 from "./pages/ProductCategory/FormSubCategoryLevel3";
import FormSubCategoryLevel4 from "./pages/ProductCategory/FormSubCategoryLevel4";

import FormProduct from "./pages/Product/FormProduct";
import ProductView from "./pages/Product/ProductView";

import HomeBanner from "./pages/Banner/HomeBanner";
import AboutUsBanner from "./pages/Banner/AboutUsBanner";
import ContactUsBanner from "./pages/Banner/ContactUsBanner";
import ProductUsBanner from "./pages/Banner/ProductBanner";
import NewsBanner from "./pages/Banner/NewsBanner";
import FormVideoBanner from "./pages/Banner/FormVideoBanner";
import FormImageBanner from "./pages/Banner/FormImageBanner";
import FormImageValues from "./pages/Banner/FormImageValues";
import { SideBarProvider } from "./context/sideBarContext";

import RoofCoverSkuProduct from "./pages/Product/RoofCoverSkuProduct";
import DoorFrameDoorSkuProduct from "./pages/Product/DoorFrameDoorSkuProduct";

Amplify.configure({
  Auth: {
    identityPoolId: "ap-southeast-1:66457ee8-2826-43e6-8673-827049351db3",
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_x1WXg2AIm",
    userPoolWebClientId: "3uj3bg0qbqh7cujrpd43dg877u",
  },
  Storage: {
    bucket: "dashboardthananan59cd00d4a4f94eee8422f5a763685a104658-dev",
    region: "ap-southeast-1",
  },
});

function App() {
  console.warn = function filterWarnings() {};
  console.error = function filterErrors() {};

  //update

  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
      parent route paths, and nested route elements render inside
      parent route elements. See the note about <Outlet> below. */}

      <SideBarProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="overview" element={<Overview />} />

              <Route path="blog" element={<Blog />} />
              <Route path="blog/:id" element={<FormBlog />} />
              <Route path="blogView/:id" element={<BlogView />} />

              <Route path="homeBanner" element={<HomeBanner />} />
              <Route path="aboutUsBanner" element={<AboutUsBanner />} />
              <Route path="contactUsBanner" element={<ContactUsBanner />} />
              <Route path="productUsBanner" element={<ProductUsBanner />} />
              <Route path="newsBanner" element={<NewsBanner />} />
              <Route path="formVideoBanner" element={<FormVideoBanner />} />
              <Route
                path="formImageBanner/:uploadId"
                element={<FormImageBanner />}
              />
              <Route
                path="formImageValues/:uploadId"
                element={<FormImageValues />}
              />

              <Route path="user" element={<User />} />
              <Route path="user/:id" element={<FormUser />} />

              <Route path="orderManagement" element={<OrderManagement />} />
              <Route path="quotationView/:id" element={<QuotationView />} />

              {/* <Route path="category" element={<Category />} />
              <Route path="category/add" element={<FormCategory />} /> */}

              {/* Category */}
              <Route
                path="category/:categoryPath"
                element={<ProductCategory />}
              />
              <Route
                path="category/:categoryPath/:subCategoryPath"
                element={<FormSubCategory />}
              />
              <Route
                path="category/:categoryPath/:subCategoryPath/categoryL3/:id"
                element={<FormSubCategoryLevel3 />}
              />
              <Route
                path="category/:categoryPath/:subCategoryPath/categoryL4/:id"
                element={<FormSubCategoryLevel4 />}
              />

              {/* Product */}
              <Route
                path="product/:categoryPath/:subCategoryPath/:id"
                element={<FormProduct />}
              />
              <Route
                path="product/:subCategoryId/:id"
                element={<ProductView />}
              />
              <Route
                path="/roofCoverSkuProduct/:categoryPath/:subCategoryPath/:categoryL2Id"
                element={<RoofCoverSkuProduct />}
              />
              <Route
                path="/doorFrameDoorSkuProduct/:categoryPath/:subCategoryPath/:categoryL2Id"
                element={<DoorFrameDoorSkuProduct />}
              />

              <Route path="*" element={<div>Not Found</div>} />
            </Route>
            <Route index element={<Signin />} />
          </Route>
        </Routes>
      </SideBarProvider>
    </div>
  );
}

export default App;
