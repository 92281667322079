import React from "react";
import { useUserStore } from "../_store/userStore";
import { HiUser } from "react-icons/hi2";
import { ICON_LOGOUT, ICON_USER } from "../utils/useIcons";
import useSweetAlert from "../_hooks/useSweetAlert";

const AppNavbar = () => {
  const clearUser = useUserStore((state) => state.clearUser);
  const user = useUserStore((state) => state.user);

  const { alertConfirm, alertSuccess } = useSweetAlert();

  return (
    <div className=" fixed flex justify-end gap-5  items-center pr-[90px] bg-[#F9F9F9] border-b h-14 z-10 w-full text-primary  ">
      <div className=" flex gap-1 items-center">
        <ICON_USER className=" w-5 h-5 " />
        {user ? user.username.toUpperCase() : ""}
      </div>
      <div className=" w-[1px] h-6 bg-primary"></div>
      <div
        onClick={() => {
          alertConfirm("ต้องการ LOG OUT หรือไม่", "", () => clearUser());
        }}
        className=" flex gap-1 items-center hover:underline hover:underline-offset-4 duration-500 cursor-pointer group "
      >
        <ICON_LOGOUT className=" w-5 h-5 " />
        <div className="hover:font-semibold">LOG OUT</div>
      </div>
    </div>
  );
};

export default AppNavbar;
