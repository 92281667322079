import React from "react";
import { s3File } from "../../utils/fileFormater";
import { CardProduct } from "../../components/Card/Cardmain";

const DiamondRoofTilesProduct = ({
  products,
  clickViewProduct,
  clickEditProduct,
  clickDeleteProduct,
}) => {
  // Group Product Diamond By Color Type
  const groupColorDiamondProduct = _.groupBy(products, "color_type");
  const standardColors = groupColorDiamondProduct?.standard_color;
  const metallicColors = groupColorDiamondProduct?.metallic_color;
  const platinumColors = groupColorDiamondProduct?.platinum_color;
  const specialColors = groupColorDiamondProduct?.special_color;
  const premiumColors = groupColorDiamondProduct?.premium_color;

  // Group Roof Product
  const groupIsRoofStandardColorProduct = _.groupBy(
    standardColors,
    "is_roof_cover"
  );
  const standardColorProducts = groupIsRoofStandardColorProduct?.is_roof;

  const groupIsRoofMetallicColorProduct = _.groupBy(
    metallicColors,
    "is_roof_cover"
  );
  const metallicColorProducts = groupIsRoofMetallicColorProduct?.is_roof;

  const groupIsRoofPlatinumColorProduct = _.groupBy(
    platinumColors,
    "is_roof_cover"
  );
  const platinumColorProducts = groupIsRoofPlatinumColorProduct?.is_roof;

  const groupIsRoofSpecialColorProduct = _.groupBy(
    specialColors,
    "is_roof_cover"
  );
  const specialColorProducts = groupIsRoofSpecialColorProduct?.is_roof;

  const groupIsRoofPremiumColorProduct = _.groupBy(
    premiumColors,
    "is_roof_cover"
  );
  const premiumColorProducts = groupIsRoofPremiumColorProduct?.is_roof;

  return (
    <div className="flex justify-center items-center">
      {products.length === 0 ? (
        <img src="/not-found.png" className=" w-40 h-40" />
      ) : (
        <div className="flex flex-col gap-5">
          {standardColorProducts ? (
            <div className="flex flex-col gap-5">
              <div className="font-bold text-primary text-[28px]">
                สีมาตรฐาน
              </div>
              <div className="grid grid-cols-4 gap-5">
                {standardColorProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {metallicColorProducts ? (
            <div className="flex flex-col gap-5">
              <div className="font-bold text-primary text-[28px]">
                สีเมทัลลิค
              </div>
              <div className="grid grid-cols-4 gap-5">
                {metallicColorProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {platinumColorProducts ? (
            <div className="flex flex-col gap-5">
              <div className="font-bold text-primary text-[28px]">
                สีแพลตตินัม
              </div>
              <div className="grid grid-cols-4 gap-5">
                {platinumColorProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {specialColorProducts ? (
            <div className="flex flex-col gap-5">
              <div className="font-bold text-primary text-[28px]">พิเศษ</div>
              <div className="grid grid-cols-4 gap-5">
                {specialColorProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {premiumColorProducts ? (
            <div className="flex flex-col gap-5">
              <div className="font-bold text-primary text-[28px]">
                พรีเมี่ยม
              </div>
              <div className="grid grid-cols-4 gap-5">
                {premiumColorProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default DiamondRoofTilesProduct;
