import { ICON_DELETE, ICON_EDIT } from "../../utils/useIcons";
import {
  CONCRETE_CATEGORY_ID,
  PUMP_CATEGORY_ID,
  TANK_CATEGORY_ID,
  NATURA_WATER_PAC_ID,
  DIAMOND_CATEGORY_ID,
  DIAMOND_DOUBLE_WAVE_ID,
  DIAMOND_DECHRA_SENATOR_SHINGLE_ID,
  DIAMOND_DECHRA_MILANO_ID,
  DIAMOND_BOARD_ID,
  DIAMOND_SYNTHETIC_WOOD_ID,
  OTHER_PRODUCT_CATEGORY_ID,
  DIAMOND_JIARANAI_TILES_ID,
  THAI_MODERN_ID,
  THAI_CLASSIC_ID,
  ELEGANT_ID,
} from "../../utils/constants";
import { AppApi } from "../../api";
import { useEffect, useState } from "react";

export const CardProduct = ({
  isMasterProduct = false,
  product,
  masterId,
  isCategory,
  categoryId,
  subCategoryId,
  onClickEdit,
  onClick,
  onClickDelete,
  img,
  totalProduct,
  totalSubCategory,
  name,
  list,
  isOtherSanitaryEquipment = false,
  isOtherDoorAndFrameDoor = false,
  doorExteriorAndInteriorUse = false,
  doorInteriorUseOnly = false,
  frameExteriorAndInteriorUse = false,
  frameInteriorUseOnly = false,
}) => {
  // Find Product Sku
  const [productSkus, setProductSkus] = useState([]);

  // Check Category
  const isConcreteProduct = categoryId === CONCRETE_CATEGORY_ID;
  const isPumpProduct = categoryId === PUMP_CATEGORY_ID;
  const isTankProduct = categoryId === TANK_CATEGORY_ID;
  const isDiamondProduct = categoryId === DIAMOND_CATEGORY_ID;
  const isDiamondBoardProduct = categoryId === DIAMOND_BOARD_ID;
  const isDiamondSyntheticWoodProduct =
    categoryId === DIAMOND_SYNTHETIC_WOOD_ID;
  const isOtherProduct = categoryId === OTHER_PRODUCT_CATEGORY_ID;

  // Check SubCategory
  const isNaturaWaterPacProduct = subCategoryId === NATURA_WATER_PAC_ID;
  const isDiamondDoubleWave = subCategoryId === DIAMOND_DOUBLE_WAVE_ID;

  const isDiamondJiaranai = subCategoryId === DIAMOND_JIARANAI_TILES_ID;
  const isThaiModern = subCategoryId === THAI_MODERN_ID;
  const isThaiClassic = subCategoryId === THAI_CLASSIC_ID;
  const isElegant = subCategoryId === ELEGANT_ID;

  const isDiamondDechraSenatorShingle =
    subCategoryId === DIAMOND_DECHRA_SENATOR_SHINGLE_ID;
  const isDiamondDechraMilano = subCategoryId === DIAMOND_DECHRA_MILANO_ID;

  const [loading, setLoading] = useState(false);
  const [countProductSku, setCountProductSku] = useState(0);

  useEffect(() => {
    if (!isMasterProduct) return;
    loadCountProductSku();
  }, []);

  if (
    isThaiModern ||
    isThaiClassic ||
    isElegant ||
    doorExteriorAndInteriorUse ||
    doorInteriorUseOnly ||
    frameExteriorAndInteriorUse ||
    frameInteriorUseOnly
  ) {
    useEffect(() => {
      let ignore = false;
      if (!ignore) {
        const load = async () => {
          try {
            const { data } = await AppApi.get(
              `products?category_ids=${subCategoryId}&limit=500`
            );
            const products = data.products;
            setProductSkus(
              products?.filter((product) => {
                return product?.master_id;
              })
            );
          } catch (error) {
            console.log(error);
          }
        };
        load();
      }
      return () => {
        ignore = true;
      };
    }, [subCategoryId]);
  }

  const totalLabel = () => {
    if (
      isTankProduct ||
      isDiamondProduct ||
      isDiamondBoardProduct ||
      isDiamondSyntheticWoodProduct
    ) {
      return `${totalSubCategory}`;
    }

    if (isMasterProduct) {
      if (loading) {
        return `. . .`;
      }
      return `${countProductSku}`;
    }

    return `${totalProduct}`;
  };

  const loadCountProductSku = async () => {
    setLoading(true);
    try {
      const { data } = await AppApi.get(`products/countProductSku/${masterId}`);
      setCountProductSku(data?.countProductSku);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-5 bg-white rounded-lg shadowcardsmall hover:shadow-xl duration-500 cursor-pointer w-[231px] h-[295px]">
      <div className="flex justify-end gap-2.5 ">
        <div
          onClick={onClickEdit}
          className=" flex items-center gap-1 text-primary_gray text-sm group hover:text-primary_red duration-500 "
        >
          <ICON_EDIT className="w-4 h-4 " />
          <div>แก้ไข</div>
        </div>
        {isConcreteProduct ||
        isPumpProduct ||
        isTankProduct ||
        isNaturaWaterPacProduct ||
        isDiamondProduct ||
        isDiamondJiaranai ||
        isThaiModern ||
        isThaiClassic ||
        isElegant ||
        isDiamondDoubleWave ||
        isDiamondDechraSenatorShingle ||
        isDiamondDechraMilano ||
        isOtherProduct ||
        isOtherSanitaryEquipment ||
        isOtherDoorAndFrameDoor ? (
          ""
        ) : (
          <ICON_DELETE
            onClick={() => onClickDelete(product, countProductSku)}
            className="w-4 h-4 text-primary_red hover:text-tnn_red_600"
          />
        )}
      </div>

      <div onClick={onClick} className=" cursor-pointer w-full h-full">
        <div className="border border-tnn_gray_50 rounded-[5px] mt-5 mb-2 w-[191px] h-[163px]">
          <img
            className="p-2.5 w-full h-full object-contain mx-auto hover:scale-105 duration-500 "
            src={img}
          />
        </div>

        <div className="font-bold text-xl text-tnn_gray_200 truncate">
          {name}
        </div>
        <div className=" flex gap-5 text-tnn_gray_200">
          {totalProduct || isCategory || isMasterProduct ? (
            <div className="flex gap-2.5">
              <div className=" font-light">ทั้งหมด :</div>
              {isDiamondJiaranai || isOtherDoorAndFrameDoor ? (
                <div className="font-bold">{totalSubCategory}</div>
              ) : isThaiModern ||
                isThaiClassic ||
                isElegant ||
                doorExteriorAndInteriorUse ||
                doorInteriorUseOnly ||
                frameExteriorAndInteriorUse ||
                frameInteriorUseOnly ? (
                <div className="font-bold">
                  {Number(totalLabel() - productSkus.length)}
                </div>
              ) : (
                <div className="font-bold">{totalLabel()}</div>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="flex gap-2.5 ">
            <div className=" font-light">ลำดับ :</div>
            <div className="font-bold">{list}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
