import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import { useParams } from "react-router-dom";
import { AppApi } from "../../api";
import useSweetAlert from "../../_hooks/useSweetAlert";
import { InputMain } from "../../components/Input/inputmain";
import { ICON_EXPORT_QUOTATION } from "../../utils/useIcons";
import {
  PUMP_CATEGORY_ID,
  TANK_CATEGORY_ID,
  DIAMOND_BOARD_ID,
  DIAMOND_SYNTHETIC_WOOD_ID,
  OTHER_PRODUCT_CATEGORY_ID,
  DIAMOND_JIARANAI_TILES_ID,
} from "../../utils/constants";
import { functionExportPdf } from "../../utils/functions";
import PopupMain from "../../components/Popup/popup.main";
import numeral from "numeral";
import { NumericFormat } from "react-number-format";

const QuotationView = () => {
  const params = useParams();
  const quotationId = params.id;

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();

  const [dataQuotation, setDataQuotation] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const onChangeTotalPrice = (e) => {
    e?.preventDefault();
    let value = e.target.value;
    let output = parseFloat(value?.replace(/,/g, ""))
      ? parseFloat(value?.replace(/,/g, ""))
      : 0;

    setTotalPrice(output);
  };

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`quotations/${quotationId}`);
          const quotation = data.quotation;

          setDataQuotation(quotation);
          setCartList(quotation?.cart_list);
          setTotalPrice(quotation?.total_price);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [quotationId]);

  if (loading) {
    return (
      <div className="bg-[#f4f4f4] w-full h-full">
        <Container>
          <div>Loading . . .</div>
        </Container>
      </div>
    );
  }

  const handleOpen = () => setOpen(!open);

  const handleOpen2 = () => setOpen2(!open2);

  //-------------------------------------------------------------------------//

  // เปลี่ยนสถานะ ส่งงานสำเร็จ
  const onClickConfirmQuotation = () => {
    if (totalPrice < 1) {
      alertError("กรุณาระบุยอดรวมสินค้า");
    } else {
      setOpen(true);
    }
  };

  const onClickConfirm = async () => {
    try {
      let payload = {
        quotation_status: "confirm",
        total_price: totalPrice,
      };

      await AppApi.put(`quotations/${quotationId}`, payload);

      alertSuccess("ยืนยันเรียบร้อย");

      const { data } = await AppApi.get(`quotations/${quotationId}`);
      const quotation = data.quotation;

      setDataQuotation(quotation);
      setCartList(quotation?.cart_list);
      setTotalPrice(quotation?.total_price);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  //-------------------------------------------------------------------------//

  // เปลี่ยนสถานะ กำลังตรวจสอบ
  const onClickInspectingQuotation = () => {
    setOpen2(true);
  };

  const onClickConfirmInspecting = async () => {
    try {
      let payload = {
        quotation_status: "inspecting",
      };

      await AppApi.put(`quotations/${quotationId}`, payload);

      alertSuccess("ตั้งสถานะเรียบร้อย");

      const { data } = await AppApi.get(`quotations/${quotationId}`);
      const quotation = data.quotation;

      setDataQuotation(quotation);
      setCartList(quotation?.cart_list);
      setTotalPrice(quotation?.total_price);
    } catch (error) {
      console.log(error);
    } finally {
      setOpen2(false);
    }
  };

  //-------------------------------------------------------------------------//

  // Export PDF
  const onClickExportPDF = () => {
    let docChecking = document.getElementById("checking");
    let docTitleChecking = document.getElementById("title_checking");

    if (docTitleChecking) {
      docTitleChecking.style.display = "block";
    }

    if (docChecking) {
      docChecking.style.display = "none";
    }

    functionExportPdf({
      id: "quotation",
      fileName: "ใบเสนอราคา",
    });

    if (docTitleChecking) {
      docTitleChecking.style.display = "none";
    }

    if (docChecking) {
      docChecking.style.display = "block";
    }
  };

  return (
    <div className="bg-[#f4f4f4] w-full h-full">
      <Container>
        {/*---------- แสดงสถานะ ----------*/}
        <div className="flex justify-between items-center mb-6">
          <div className=" font-light text-[48px] text-primary">ใบเสนอราคา</div>
          {dataQuotation?.quotation_status === "not-confirm" ? (
            <div className="bg-primary_red rounded-full px-5 py-[6px] text-white text-base text-center">
              ยังไม่ได้ตรวจสอบ
            </div>
          ) : dataQuotation?.quotation_status === "inspecting" ? (
            <div className="bg-primary_yellow rounded-full px-5 py-[6px] text-white text-base text-center">
              กำลังตรวจสอบ
            </div>
          ) : (
            <div className="bg-primary_green rounded-full px-5 py-[6px] text-white text-base text-center">
              ส่งงานสำเร็จ
            </div>
          )}
        </div>

        <div className="bg-white rounded-xl">
          {/*---------- ปุ่ม Export PDF ----------*/}
          <div className="flex justify-end px-[60px] pt-6 ">
            <button
              onClick={onClickExportPDF}
              className="flex bg-white border-2 border-primary_red rounded-full px-5 py-1 justify-center items-center gap-2 group hover:bg-primary_red duration-300"
            >
              <ICON_EXPORT_QUOTATION
                className={`text-primary_red group-hover:text-white`}
              />
              <div className="font-semibold text-primary_red group-hover:text-white">
                Export PDF
              </div>
            </button>
          </div>

          {/*---------- ข้อมูลใบเสนอราคา ----------*/}
          <div id="quotation" className=" px-[60px] py-10 flex flex-col gap-5">
            <div
              id="title_checking"
              className="text-center text-primary mb-6 hidden"
            >
              <div className="font-semibold text-3xl">ใบเสนอราคา</div>
            </div>

            <div className="bg-[#f4f4f4] rounded-3xl px-16 py-5 text-tnn_gray_200">
              <div className="flex flex-col gap-5">
                <div className="grid grid-cols-2">
                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-[20px]">ชื่อ</div>
                    <div>{dataQuotation?.first_name}</div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-[20px]">นามสกุล</div>
                    <div>{dataQuotation?.last_name}</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-1">
                  <div className="flex flex-col">
                    <div className="font-semibold text-[20px]">เบอร์โทร</div>
                    <div>{dataQuotation?.phone_number}</div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-[20px]">อีเมล์</div>
                    <div>{dataQuotation?.email}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 text-tnn_gray_200">
              <div className="font-semibold text-[20px]">สินค้า</div>
              <div className="flex flex-col">
                <div className="grid grid-cols-3 bg-tnn_gray_50 p-2 text-center font-semibold">
                  <div>ประเภทสินค้า</div>
                  <div>สินค้า</div>
                  <div>จำนวน</div>
                </div>
                {cartList?.map((product, index) => {
                  return (
                    //----------------------- สินค้า และ ครอบ -----------------------//
                    <div key={index} className="text-sm">
                      {product?.product_name && product?.cover_roof_name ? (
                        <div className="grid grid-cols-3 bg-[#f4f4f4] py-2 text-center items-center border-b-2">
                          {product?.parent_category_name ===
                          "กระเบื้องหลังคา" ? (
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                {product?.parent_category_name}
                              </div>
                              {product?.dechra_type ? (
                                <div>{product?.dechra_type}</div>
                              ) : (
                                <div>{product?.category_name}</div>
                              )}
                            </div>
                          ) : product?.curr_parent_category_id ===
                            DIAMOND_JIARANAI_TILES_ID ? (
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                {product?.parent_category_name}
                              </div>
                              <div>{product?.category_name}</div>
                            </div>
                          ) : (
                            <div>{product?.category_name}</div>
                          )}

                          <div className="">
                            <div>
                              สี - {product?.product_name}{" "}
                              {product?.type_color_product
                                ? ` (${product?.type_color_product})`
                                : ""}
                              {product?.polish_tiles_type
                                ? ` (${product?.polish_tiles_type})`
                                : ""}
                              {product?.size_product ? (
                                <div className="text-xs">
                                  ขนาด {product?.size_product}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {product?.have_start_pad ? (
                              <div>มีแผ่นเริ่ม</div>
                            ) : (
                              ""
                            )}
                            <div>
                              {product?.cover_roof_name} (สี
                              {product?.cover_roof_color})
                            </div>
                          </div>

                          <div className="flex flex-col h-full justify-between">
                            <div>{product?.product_qty}</div>
                            {product?.amount_cover_roof ? (
                              <div>{product?.amount_cover_roof}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : product?.product_name ? (
                        //----------------------- สินค้าอย่างเดียว -----------------------//
                        <div className="grid grid-cols-3 bg-[#f4f4f4] py-2 text-center items-center border-b-2">
                          {product?.main_category_id === PUMP_CATEGORY_ID ? (
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                {product?.parent_category_name}
                              </div>
                              <div>{product?.category_name}</div>
                            </div>
                          ) : product?.main_category_id === TANK_CATEGORY_ID ? (
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                {product?.parent_category_name}
                              </div>
                              <div>{product?.category_name}</div>
                            </div>
                          ) : product?.parent_category_name ===
                            "กระเบื้องหลังคา" ? (
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                {product?.parent_category_name}
                              </div>
                              {product?.dechra_type ? (
                                <div>{product?.dechra_type}</div>
                              ) : (
                                <div>{product?.category_name}</div>
                              )}
                            </div>
                          ) : product?.curr_parent_category_id ===
                            DIAMOND_JIARANAI_TILES_ID ? (
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                {product?.parent_category_name}
                              </div>
                              <div>{product?.category_name}</div>
                            </div>
                          ) : product?.type_concrete_piles ? (
                            <div>{product?.type_concrete_piles}</div>
                          ) : product?.main_category_id === DIAMOND_BOARD_ID ||
                            product?.main_category_id ===
                              DIAMOND_SYNTHETIC_WOOD_ID ? (
                            <div className="flex flex-col">
                              <div className="font-semibold">
                                {product?.main_category_name}
                              </div>
                              <div>{product?.category_name}</div>
                            </div>
                          ) : product?.main_category_id ===
                            OTHER_PRODUCT_CATEGORY_ID ? (
                            <div>{product?.parent_category_name}</div>
                          ) : (
                            <div>{product?.category_name}</div>
                          )}

                          <div className="flex flex-col gap-1">
                            {product?.parent_category_name ===
                              "กระเบื้องหลังคา" ||
                            product?.curr_parent_category_id ===
                              DIAMOND_JIARANAI_TILES_ID ||
                            product?.parent_category_name ===
                              "ถังเก็บน้ำบนดิน" ? (
                              <>
                                <div>
                                  สี - {product?.product_name}{" "}
                                  {product?.type_color_product
                                    ? ` (${product?.type_color_product})`
                                    : ""}
                                  {product?.polish_tiles_type
                                    ? ` (${product?.polish_tiles_type})`
                                    : ""}
                                </div>
                              </>
                            ) : product?.main_category_id ===
                              OTHER_PRODUCT_CATEGORY_ID ? (
                              product?.color_door_product ? (
                                <div className="flex flex-col">
                                  <div>{product?.category_name}</div>
                                  <div>รหัส : {product?.product_name}</div>
                                  <div>สี : {product?.color_door_product}</div>
                                </div>
                              ) : (
                                <div className="flex flex-col">
                                  <div>{product?.category_name}</div>
                                  <div>{product?.product_name}</div>
                                </div>
                              )
                            ) : (
                              <div>{product?.product_name}</div>
                            )}

                            {product?.type_product ? (
                              <div className="text-[12px]">
                                ชนิด {product?.type_product}
                              </div>
                            ) : (
                              ""
                            )}

                            {product?.board_color ? (
                              <div>สี : {product?.board_color}</div>
                            ) : (
                              ""
                            )}

                            {product?.synthetic_wood_color ? (
                              <div>สี : {product?.synthetic_wood_color}</div>
                            ) : (
                              ""
                            )}

                            {product?.size_product ? (
                              <div className="text-[12px]">
                                ขนาด {product?.size_product}
                              </div>
                            ) : (
                              ""
                            )}
                            {product?.pump_name ? (
                              <div>{`ปั้ม - ${product?.pump_name}`}</div>
                            ) : (
                              ""
                            )}
                            {product?.have_start_pad ? (
                              <div>มีแผ่นเริ่ม</div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div>{product?.product_qty}</div>
                        </div>
                      ) : (
                        //----------------------- ครอบอย่างเดียว -----------------------//
                        <div className="grid grid-cols-3 bg-[#f4f4f4] py-2 text-center items-center border-b-2">
                          <div className="flex flex-col">
                            {product?.curr_parent_category_id ===
                            DIAMOND_JIARANAI_TILES_ID ? (
                              <div className="font-semibold">
                                ครอบ{product?.parent_category_name}
                              </div>
                            ) : (
                              <div className="font-semibold">ครอบ</div>
                            )}
                            <div>{product?.category_name}</div>
                          </div>

                          <div>
                            {product?.cover_roof_name} (สี
                            {product?.cover_roof_color})
                          </div>

                          <div>{product?.amount_cover_roof}</div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col gap-2 text-tnn_gray_200">
              <div className="font-semibold text-[20px]">สถานที่จัดส่ง</div>
              <div>{dataQuotation?.address}</div>
            </div>

            <div className="flex flex-col gap-2 text-tnn_gray_200">
              <div className="font-semibold text-[20px]">วันเวลาที่ใช้</div>
              <div>{dataQuotation?.date_of_use}</div>
            </div>

            <div className="flex flex-col gap-2 text-tnn_gray_200">
              <div className="font-semibold text-[20px]">หมายเหตุ</div>
              <div>{dataQuotation?.remark}</div>
            </div>

            <div
              id="checking"
              className="flex flex-col gap-2 text-tnn_gray_200"
            >
              <div className="font-semibold text-[20px]">
                ยอดรวมสินค้าทั้งหมด (บาท)
              </div>
              <NumericFormat
                // maxLength={11}
                value={totalPrice === 0 ? "" : totalPrice}
                placeholder="0.00"
                onChange={onChangeTotalPrice}
                thousandSeparator=","
                inputMode="decimal"
                pattern="[0-9],*"
                className="border border-tnn_gray_50 focus:outline-none focus:right-1 focus:border-tnn_gray_200 rounded-[10px] block w-full px-5 py-2 placeholder:text-tnn_gray_50 text-tnn_gray_200 bg-white text-base"
              />
            </div>
          </div>

          <div className="px-[60px] pb-6 w-full">
            <button
              className="bg-primary text-white text-[20px] p-2 rounded-full w-full hover:opacity-80"
              onClick={onClickConfirmQuotation}
            >
              ยืนยันการตรวจสอบ
            </button>
          </div>

          <div className="px-[60px] pb-6 w-full">
            <button
              className="bg-primary_yellow text-white text-[20px] p-2 rounded-full w-full hover:opacity-80"
              onClick={onClickInspectingQuotation}
            >
              ตั้งสถานะกำลังตรวจสอบ
            </button>
          </div>
        </div>
      </Container>

      {/*---------- ยืนยันสถานะ ส่งงานเสร็จสิ้น ----------*/}
      <PopupMain
        open={open}
        handleOpen={handleOpen}
        onClick={onClickConfirm}
        statusQuotation="confirm"
      />

      {/*---------- ยืนยันสถานะ กำลังตรวจสอบ ----------*/}
      <PopupMain
        open={open2}
        handleOpen={handleOpen2}
        onClick={onClickConfirmInspecting}
        statusQuotation="inspecting"
      />
    </div>
  );
};

export default QuotationView;
