import React from "react";
import { s3File } from "../../utils/fileFormater";
import { CardProduct } from "../../components/Card/Cardmain";
import { useNavigate } from "react-router-dom";

const DoorFrameDoorProduct = ({
  categoryPath,
  subCategoryPath,
  categoryL2Id,
  subCategoryId,
  products,
  clickEditProduct,
  clickDeleteProduct,
}) => {
  const navigate = useNavigate();

  const resultProducts = products?.filter((el) => !el.master_id);

  const clickViewProduct = (id) => {
    navigate(
      `/doorFrameDoorSkuProduct/${categoryPath}/${subCategoryPath}/${categoryL2Id}?masterId_${id}`
    );
  };

  return (
    <>
      <div className="font-bold text-primary text-[28px] -mb-7">รหัส</div>

      <div className="flex justify-center items-center">
        {resultProducts.length === 0 ? (
          <img src="/not-found.png" className=" w-40 h-40" />
        ) : (
          <div className="grid grid-cols-4 gap-5">
            {resultProducts
              ?.sort((a, b) => a.number_index - b.number_index)
              ?.map((product, index) => {
                const imageSrc = s3File(product?.image_key);
                return (
                  <CardProduct
                    key={index}
                    isMasterProduct={true}
                    masterId={product._id}
                    totalProduct={product.totalProduct}
                    name={product.product_name}
                    img={imageSrc}
                    product={product}
                    list={product.number_index}
                    onClick={() => clickViewProduct(product._id)}
                    onClickEdit={() => clickEditProduct(product._id)}
                    onClickDelete={(product, countProductSku) => {
                      clickDeleteProduct(product, countProductSku);
                    }}
                  />
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default DoorFrameDoorProduct;
