import Swal from "sweetalert2";
import { hexColors } from "../utils/constants";

const useSweetAlert = () => {
  const alertSuccess = (title, message, callback) => {
    Swal.fire({
      icon: "success",
      title: title,
      text: message,
      showConfirmButton: true,
      confirmButtonText: "ตกลง",
      confirmButtonColor: hexColors.primary,
      preConfirm: callback,
    });
  };

  const alertError = (title, message) => {
    Swal.fire({
      icon: "error",
      title: title,
      text: message,
      showConfirmButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: hexColors.primary,
    });
  };

  const alertConfirm = (title, message, callback) => {
    Swal.fire({
      icon: "warning",
      title: title,
      text: message,
      showCancelButton: true,
      confirmButtonColor: hexColors.primary,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      preConfirm: async () => {
        Swal.showLoading();
        await callback();
      },
      allowOutsideClick: false,
    });
  };

  return { alertSuccess, alertError, alertConfirm };
};

export default useSweetAlert;
