import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppApi } from "../../api";
import { TypeFile, s3File } from "../../utils/fileFormater";
import { HiPencil, HiTrash } from "react-icons/hi2";
import { Storage } from "aws-amplify";
import useSweetAlert from "../../_hooks/useSweetAlert";
import Container from "../../components/Container";
import { InputMain } from "../../components/Input/inputmain";
import {
  ELEGANT_ID,
  IMAGE_TYPE_LIST,
  THAI_CLASSIC_ID,
  THAI_MODERN_ID,
} from "../../utils/constants";
import { errorScroll } from "../../utils/functions";

const FormSubCategoryLevel4 = () => {
  const params = useParams();
  const categoryPath = params.categoryPath;
  const subCategoryPath = params.subCategoryPath;
  const subCategoryLevel4Id = params.id;

  const navigate = useNavigate();

  const isAddMode = subCategoryLevel4Id === "add";
  const isEditMode = subCategoryLevel4Id.length === 24;

  // Check Diamond Jiaranai Sub Category
  const isThaiModern = subCategoryLevel4Id === THAI_MODERN_ID;
  const isThaiClassic = subCategoryLevel4Id === THAI_CLASSIC_ID;
  const isElegant = subCategoryLevel4Id === ELEGANT_ID;

  const [title, setTitle] = useState("");
  const [pathName, setPathName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreviewRoof, setImagePreviewRoof] = useState(null);
  const [imagePreviewRoofCover, setImagePreviewRoofCover] = useState(null);
  const [indexNumber, setIndexNumber] = useState("1");

  const [tempPathName, setTempPathName] = useState("");

  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [parentCategoryTitle, setParentCategoryTitle] = useState("");

  const [subCategoryL2Path, setsubCategoryL2Path] = useState("");

  const [saving, setSaving] = useState(false);

  const inputImageRef = useRef();
  const inputImagePreviewRoofRef = useRef();
  const inputImagePreviewRoofCoverRef = useRef();

  const [hasImage, setHasImage] = useState(false);
  const [hasImagePreviewRoof, setHasImagePreviewRoof] = useState(false);
  const [hasImagePreviewRoofCover, setHasImagePreviewRoofCover] =
    useState(false);

  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`categorys/${categoryPath}`);
          const category = data.category;
          setCategoryId(category?._id);
          setParentCategoryTitle(category?.category_name);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [categoryPath]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`categorys/${subCategoryPath}`);
          const category = data.category;
          setSubCategoryId(category._id);

          const { data: dataSubCategoryL3 } = await AppApi.get(
            `categorys/${category.parent_category_id}`
          );
          setsubCategoryL2Path(dataSubCategoryL3.category.path);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [subCategoryPath]);

  const clearForm = () => {
    setTitle("");
    setImage(null);
    setImagePreviewRoof(null);
    setImagePreviewRoofCover(null);
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore && isEditMode) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`categorys/${subCategoryLevel4Id}`);
          const category = data.category;

          // setSubCategoryLevel3Id(category._id);
          setTitle(category.category_name);
          setPathName(category.path);
          setTempPathName(category.path);
          setImage(s3File(category.image_key));
          setIndexNumber(category.number_index);

          if (category.image_pre_roof_key !== "none") {
            setImagePreviewRoof(s3File(category.image_pre_roof_key));
          }

          if (category.image_pre_roof_cover_key !== "none") {
            setImagePreviewRoofCover(s3File(category.image_pre_roof_cover_key));
          }
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [subCategoryLevel4Id]);

  const onChangeImage = (e) => {
    if (!e.target.value) return;
    const type = e.target.files[0].type;
    if (!IMAGE_TYPE_LIST.includes(type)) {
      alertError("รองรับไฟล์ PNG หรือ JPEG");
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;
        // console.log(`width: `, width);
        // console.log(`height: `, height);

        if (width <= 334 && height <= 334) {
          // Valid image file
          // Perform further actions here
          setImage(file);
          setHasImage(false);
        } else {
          // Invalid image file

          alertError("รองรับไฟล์ขนาดไม่เกิน 334x334");
        }
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const onChangeImagePreviewRoof = (e) => {
    if (!e.target.value) return;
    const type = e.target.files[0].type;
    if (!IMAGE_TYPE_LIST.includes(type)) {
      alertError("รองรับไฟล์ PNG หรือ JPEG");
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;
        // console.log(`width: `, width);
        // console.log(`height: `, height);

        // if (width <= 1004 && height <= 312) {
        //   // Valid image file
        //   // Perform further actions here
        //   setImagePreviewRoof(file);
        //   setHasImagePreviewRoof(false);
        // } else {
        //   // Invalid image file
        //   alertError("รองรับไฟล์ขนาดไม่เกิน 1004x312");
        // }
        setImagePreviewRoof(file);
        setHasImagePreviewRoof(false);
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const onChangeImagePreviewRoofCover = (e) => {
    if (!e.target.value) return;
    const type = e.target.files[0].type;
    if (!IMAGE_TYPE_LIST.includes(type)) {
      alertError("รองรับไฟล์ PNG หรือ JPEG");
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;
        // console.log(`width: `, width);
        // console.log(`height: `, height);

        // if (width <= 1004 && height <= 1004) {
        //   // Valid image file
        //   // Perform further actions here
        //   setImagePreviewRoofCover(file);
        //   setHasImagePreviewRoofCover(false);
        // } else {
        //   // Invalid image file
        //   alertError("รองรับไฟล์ขนาดไม่เกิน 1004x312");
        // }
        setImagePreviewRoofCover(file);
        setHasImagePreviewRoofCover(false);
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!image) {
      setHasImage(true);
      errorScroll("errorImage");
      return;
    }

    setSaving(true);

    if (isAddMode) {
      const { data } = await AppApi.get(
        `categorys/checkExistCategory/${pathName}`
      );

      if (data.isExist) {
        alertError("ชื่อประเภทภาษาอังกฤษนี้ใช้ไปแล้ว");
        setSaving(false);
        return;
      }

      try {
        const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;

        await Storage.put(keyUpload, image);

        const payload = {
          parent_category_id: subCategoryId,
          category_paths: `${categoryPath},${subCategoryL2Path},${subCategoryPath}`,
          category_level: "4",
          category_name: title,
          path: pathName,
          image_key: keyUpload,
          number_index: indexNumber,
        };

        await AppApi.post(`categorys`, payload);

        clearForm();
        e.target.reset();

        alertSuccess("Create Success", "", () =>
          // navigate(`/category/${categoryPath}/${subCategoryPath}?view`)
          navigate(-1)
        );
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    } else {
      const { data } = await AppApi.get(
        `categorys/checkExistCategory/${pathName}`
      );

      if (data.isExist && tempPathName !== pathName) {
        alertError("ชื่อประเภทภาษาอังกฤษนี้ใช้ไปแล้ว");
        setSaving(false);
        return;
      }

      try {
        let payload = {
          category_name: title,
          path: pathName,
          number_index: indexNumber,
          parent_category_id: subCategoryId,
          category_level: "4",
        };

        if (image?.size > 0) {
          const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;
          await Storage.put(keyUpload, image);
          payload.image_key = keyUpload;
        }

        if (!imagePreviewRoof) {
          payload.image_pre_roof_key = "none";
        }
        if (imagePreviewRoof?.size > 0) {
          const keyUpload = `${new Date().getTime()}.${
            TypeFile[imagePreviewRoof.type]
          }`;
          await Storage.put(keyUpload, imagePreviewRoof);
          payload.image_pre_roof_key = keyUpload;
        }

        if (!imagePreviewRoofCover) {
          payload.image_pre_roof_cover_key = "none";
        }
        if (imagePreviewRoofCover?.size > 0) {
          const keyUpload = `${new Date().getTime()}.${
            TypeFile[imagePreviewRoofCover.type]
          }`;
          await Storage.put(keyUpload, imagePreviewRoofCover);
          payload.image_pre_roof_cover_key = keyUpload;
        }

        await AppApi.put(`categorys/${subCategoryLevel4Id}`, payload);

        clearForm();
        e.target.reset();

        alertSuccess("Update Success", "", () => navigate(-1));
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <>
      {isAddMode ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          <Container>
            <div className="flex flex-col gap-10">
              <div className="flex justify-between items-center">
                <div className="text-primary text-[48px]">เพิ่มประเภท</div>
                <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                  {parentCategoryTitle}
                </div>
              </div>

              <div className="bg-white pt-[32px] pb-[32px] pl-[60px] pr-[60px] rounded-2xl drop-shadow-lg">
                <form className="flex flex-col gap-6" onSubmit={onSubmitForm}>
                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      ชื่อประเภท
                    </div>
                    <InputMain
                      name="typename"
                      id="typename"
                      placeholder="กรอกประเภท"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2 font-semibold text-[20px] text-tnn_gray_200">
                      ชื่อประเภทแบบย่อ
                      <span className="text-red-700 text-sm font-normal">
                        (ภาษาอังกฤษ)
                      </span>
                    </div>
                    <InputMain
                      name="pathname"
                      id="pathname"
                      placeholder="กรอกชื่อประเภทภาษาอังกฤษแบบย่อ"
                      value={pathName}
                      onChange={(e) => setPathName(e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col gap-2 relative">
                    <div className="flex flex-col">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        รูปตัวอย่างสินค้า
                      </div>
                      <div className=" text-tnn_gray_200">
                        รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                        334px*334px
                      </div>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      name="image"
                      onChange={onChangeImage}
                      ref={inputImageRef}
                    />
                    {image ? (
                      <div className=" rounded-lg w-full ">
                        <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                          <img
                            className="w-full h-full object-contain"
                            src={
                              image?.size > 0
                                ? URL.createObjectURL(image)
                                : image
                            }
                            alt="image"
                          />
                          <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                            <HiPencil
                              size={16}
                              className=""
                              onClick={() => {
                                inputImageRef.current.click();
                              }}
                            />
                          </div>
                          <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                            <HiTrash
                              onClick={() => {
                                setImage(null);
                                inputImageRef.current.value = null;
                              }}
                              size={16}
                              className=""
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => inputImageRef.current.click()}
                        className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                            334px*334px
                          </p>
                        </div>
                      </div>
                    )}
                    <div id="errorImage" className="absolute mt-4 right-0">
                      {hasImage ? (
                        <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600">
                          *กรุณาใส่รูปภาพตัวอย่าง
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="font-semibold text-[20px] text-tnn_gray_200">
                      ลำดับ
                    </div>
                    <InputMain
                      type="number"
                      name="numberindex"
                      id="numberindex"
                      placeholder="1"
                      value={indexNumber}
                      onChange={(e) => setIndexNumber(e.target.value)}
                    />
                  </div>

                  <button
                    isProcessing={saving}
                    className="bg-primary text-white rounded-full p-2 w-full"
                    type="submit"
                    disabled={saving}
                  >
                    {saving ? "Saving..." : "บันทึก"}
                  </button>
                </form>
              </div>
            </div>
          </Container>
        </div>
      ) : isEditMode ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          {isThaiModern || isThaiClassic || isElegant ? (
            <Container>
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center">
                  <div className="text-primary text-[48px]">แก้ไขประเภท</div>
                  <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                    {parentCategoryTitle}
                  </div>
                </div>

                <div className="bg-white px-[60px] py-[32px] rounded-2xl drop-shadow-lg">
                  <form className="flex flex-col gap-6" onSubmit={onSubmitForm}>
                    <div className="flex flex-col gap-1">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        ชื่อประเภท
                      </div>
                      <InputMain
                        name="typename"
                        id="typename"
                        placeholder="กรอกประเภท"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="flex items-center gap-2 font-semibold text-[20px] text-tnn_gray_200">
                        ชื่อประเภทแบบย่อ
                        <span className="text-red-700 text-sm font-normal">
                          (ภาษาอังกฤษ)
                        </span>
                      </div>
                      <InputMain
                        name="pathname"
                        id="pathname"
                        placeholder="กรอกชื่อประเภทภาษาอังกฤษแบบย่อ"
                        value={pathName}
                        onChange={(e) => setPathName(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-2 relative">
                      <div className="flex flex-col">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          รูปตัวอย่างสินค้า
                        </div>
                        <div className=" text-tnn_gray_200">
                          รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                          334px*334px
                        </div>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        name="image"
                        onChange={onChangeImage}
                        ref={inputImageRef}
                      />
                      {image ? (
                        <div className=" rounded-lg w-full ">
                          <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                            <img
                              className="w-full h-full object-contain"
                              src={
                                image?.size > 0
                                  ? URL.createObjectURL(image)
                                  : image
                              }
                              alt="image"
                            />
                            <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiPencil
                                size={16}
                                className=""
                                onClick={() => {
                                  inputImageRef.current.click();
                                }}
                              />
                            </div>
                            <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiTrash
                                onClick={() => {
                                  setImage(null);
                                  inputImageRef.current.value = null;
                                }}
                                size={16}
                                className=""
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => inputImageRef.current.click()}
                          className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1
                              / 334px*334px
                            </p>
                          </div>
                        </div>
                      )}
                      <div id="errorImage" className="absolute mt-4 right-0">
                        {hasImage ? (
                          <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600">
                            *กรุณาใส่รูปภาพตัวอย่าง
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 relative">
                      <div className="flex flex-col">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          รูปพรีวิวกระเบื้องหลังคา
                        </div>
                        <div className=" text-tnn_gray_200">
                          รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน 1004px*312px
                        </div>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        name="image preview roof"
                        onChange={onChangeImagePreviewRoof}
                        ref={inputImagePreviewRoofRef}
                      />
                      {imagePreviewRoof ? (
                        <div className=" rounded-lg w-full ">
                          <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                            <img
                              className="w-full h-full object-contain"
                              src={
                                imagePreviewRoof?.size > 0
                                  ? URL.createObjectURL(imagePreviewRoof)
                                  : imagePreviewRoof
                              }
                              alt="image preview roof"
                            />
                            <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiPencil
                                size={16}
                                className=""
                                onClick={() => {
                                  inputImagePreviewRoofRef.current.click();
                                }}
                              />
                            </div>
                            <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiTrash
                                onClick={() => {
                                  setImagePreviewRoof(null);
                                  inputImagePreviewRoofRef.current.value = null;
                                }}
                                size={16}
                                className=""
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            inputImagePreviewRoofRef.current.click()
                          }
                          className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน
                              1004px*312px
                            </p>
                          </div>
                        </div>
                      )}
                      {/* <div id="errorImage" className="absolute mt-4 right-0">
                        {hasImagePreviewRoof ? (
                          <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600">
                            *กรุณาใส่รูปภาพตัวอย่าง
                          </div>
                        ) : (
                          ""
                        )}
                      </div> */}
                    </div>

                    <div className="flex flex-col gap-2 relative">
                      <div className="flex flex-col">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          รูปพรีวิวครอบ
                        </div>
                        <div className=" text-tnn_gray_200">
                          รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน 1004px*312px
                        </div>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        name="image preview roof cover"
                        onChange={onChangeImagePreviewRoofCover}
                        ref={inputImagePreviewRoofCoverRef}
                      />
                      {imagePreviewRoofCover ? (
                        <div className=" rounded-lg w-full ">
                          <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                            <img
                              className="w-full h-full object-contain"
                              src={
                                imagePreviewRoofCover?.size > 0
                                  ? URL.createObjectURL(imagePreviewRoofCover)
                                  : imagePreviewRoofCover
                              }
                              alt="image previee roof cover"
                            />
                            <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiPencil
                                size={16}
                                className=""
                                onClick={() => {
                                  inputImagePreviewRoofCoverRef.current.click();
                                }}
                              />
                            </div>
                            <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiTrash
                                onClick={() => {
                                  setImagePreviewRoofCover(null);
                                  inputImagePreviewRoofCoverRef.current.value =
                                    null;
                                }}
                                size={16}
                                className=""
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            inputImagePreviewRoofCoverRef.current.click()
                          }
                          className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน
                              1004px*312px
                            </p>
                          </div>
                        </div>
                      )}
                      {/* <div id="errorImage" className="absolute mt-4 right-0">
                        {hasImagePreviewRoofCover ? (
                          <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600">
                            *กรุณาใส่รูปภาพตัวอย่าง
                          </div>
                        ) : (
                          ""
                        )}
                      </div> */}
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        ลำดับ
                      </div>
                      <InputMain
                        type="number"
                        name="numberindex"
                        id="numberindex"
                        placeholder="1"
                        value={indexNumber}
                        onChange={(e) => setIndexNumber(e.target.value)}
                      />
                    </div>

                    <button
                      isProcessing={saving}
                      className="bg-primary text-white rounded-full p-2 w-full"
                      type="submit"
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "บันทึก"}
                    </button>
                  </form>
                </div>
              </div>
            </Container>
          ) : (
            <Container>
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center">
                  <div className="text-primary text-[48px]">แก้ไขประเภท</div>
                  <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                    {parentCategoryTitle}
                  </div>
                </div>

                <div className="bg-white px-[60px] py-[32px] rounded-2xl drop-shadow-lg">
                  <form className="flex flex-col gap-6" onSubmit={onSubmitForm}>
                    <div className="flex flex-col gap-1">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        ชื่อประเภท
                      </div>
                      <InputMain
                        name="typename"
                        id="typename"
                        placeholder="กรอกประเภท"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="flex items-center gap-2 font-semibold text-[20px] text-tnn_gray_200">
                        ชื่อประเภทแบบย่อ
                        <span className="text-red-700 text-sm font-normal">
                          (ภาษาอังกฤษ)
                        </span>
                      </div>
                      <InputMain
                        name="pathname"
                        id="pathname"
                        placeholder="กรอกชื่อประเภทภาษาอังกฤษแบบย่อ"
                        value={pathName}
                        onChange={(e) => setPathName(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col gap-2 relative">
                      <div className="flex flex-col">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          รูปตัวอย่างสินค้า
                        </div>
                        <div className=" text-tnn_gray_200">
                          รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                          334px*334px
                        </div>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        name="image"
                        onChange={onChangeImage}
                        ref={inputImageRef}
                      />
                      {image ? (
                        <div className=" rounded-lg w-full ">
                          <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                            <img
                              className="w-full h-full object-contain"
                              src={
                                image?.size > 0
                                  ? URL.createObjectURL(image)
                                  : image
                              }
                              alt="image"
                            />
                            <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiPencil
                                size={16}
                                className=""
                                onClick={() => {
                                  inputImageRef.current.click();
                                }}
                              />
                            </div>
                            <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                              <HiTrash
                                onClick={() => {
                                  setImage(null);
                                  inputImageRef.current.value = null;
                                }}
                                size={16}
                                className=""
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => inputImageRef.current.click()}
                          className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1
                              / 334px*334px
                            </p>
                          </div>
                        </div>
                      )}
                      <div id="errorImage" className="absolute mt-4 right-0">
                        {hasImage ? (
                          <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600">
                            *กรุณาใส่รูปภาพตัวอย่าง
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="font-semibold text-[20px] text-tnn_gray_200">
                        ลำดับ
                      </div>
                      <InputMain
                        type="number"
                        name="numberindex"
                        id="numberindex"
                        placeholder="1"
                        value={indexNumber}
                        onChange={(e) => setIndexNumber(e.target.value)}
                      />
                    </div>

                    <button
                      isProcessing={saving}
                      className="bg-primary text-white rounded-full p-2 w-full"
                      type="submit"
                      disabled={saving}
                    >
                      {saving ? "Saving..." : "บันทึก"}
                    </button>
                  </form>
                </div>
              </div>
            </Container>
          )}
        </div>
      ) : (
        <Container>
          <div>Page Not Found.</div>
        </Container>
      )}
    </>
  );
};

export default FormSubCategoryLevel4;
