import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import React from "react";

export const PopupMain = ({
  open,
  handleOpen,
  onClick,
  statusQuotation = "",
}) => {
  return (
    <Dialog
      size="sm"
      open={open}
      handler={handleOpen}
      className="p-5 rounded-[20px] relative"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className=" absolute right-[20px] cursor-pointer"
        onClick={handleOpen}
      >
        <path
          d="M15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5Z"
          stroke="#4A4A4A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.4624 18.5375L18.5374 11.4625"
          stroke="#4A4A4A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.5374 18.5375L11.4624 11.4625"
          stroke="#4A4A4A"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <DialogHeader className="text-primary text-4xl flex justify-center">
        <div>ยืนยันตรวจสอบสินค้า</div>
      </DialogHeader>
      <DialogBody className="bg-[#f8f8f8] rounded-[10px] py-10 text-base text-primary_gray flex justify-center items-center gap-2">
        <div>เมื่อยืนยันแล้วระบบจะเปลี่ยนสถานะเป็น</div>
        {statusQuotation === "confirm" ? (
          <div className="bg-primary_green rounded-full px-2.5 py-1.5 text-white text-xs text-center ">
            ส่งงานสำเร็จ
          </div>
        ) : (
          <div className="bg-primary_yellow rounded-full px-2.5 py-1.5 text-white text-xs text-center ">
            กำลังตรวจสอบ
          </div>
        )}
      </DialogBody>
      <div className="flex justify-center mt-5 gap-2.5">
        <button
          onClick={handleOpen}
          className="bg-white border-2 border-primary_gray rounded-full w-full h-[45px] text-primary_gray text-base"
        >
          <span>ยกเลิก</span>
        </button>
        <button
          onClick={onClick}
          className="bg-primary border-2 border-primary rounded-full w-full h-[45px] text-white text-base hover:text-primary hover:bg-white duration-300"
        >
          <span>ยืนยัน</span>
        </button>
      </div>
    </Dialog>
  );
};

export default PopupMain;
