import React, { useEffect, useState } from "react";
import { AppApi } from "../../api";
import useSweetAlert from "../../_hooks/useSweetAlert";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container";

const User = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState("");

  const [editIndexName, setEditIndexName] = useState(null);
  const [editNewName, setEditNewName] = useState("");
  const [editIndexPassword, setEditIndexPassword] = useState(null);
  const [editNewPassword, setEditNewPassword] = useState("");
  const [editIndexRole, setEditIndexRole] = useState(null);
  const [editNewRole, setEditNewRole] = useState("");

  const navigate = useNavigate();
  const { alertConfirm, alertSuccess } = useSweetAlert();

  const loadUsers = async () => {
    try {
      setLoading(true);
      const { data } = await AppApi.get("users");
      setUsers(data?.users);
    } catch (error) {
      console.log("ERROR TO LOAD USERS", error);
    } finally {
      setLoading(false);
    }
  };

  const editUserName = async (id) => {
    try {
      setLoading(true);
      await AppApi.put(`users/${id}`, {
        username: editNewName,
      });
    } catch (error) {
      console.log("ERROR TO EDIT USERNAME", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const editPassword = async (id) => {
    try {
      setLoading(true);
      await AppApi.put(`users/${id}`, {
        password: editNewPassword,
      });
    } catch (error) {
      console.log("ERROR TO EDIT PASSWORD", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const editRole = async (id) => {
    try {
      setLoading(true);
      await AppApi.put(`users/${id}`, {
        role: editNewRole,
      });
    } catch (error) {
      console.log("ERROR TO EDIT ROLE", error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const DropdownSelect = () => {
    const handleSelectChange = (e) => {
      setEditNewRole(e.target.value);
    };

    return (
      <select
        value={editNewRole}
        onChange={handleSelectChange}
        className="p-2 border-2 border-tnn_gray_200 rounded-md"
      >
        <option value="">Select a Role</option>
        <option value="admin">admin</option>
        <option value="user">user</option>
      </select>
    );
  };

  const clickDelete = async (id) => {
    alertConfirm("ยืนยันการลบ ?", "", async () => {
      try {
        await AppApi.delete(`users/${id}`);
        alertSuccess("Delete User Success", "", () => window.location.reload());
      } catch (error) {
        console.log("ERROR TO DELETE USER", error);
      }
    });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleSortClick1 = () => {
    setUsers((prev) => {
      let temp = [...prev];
      temp = temp.sort((a, b) => {
        const dateA = new Date(a.date_created);
        const dateB = new Date(b.date_created);
        return dateB - dateA;
      });
      return temp;
    });
  };

  const handleSortClick2 = () => {
    setUsers((prev) => {
      let temp = [...prev];
      temp = temp.sort((a, b) => {
        const dateA = new Date(a.date_created);
        const dateB = new Date(b.date_created);
        return dateA - dateB;
      });
      return temp;
    });
  };

  return (
    <Container>
      <div className="flex justify-end">
        <button
          className="border-2 border-primary bg-primary rounded-xl px-5 py-2 text-white hover:bg-white hover:text-primary duration-300"
          onClick={() => navigate("/user/add")}
        >
          <div>Create User</div>
        </button>
      </div>

      <div className="flex gap-4 my-4 text-sm">
        <button
          className="flex items-center border-2 rounded-xl px-5 py-1.5"
          onClick={handleSortClick1}
        >
          <div>ใหม่ ไป เก่า</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
            />
          </svg>
        </button>

        <button
          className="flex items-center border-2 rounded-xl px-5 py-1.5 "
          onClick={handleSortClick2}
        >
          <div>เก่า ไป ใหม่</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
            />
          </svg>
        </button>
      </div>

      <div className="overflow-auto lg:overflow-visible ">
        <div className="grid grid-cols-5 bg-white rounded-xl w-full h-auto mb-6 my-4 text-lg font-bold">
          <div>Username</div>
          <div>Password</div>
          <div>Role</div>
          <div>Created Date</div>
          <div>Delete User</div>
        </div>

        {users?.map((user, index) => {
          return (
            <div className="grid grid-cols-5 bg-white duration-300 hover:shadow-lg rounded-xl border-2 w-full h-auto p-4 items-center my-4 text-sm">
              <div className="flex items-center">
                <div className="">
                  {editIndexName === index ? (
                    <input
                      className="w-20 p-2 border-2 border-tnn_gray_200 rounded-md"
                      type="text"
                      value={editNewName}
                      onChange={(e) => {
                        setEditNewName(e.target.value);
                      }}
                    />
                  ) : (
                    user?.username
                  )}
                </div>
                <div className=" mx-auto">
                  {editIndexName === index ? (
                    <div className="flex flex-col gap-2">
                      <div
                        className="cursor-pointer hover:text-red-700 hover:border-2 rounded-xl hover:border-red-700 duration-300 p-2 "
                        onClick={() => setEditIndexName(null)}
                      >
                        cancel
                      </div>
                      <div
                        className="cursor-pointer hover:text-green-600 hover:border-2 rounded-xl hover:border-green-600 duration-300 p-2 "
                        onClick={() => {
                          editUserName(user?._id);
                          setEditIndexName(null);
                        }}
                      >
                        save
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer hover:text-primary duration-300"
                      onClick={() => {
                        if (editIndexName === null) {
                          setEditIndexName(index);
                          setEditNewName(user?.username);
                        } else {
                          setEditIndexName(null);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center">
                <div>
                  {editIndexPassword === index ? (
                    <input
                      className="w-20 p-2 border-2 border-tnn_gray_200 rounded-md"
                      type="text"
                      value={editNewPassword}
                      onChange={(e) => {
                        setEditNewPassword(e.target.value);
                      }}
                    />
                  ) : (
                    user?.password
                  )}
                </div>
                <div className=" mx-auto">
                  {editIndexPassword === index ? (
                    <div className="flex flex-col gap-2">
                      <div
                        className="cursor-pointer hover:text-red-700 hover:border-2 rounded-xl hover:border-red-700 p-2 "
                        onClick={() => setEditIndexPassword(null)}
                      >
                        cancel
                      </div>
                      <div
                        className="cursor-pointer hover:text-green-600 hover:border-2 rounded-xl hover:border-green-600 p-2 "
                        onClick={() => {
                          editPassword(user?._id);
                          setEditIndexPassword(null);
                        }}
                      >
                        save
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer hover:text-primary duration-300"
                      onClick={() => {
                        if (editIndexPassword === null) {
                          setEditIndexPassword(index);
                          setEditNewPassword(user?.password);
                        } else {
                          setEditIndexPassword(null);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center">
                {editIndexRole === index ? <DropdownSelect /> : user?.role}
                <div className=" mx-auto">
                  {editIndexRole === index ? (
                    <div>
                      <div
                        className="cursor-pointer hover:text-red-700 hover:border-2 rounded-xl hover:border-red-700 p-2 "
                        onClick={() => setEditIndexRole(null)}
                      >
                        cancel
                      </div>
                      <div
                        className="cursor-pointer hover:text-green-600 hover:border-2 rounded-xl hover:border-green-600 p-2 "
                        onClick={() => {
                          editRole(user?._id);
                          setEditIndexRole(null);
                        }}
                      >
                        save
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer hover:text-primary duration-300"
                      onClick={() => {
                        if (editIndexRole === null) {
                          setEditIndexRole(index);
                          setEditNewRole(user?.role);
                        } else {
                          setEditIndexRole(null);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="w-6 h-6"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                        <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center">
                {new Date(user?.date_created).toLocaleDateString("th-TH")}
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                className="cursor-pointer hover:text-red-700 duration-300 w-10 h-10"
                onClick={() => clickDelete(user?._id)}
              >
                <path
                  fill-rule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default User;
