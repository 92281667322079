import { Spinner } from "@material-tailwind/react";

export const ButtonBlue = ({
  isLoading,
  type,
  //   className = "w-full px-5 py-2.5 text-lg font-medium text-center text-white bg-primary duration-500 hover:shadow-blue-gray-200 hover:shadow-sm-light rounded-full ",
  title,
  onClick,
  icon,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      // className={className}
      className="rounded-full relative inline-flex group items-center justify-center px-5 py-2.5 text-base font-medium text-center cursor-pointer bg-primary text-white w-full h-fit gap-1"
    >
      <span class=" absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-full group-hover:h-full  opacity-10 "></span>
      {icon}
      <span class="relative">{title}</span>
      {isLoading ? <Spinner color="red" className="w-4 h-4" /> : ""}
    </button>
  );
};
