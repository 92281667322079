import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FieldWysiwyg from "../../components/Wysiwyg";
import { Storage } from "aws-amplify";
import { TypeFile, s3File } from "../../utils/fileFormater";
import { AppApi } from "../../api";
import { HiPencil, HiTrash } from "react-icons/hi2";
import useSweetAlert from "../../_hooks/useSweetAlert";
import Container from "../../components/Container";
import { STYLE_CARD_PARENT, STYLE_CARD_PARENT_ADD } from "../../utils/useStyle";
import { InputMain, InputMainTextArea } from "../../components/Input/inputmain";
import { ButtonBlue } from "../../components/Button/buttonmain";
import { useUserStore } from "../../_store/userStore";
import { IMAGE_TYPE_LIST } from "../../utils/constants";
import { errorScroll } from "../../utils/functions";

const FormBlog = () => {
  const params = useParams();

  const user = useUserStore((state) => state.user);

  const blogId = params.id;

  const navigate = useNavigate();

  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();

  const [saving, setSaving] = useState(false);

  const isCreate = blogId === "add";
  const isEdit = blogId.length === 24;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [indexNumber, setIndexNumber] = useState("1");

  const inputImageRef = useRef();

  const [hasImage, setHasImage] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore && isEdit) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(`blogs/${blogId}`);
          const blog = data.blog;
          setTitle(blog.title);
          setDescription(blog.description);
          setImage(s3File(blog.image_key));
          setContent(blog.content);
          setIndexNumber(blog.number_index);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [blogId]);

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setContent("");
    setImage(null);
    setIndexNumber("");
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!image) {
      setHasImage(true);
      errorScroll("errorImage");
      return;
    }

    setSaving(true);

    if (isCreate) {
      try {
        const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;

        await Storage.put(keyUpload, image);

        const payload = {
          name_creator: user?.username,
          title,
          description,
          image_key: keyUpload,
          number_index: indexNumber,
        };

        if (content) {
          payload.content = content;
        }

        await AppApi.post(`blogs`, payload);

        clearForm();
        e.target.reset();

        alertSuccess("Create Success", "กลับสู่หน้า ข่าวสาร", () =>
          navigate("/blog")
        );
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    } else {
      try {
        let payload = {
          title,
          description,
          number_index: indexNumber,
        };

        if (image?.size > 0) {
          const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;

          await Storage.put(keyUpload, image);

          payload.image_key = keyUpload;
        }

        if (content) {
          payload.content = content;
        }

        await AppApi.put(`blogs/${blogId}`, payload);

        clearForm();
        e.target.reset();
        alertSuccess("Update Success", "", () => navigate(-1));
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    }
  };

  const onChangeImage = (e) => {
    if (!e.target.value) return;

    const type = e.target.files[0].type;

    if (!IMAGE_TYPE_LIST.includes(type)) {
      alertError("รองรับไฟล์ PNG หรือ JPEG");
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;
        // console.log(`width: `, width);
        // console.log(`height: `, height);

        if (width <= 334 && height <= 334) {
          // Valid image file
          // Perform further actions here
          setHasImage(false);
          setImage(file);
        } else {
          // Invalid image file

          alertError("รองรับไฟล์ขนาดไม่เกิน 334x334");
        }
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  if (!isCreate && !isEdit) {
    return <div>Not Found</div>;
  }

  return (
    <div className="bg-[#F9F9F9] w-full h-full">
      <Container>
        <div className="text-primary text-5xl font-light mb-8">
          {isCreate ? "เพิ่มข่าวสาร" : `แก้ไขข่าวสาร`}
        </div>

        <div className={STYLE_CARD_PARENT_ADD}>
          <form onSubmit={onSubmitForm}>
            <div className=" flex flex-col gap-6">
              <div className="flex flex-col gap-1 font-normal relative">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  รูปภาพตัวอย่าง
                </div>
                <div className=" text-tnn_gray_200">
                  ขนาดภาพแนะนำ Ratio 1:1 / 334*334px
                </div>
                <input
                  type="file"
                  className="hidden absolute"
                  name="image"
                  onChange={onChangeImage}
                  ref={inputImageRef}
                />
                {image ? (
                  <div className=" rounded-lg w-full ">
                    <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3 relative">
                      <img
                        className="w-full h-full object-contain"
                        src={
                          image?.size > 0 ? URL.createObjectURL(image) : image
                        }
                        alt="image"
                      />
                      <div className="absolute -top-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                        <HiPencil
                          size={16}
                          className=""
                          onClick={() => {
                            inputImageRef.current.click();
                          }}
                        />
                      </div>
                      <div className="absolute -bottom-3 -right-3 p-2 rounded-full bg-white text-gray-700 shadow hover:text-primary cursor-pointer">
                        <HiTrash
                          onClick={() => {
                            setImage(null);
                            inputImageRef.current.value = null;
                          }}
                          size={16}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => inputImageRef.current.click()}
                    className="flex flex-col items-center justify-center w-full h-60 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        PNG or JPEG (MAX. 334 x 334)
                      </p>
                    </div>
                  </div>
                )}
                <div id="errorImage" className="absolute mt-4 right-0">
                  {hasImage ? (
                    <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                      *กรุณาใส่รูปภาพตัวอย่าง
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-1 font-normal">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  หัวข้อ
                </div>
                <InputMain
                  placeholder="กรอกชื่อหัวข้อ..."
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-1 font-normal">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  คำอธิบาย
                </div>
                <InputMainTextArea
                  placeholder="กรอกคำอธิบาย..."
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-1 font-normal">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  รายละเอียดเนื้อหา
                </div>
                <FieldWysiwyg value={content} setValue={setContent} />
              </div>

              <div className="flex flex-col gap-1 font-normal">
                <div className="font-semibold text-[20px] text-tnn_gray_200">
                  ลำดับ
                </div>
                <InputMain
                  type="number"
                  placeholder="1"
                  required
                  value={indexNumber}
                  onChange={(e) => setIndexNumber(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-end mt-5">
              <button
                isProcessing={saving}
                disabled={saving}
                className="w-full"
              >
                <ButtonBlue
                  type="submit"
                  title={saving ? "Saving..." : isCreate ? "สร้าง" : "บันทึก"}
                />
              </button>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default FormBlog;
