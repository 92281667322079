import { useCallback, useEffect, useState } from "react";
import Container from "../../components/Container";
import { AppApi } from "../../api";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import React from "react";
import QuotationCartList from "./QuotationCartList";

// Get the current date
const currDate = new Date();

// Extract the month (0-indexed, so January is 0)
const currentMonth = currDate.getMonth() + 1; // Adding 1 to match human-readable month numbers

const OrderManagement = () => {
  const navigate = useNavigate();

  const screenWidth = window.innerWidth;
  // console.log("Screen width:", screenWidth);

  const [loading, setLoading] = useState(false);

  const MONTH_LIST = [
    { value: "00", label: "ทั้งหมด" },
    { value: "01", label: "มกราคม" },
    { value: "02", label: "กุมภาพันธ์" },
    { value: "03", label: "มีนาคม" },
    { value: "04", label: "เมษายน" },
    { value: "05", label: "พฤษภาคม" },
    { value: "06", label: "มิถุนายน" },
    { value: "07", label: "กรกฎาคม" },
    { value: "08", label: "สิงหาคม" },
    { value: "09", label: "กันยายน" },
    { value: "10", label: "ตุลาคม" },
    { value: "11", label: "พฤศจิกายน" },
    { value: "12", label: "ธันวาคม" },
  ];

  const PEODUCT_CATEGORY_LIST = [
    { value: "all", label: "ทั้งหมด" },
    { value: "64d3230f92e632cfcf7be58c", label: "ปูนซีเมนต์, อิฐมวลเบา" },
    { value: "64d3251c92e632cfcf7be5a0", label: "ผลิตภัณฑ์คอนกรีต" },
    { value: "64d3254392e632cfcf7be5a4", label: "ถังเก็บน้ำ, ถังบำบัด" },
    { value: "64d3255f92e632cfcf7be5aa", label: "ปั้มน้ำ" },
    { value: "64d3256e92e632cfcf7be5ae", label: "ผลิตภัณฑ์ตราเพชร" },
    { value: "652cc2fee6867d835d1b4046", label: "ตราเพชร (บอร์ด)" },
    { value: "652cc3e0f9d95317b7642cad", label: "ตราเพชร (ไม้สังเคราะห์)" },
    { value: "64d3257492e632cfcf7be5b2", label: "อื่น ๆ" },
  ];

  const STATUS_LIST = [
    { value: "all", label: "ทั้งหมด" },
    { value: "not-confirm", label: "ยังไม่ได้ตรวจสอบ" },
    { value: "inspecting", label: "กำลังตรวจสอบ" },
    { value: "confirm", label: "ส่งงานสำเร็จ" },
  ];

  const [textSearch, setTextSearch] = useState("");
  const [month, setMonth] = useState(MONTH_LIST[0].value);
  const [productCategory, setProductCategory] = useState("all");
  const [statusQuotation, setStatusQuotation] = useState("all");

  const [quotations, setQuotations] = useState([]);
  // console.log("quotations : ", quotations);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      load();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const load = async (
    search = "",
    month = MONTH_LIST[0].value,
    statusQuotation = "all",
    productCategory = "all"
  ) => {
    try {
      setLoading(true);
      const { data } = await AppApi.get(
        `quotations?search=${search}&month=${month}&statusQuotation=${statusQuotation}&productCategory=${productCategory}`
      );
      setQuotations(data.quotations);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadDelay = useCallback(_.debounce(load, 500), [setTextSearch]);

  const onChangeSearch = async (e) => {
    setTextSearch(e.target.value);
    await loadDelay(e.target.value, month, statusQuotation, productCategory);
  };

  const handleMonthChange = async (e) => {
    setMonth(e.target.value);
    await load(textSearch, e.target.value, statusQuotation, productCategory);
  };

  const handleStatusQuotationChange = async (e) => {
    setStatusQuotation(e.target.value);
    await load(textSearch, month, e.target.value, productCategory);
  };

  const handleProductCategoryChange = async (e) => {
    setProductCategory(e.target.value);
    await load(textSearch, month, statusQuotation, e.target.value);
  };

  console.log("quotations : ", quotations)

  return (
    <div className="bg-[#f4f4f4] w-full h-full">
      <Container>
        <div className="flex justify-between text-primary items-center">
          <div className="text-[48px] font-light">Order Management</div>
          <div className="text-[28px] font-bold">
            จำนวนใบเสนอราคา {quotations.length}
          </div>
        </div>

        {/*---------- Filter Search ----------*/}
        <div className="grid grid-cols-2 gap-4 items-end mb-6">
          <div className=" relative flex items-center justify-end">
            <input
              type="text"
              placeholder="ค้นหาสินค้า, ชื่อลูกค้า, ..."
              className="rounded-xl w-full h-full p-2 border-2"
              value={textSearch}
              onChange={onChangeSearch}
            />
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=" absolute mr-4 cursor-pointer"
            >
              <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="#31338E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 22L20 20"
                stroke="#31338E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="grid grid-cols-3 gap-4 items-end">
            <div className="flex flex-col">
              <div className="text-primary_gray text-[14px]">
                เลือกเดือนเพื่อดูยอดสั่งซื้อ
              </div>
              <select
                id="selectMonth"
                className="bg-[#f9f9f9] p-2 rounded-xl border-2"
                value={month}
                onChange={handleMonthChange}
              >
                {MONTH_LIST?.map((month, index) => {
                  return (
                    <option key={index} value={month.value}>
                      {month.label}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex flex-col">
              <div className="text-primary_gray text-[14px]">
                เลือกประเภทสินค้า
              </div>
              <select
                id="selectProductCategory"
                className="bg-[#f9f9f9] p-2 rounded-xl border-2"
                value={productCategory}
                onChange={handleProductCategoryChange}
              >
                {PEODUCT_CATEGORY_LIST?.map((category, index) => {
                  return (
                    <option key={index} value={category?.value}>
                      {category?.label}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex flex-col">
              <div className="text-primary_gray text-[14px]">สถานะ</div>
              <select
                id="selectProductCategory"
                className="bg-[#f9f9f9] p-2 rounded-xl border-2"
                value={statusQuotation}
                onChange={handleStatusQuotationChange}
              >
                {STATUS_LIST?.map((statusQuotation, index) => {
                  return (
                    <option key={index} value={statusQuotation.value}>
                      {statusQuotation.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-2xl p-6 shadow-xl">
          <div className="bg-primary rounded-2xl p-6 shadow-xl text-white text-base">
            <div className="grid grid-cols-8 gap-5">
              <div>ชื่อ - นามสกุล</div>
              <div>เบอร์โทร</div>
              <div className=" col-span-2">
                <div className="flex justify-between">
                  <div>สินค้า</div>
                  <div>จำนวน</div>
                </div>
              </div>
              <div>วันเวลาที่ใช้</div>
              <div>สถานที่จัดส่ง</div>
              <div>หมายเหตุ</div>
              <div>สถานะ</div>
            </div>
          </div>

          {loading ? (
            <div className="text-center pt-20 pb-10">Loading . . .</div>
          ) : quotations.length === 0 ? (
            <div className="text-center pt-20 pb-10">ไม่พบ Order</div>
          ) : (
            <>
              {quotations?.map((quotation, index) => {
                return (
                  <div
                    key={index}
                    className="grid grid-cols-8 items-start border-b-2 border-tnn_gray_50 pb-6 pl-3 pr-3 mt-8 gap-5 
                    text-sm rounded-xl cursor-pointer hover:shadow-xl duration-300 text-primary"
                    onClick={() => navigate(`/quotationView/${quotation._id}`)}
                  >
                    <div>
                      {quotation.first_name} {quotation.last_name}
                    </div>
                    <div>{quotation.phone_number}</div>

                    <div className=" col-span-2">
                      <QuotationCartList quotation={quotation} />
                    </div>

                    <div>{quotation.date_of_use}</div>
                    <div>{quotation.address}</div>
                    <div>{quotation.remark}</div>

                    {/*---------- สถานะใบเสนอราคา ----------*/}
                    <div className="flex">
                      {quotation.quotation_status === "not-confirm" ? (
                        <div className="bg-primary_red rounded-full px-[10px] py-[6px] text-white text-xs text-center ">
                          ยังไม่ได้ตรวจสอบ
                        </div>
                      ) : quotation.quotation_status === "inspecting" ? (
                        <div className="bg-primary_yellow rounded-full px-[10px] py-[6px] text-white text-xs text-center ">
                          กำลังตรวจสอบ
                        </div>
                      ) : (
                        <div className="bg-primary_green rounded-full px-[10px] py-[6px] text-white text-xs text-center ">
                          ส่งงานสำเร็จ
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default OrderManagement;
