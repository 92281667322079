import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Spinner,
} from "@material-tailwind/react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { AppApi } from "../api";
import classNames from "classnames";
import {
  STYLE_ACCORDION_BODY,
  STYLE_ACCORDION_BODY_LIST,
  STYLE_ACCORDION_HEADER,
  STYLE_ACCORDION_HEADER_ACTIVE,
  STYLE_ACCORDION_HEADER_INACTIVE,
  STYLE_ACCORDION_HEADER_TYPO,
  STYLE_ACCORDION_HEADER_TYPO_ACTIVE,
  STYLE_ACCORDION_HEADER_TYPO_INACTIVE,
  STYLE_ICON_LIST_ITEMPREFIX,
  STYLE_LIST_ITEM,
  STYLE_LIST_ITEMPREFIX,
  STYLE_LIST_ITEM_ACCORDION,
  STYLE_LIST_ITEM_ACCORDIONBODY,
  STYLE_TYPOGRAPHY,
} from "../utils/useStyle";
import { SideBarContext } from "../context/sideBarContext";
import { CEMENT_CATEGORY_ID } from "../utils/constants";

const formatCategory = (data) => {
  const result = data.filter((category) => category?.category_level === "1");

  let finalResult = [];

  result.forEach((category) => {
    const result2 = data?.filter(
      (el) => category?._id === el?.parent_category_id
    );

    let sub = [];

    result2.forEach((category) => {
      sub.push({
        ...category,
        name: category?.category_name,
      });
    });

    if (category?._id === CEMENT_CATEGORY_ID) {
      finalResult.push({
        ...category,
        name: category?.category_name,
        sub: sub?.sort((a, b) => {
          if (
            a.group_category_name === "cement" &&
            b.group_category_name !== "cement"
          ) {
            return -1;
          } else if (
            a.group_category_name !== "cement" &&
            b.group_category_name === "cement"
          ) {
            return 1;
          } else {
            if (a.number_index < b.number_index) {
              return -1;
            }
            if (a.number_index > b.number_index) {
              return 1;
            }
          }
        }),
      });
    } else {
      finalResult.push({
        ...category,
        name: category?.category_name,
        sub: sub.sort((a, b) => a?.number_index - b?.number_index),
      });
    }
  });
  return finalResult;
};

const findCategory = (categoryId = "", categorys = []) => {
  const result = categorys.find((category) => {
    const subIds = category?.sub?.map((a) => a?._id) || [];
    return subIds?.includes(categoryId);
  });
  return result;
};

export default function Sidebar() {
  const { categoryId } = useParams();
  const { isRefetch } = useContext(SideBarContext);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const [categorys, setCategorys] = useState([]);

  const [open, setOpen] = React.useState(0);
  const [openL2, setOpenL2] = React.useState(0);
  const [activeL3, setActiveL3] = React.useState(null);
  const [openBanner, setOpenBanner] = React.useState(0);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      if (categoryId) {
        const currentCategory = findCategory(categoryId, categorys);

        if (currentCategory?.sub?.length > 0) {
          const subIds = currentCategory?.sub?.map((a) => a?._id) || [];

          const thisIndex = subIds?.findIndex((subId) => subId === categoryId);
          setActiveL3(thisIndex);
        } else {
          setActiveL3(null);
        }
      }
    }
    return () => {
      ignore = true;
    };
  }, [categoryId]);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleOpenL2 = (value) => {
    setOpenL2(openL2 === value ? 0 : value);
  };

  const handleBannerOpen = (value) => {
    setOpenBanner(openBanner === value ? 0 : value);
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(
            `categorys?category_level_custom=1-2&limit=500`
          );

          const result = formatCategory(data?.categorys);

          setCategorys(result);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, [isRefetch]);

  return (
    <div className=" flex flex-col space-y-10 w-[198px] fixed bg-white h-full pb-10 z-20 overflow-y-auto">
      <div className="flex justify-center mt-5">
        <Typography>
          <img
            src="/Logo-sidebar.png"
            alt="logo"
            className="w-32"
            onClick={() => navigate("/")}
          />
        </Typography>
      </div>

      <List className=" flex flex-col gap-10 pl-2.5 text-[14px]">
        <ListItem
          className={STYLE_LIST_ITEM}
          onClick={() => {
            navigate("/overview");
          }}
        >
          <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
            <svg
              className={STYLE_ICON_LIST_ITEMPREFIX}
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.25 6.5C15.4926 6.5 16.5 5.49264 16.5 4.25C16.5 3.00736 15.4926 2 14.25 2C13.0074 2 12 3.00736 12 4.25C12 5.49264 13.0074 6.5 14.25 6.5Z"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.25 10.25H9"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.25 13.25H12"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V8"
                stroke="CurrentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ListItemPrefix>
          Overview
        </ListItem>

        {/* start */}
        <Accordion
          open={open === 1}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 1 ? "rotate-180" : ""
              }`}
            />
          }
        >
          <ListItem className={STYLE_LIST_ITEM} selected={open === 1}>
            <AccordionHeader
              onClick={() => {
                handleOpen(1);
              }}
              className={STYLE_ACCORDION_HEADER}
            >
              <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                <svg
                  className={STYLE_ICON_LIST_ITEMPREFIX}
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.37744 6.08008L8.99993 9.91257L15.5774 6.10255"
                    stroke="CurrentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 16.7075V9.90503"
                    stroke="CurrentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.44746 2.36L3.44247 4.58752C2.53497 5.09002 1.79248 6.35001 1.79248 7.38501V11.6225C1.79248 12.6575 2.53497 13.9175 3.44247 14.42L7.44746 16.6475C8.30246 17.12 9.70496 17.12 10.56 16.6475L14.565 14.42C15.4725 13.9175 16.215 12.6575 16.215 11.6225V7.38501C16.215 6.35001 15.4725 5.09002 14.565 4.58752L10.56 2.36C9.69746 1.88 8.30246 1.88 7.44746 2.36Z"
                    stroke="CurrentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.7498 10.43V7.68498L5.63232 3.57495"
                    stroke="CurrentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ListItemPrefix>
              <Typography
                className={classNames(STYLE_TYPOGRAPHY, "text-[14px]")}
              >
                Product
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className={STYLE_ACCORDION_BODY}>
            {isLoading ? (
              <div className="flex pl-20 py-5">
                <Spinner className="h-5 w-5" color="red" />
              </div>
            ) : (
              ""
            )}
            <List className={STYLE_ACCORDION_BODY_LIST}>
              {categorys
                ?.sort((a, b) => a.number_index - b.number_index)
                ?.map((category, index) => {
                  const isActive = openL2 === index + 1;

                  return (
                    <Accordion key={index} open={isActive}>
                      <ListItem
                        className={STYLE_LIST_ITEM_ACCORDION}
                        selected={isActive}
                      >
                        <AccordionHeader
                          onClick={() => {
                            if (category?.sub) {
                              handleOpenL2(index + 1);
                            }
                            navigate(`/category/${category?.path}`);
                          }}
                          className={`${
                            isActive
                              ? STYLE_ACCORDION_HEADER_ACTIVE
                              : STYLE_ACCORDION_HEADER_INACTIVE
                          }
                           hover:text-primary group`}
                        >
                          <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                            <ChevronRightIcon
                              strokeWidth={3}
                              className={`h-3 w-5 transition-transform ${
                                isActive ? "rotate-90" : ""
                              }`}
                            />
                          </ListItemPrefix>
                          <Typography
                            className={`${
                              isActive
                                ? STYLE_ACCORDION_HEADER_TYPO_ACTIVE
                                : STYLE_ACCORDION_HEADER_TYPO_INACTIVE
                            } ${STYLE_ACCORDION_HEADER_TYPO} text-[14px]`}
                          >
                            {category.category_name}
                          </Typography>
                        </AccordionHeader>
                      </ListItem>

                      <AccordionBody className="py-1">
                        <List className="p-0 w-fit">
                          {category?.sub?.map((sub, subIndex) => {
                            return (
                              <ListItem
                                key={subIndex}
                                className={classNames(
                                  `${STYLE_LIST_ITEM_ACCORDIONBODY}`,
                                  activeL3 === subIndex
                                    ? "text-primary font-medium"
                                    : "bg-transparent"
                                )}
                                onClick={() => {
                                  setActiveL3(subIndex);
                                  navigate(
                                    `/category/${category.path}/${sub?.path}?view`
                                  );
                                }}
                              >
                                <ListItemPrefix
                                  className={STYLE_LIST_ITEMPREFIX}
                                >
                                  <svg
                                    width="4"
                                    height="5"
                                    viewBox="0 0 4 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="2"
                                      cy="2.5"
                                      r="2"
                                      fill="CurrentColor"
                                    />
                                  </svg>
                                </ListItemPrefix>
                                {sub.category_name}
                              </ListItem>
                            );
                          })}
                        </List>
                      </AccordionBody>
                    </Accordion>
                  );
                })}
            </List>
          </AccordionBody>
        </Accordion>
        {/* end */}

        <Accordion
          open={openBanner === 1}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                openBanner === 1 ? "rotate-180" : ""
              }`}
            />
          }
        >
          <ListItem className={STYLE_LIST_ITEM} selected={open === 1}>
            <AccordionHeader
              onClick={() => {
                handleBannerOpen(1);
              }}
              className={STYLE_ACCORDION_HEADER}
            >
              <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                <svg
                  className={STYLE_ICON_LIST_ITEMPREFIX}
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.2602 13.22L13.9127 7.73747C13.1177 5.87747 11.6552 5.80247 10.6727 7.57247L9.25519 10.13C8.53519 11.4275 7.19269 11.54 6.26269 10.3775L6.09769 10.1675C5.13019 8.95247 3.76519 9.10247 3.06769 10.49L1.77769 13.0775C0.870188 14.8775 2.18269 17 4.19269 17H13.7627C15.7127 17 17.0252 15.0125 16.2602 13.22Z"
                    stroke="CurrentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.22754 6.5C6.47018 6.5 7.47754 5.49264 7.47754 4.25C7.47754 3.00736 6.47018 2 5.22754 2C3.9849 2 2.97754 3.00736 2.97754 4.25C2.97754 5.49264 3.9849 6.5 5.22754 6.5Z"
                    stroke="CurrentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </ListItemPrefix>
              <Typography
                className={classNames(STYLE_TYPOGRAPHY, "text-[14px]")}
              >
                Banner
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className={STYLE_ACCORDION_BODY}>
            <List className="p-0">
              <ListItem
                className={classNames(
                  STYLE_LIST_ITEM_ACCORDIONBODY,
                  "text-[14px]"
                )}
                onClick={() => {
                  navigate("/homeBanner");
                }}
              >
                <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                  <svg
                    className={STYLE_ICON_LIST_ITEMPREFIX}
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2" cy="2.5" r="2" fill="CurrentColor" />
                  </svg>
                </ListItemPrefix>
                Home
              </ListItem>

              <ListItem
                className={classNames(
                  STYLE_LIST_ITEM_ACCORDIONBODY,
                  "text-[14px]"
                )}
                onClick={() => {
                  navigate("/aboutUsBanner");
                }}
              >
                <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                  <svg
                    className={STYLE_ICON_LIST_ITEMPREFIX}
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2" cy="2.5" r="2" fill="CurrentColor" />
                  </svg>
                </ListItemPrefix>
                About Us
              </ListItem>

              <ListItem
                className={classNames(
                  STYLE_LIST_ITEM_ACCORDIONBODY,
                  "text-[14px]"
                )}
                onClick={() => {
                  navigate("/contactUsBanner");
                }}
              >
                <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                  <svg
                    className={STYLE_ICON_LIST_ITEMPREFIX}
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2" cy="2.5" r="2" fill="CurrentColor" />
                  </svg>
                </ListItemPrefix>
                Contact Us
              </ListItem>

              <ListItem
                className={classNames(
                  STYLE_LIST_ITEM_ACCORDIONBODY,
                  "text-[14px]"
                )}
                onClick={() => {
                  navigate("/productUsBanner");
                }}
              >
                <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                  <svg
                    className={STYLE_ICON_LIST_ITEMPREFIX}
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2" cy="2.5" r="2" fill="CurrentColor" />
                  </svg>
                </ListItemPrefix>
                Product
              </ListItem>

              <ListItem
                className={classNames(
                  STYLE_LIST_ITEM_ACCORDIONBODY,
                  "text-[14px]"
                )}
                onClick={() => {
                  navigate("/newsBanner");
                }}
              >
                <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
                  <svg
                    className={STYLE_ICON_LIST_ITEMPREFIX}
                    width="4"
                    height="5"
                    viewBox="0 0 4 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2" cy="2.5" r="2" fill="CurrentColor" />
                  </svg>
                </ListItemPrefix>
                News
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>

        <ListItem
          className={STYLE_LIST_ITEM}
          onClick={() => {
            navigate("/blog");
          }}
        >
          <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
            <svg
              className={STYLE_ICON_LIST_ITEMPREFIX}
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2H6C3 2 1.5 3.5 1.5 6.5V16.25C1.5 16.6625 1.8375 17 2.25 17H12C15 17 16.5 15.5 16.5 12.5V6.5C16.5 3.5 15 2 12 2Z"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.68259 6.38011L5.79009 10.2726C5.64009 10.4226 5.49759 10.7151 5.46759 10.9251L5.25759 12.4101C5.18259 12.9501 5.55759 13.3251 6.09759 13.2501L7.58258 13.0401C7.79258 13.0101 8.08509 12.8676 8.23509 12.7176L12.1276 8.82511C12.7951 8.15761 13.1176 7.37761 12.1276 6.38761C11.1376 5.39011 10.3576 5.70511 9.68259 6.38011Z"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.12744 6.93506C9.45744 8.11256 10.3799 9.04256 11.5649 9.37256"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ListItemPrefix>
          News
        </ListItem>

        <ListItem
          className={STYLE_LIST_ITEM}
          onClick={() => {
            navigate("/user");
          }}
        >
          <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
            <svg
              className={STYLE_ICON_LIST_ITEMPREFIX}
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.25 6.5C15.4926 6.5 16.5 5.49264 16.5 4.25C16.5 3.00736 15.4926 2 14.25 2C13.0074 2 12 3.00736 12 4.25C12 5.49264 13.0074 6.5 14.25 6.5Z"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.25 10.25H9"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.25 13.25H12"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V8"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ListItemPrefix>
          User
        </ListItem>

        {/* <ListItem
          onClick={() => {
            navigate("/category");
          }}
        >
          <ListItemPrefix>
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 2V7.25L10.5 5.75"
                stroke="#4A4A4A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 7.25L7.5 5.75"
                stroke="#4A4A4A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.48486 10.25H4.79236C5.07736 10.25 5.33236 10.4075 5.45986 10.6625L6.33736 12.4175C6.59236 12.9275 7.10986 13.25 7.67986 13.25H10.3274C10.8974 13.25 11.4149 12.9275 11.6699 12.4175L12.5474 10.6625C12.6749 10.4075 12.9374 10.25 13.2149 10.25H16.4849"
                stroke="#4A4A4A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.25 3.59741C2.595 3.98741 1.5 5.54741 1.5 8.74991V11.7499C1.5 15.4999 3 16.9999 6.75 16.9999H11.25C15 16.9999 16.5 15.4999 16.5 11.7499V8.74991C16.5 5.54741 15.405 3.98741 12.75 3.59741"
                stroke="#4A4A4A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ListItemPrefix>
          Category
        </ListItem> */}

        <ListItem
          className={`${STYLE_LIST_ITEM} w-fit`}
          onClick={() => {
            navigate("/orderManagement");
          }}
        >
          <ListItemPrefix className={STYLE_LIST_ITEMPREFIX}>
            <svg
              className={STYLE_ICON_LIST_ITEMPREFIX}
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 2V7.25L10.5 5.75"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 7.25L7.5 5.75"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.48486 10.25H4.79236C5.07736 10.25 5.33236 10.4075 5.45986 10.6625L6.33736 12.4175C6.59236 12.9275 7.10986 13.25 7.67986 13.25H10.3274C10.8974 13.25 11.4149 12.9275 11.6699 12.4175L12.5474 10.6625C12.6749 10.4075 12.9374 10.25 13.2149 10.25H16.4849"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.25 3.59741C2.595 3.98741 1.5 5.54741 1.5 8.74991V11.7499C1.5 15.4999 3 16.9999 6.75 16.9999H11.25C15 16.9999 16.5 15.4999 16.5 11.7499V8.74991C16.5 5.54741 15.405 3.98741 12.75 3.59741"
                stroke="CurrentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ListItemPrefix>
          Order Management
        </ListItem>
      </List>
    </div>
  );
}
