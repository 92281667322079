import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBlue } from "../../components/Button/buttonmain";
import { ICON_ADD } from "../../utils/useIcons";
import { s3File } from "../../utils/fileFormater";
import { CardProduct } from "../../components/Card/Cardmain";
import { DIAMOND_DOUBLE_WAVE_ID } from "../../utils/constants";

const RoofCoverMasterProduct = ({
  categoryPath,
  subCategoryPath,
  categoryL2Id,
  subCategoryId,
  products,
  clickDeleteProduct,
}) => {
  const navigate = useNavigate();

  // Check Sub Category
  const isDiamondDoubleWave = subCategoryId === DIAMOND_DOUBLE_WAVE_ID;

  // Grop Cover Product
  const groupIsRoofCoverProduct = _.groupBy(products, "is_roof_cover");
  const roofCoverProducts = groupIsRoofCoverProduct?.is_cover;

  // Grop Cover Type
  const groupRoofCoverTypeProduct = _.groupBy(products, "roof_cover_type");
  const roofCoverTypeStandard = groupRoofCoverTypeProduct?.roof_cover_standard;
  const roofCoverTypeCurvedRidge =
    groupRoofCoverTypeProduct?.roof_cover_curved_ridge;

  const clickEditProduct = (id) => {
    navigate(
      `/product/${categoryPath}/${subCategoryPath}/${id}?categoryL2Id_${categoryL2Id}_isRoofCover`
    );
  };

  const clickViewProduct = (id) => {
    navigate(
      `/roofCoverSkuProduct/${categoryPath}/${subCategoryPath}/${categoryL2Id}?masterId_${id}`
    );
  };

  // Click Edit Roof Cover Standard
  const clickEditStandardProduct = (id) => {
    navigate(
      `/product/${categoryPath}/${subCategoryPath}/${id}?categoryL2Id_${categoryL2Id}_isRoofCover_standard`
    );
  };

  // Click Edit Roof Cover Curved Ridge
  const clickEditCurvedRidgeProduct = (id) => {
    navigate(
      `/product/${categoryPath}/${subCategoryPath}/${id}?categoryL2Id_${categoryL2Id}_isRoofCover_curvedRidge`
    );
  };

  return (
    <>
      {isDiamondDoubleWave ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="font-bold text-primary text-[28px] -mb-7">
              ครอบมาตรฐาน
            </div>

            <div className="flex justify-center items-center">
              {!roofCoverTypeStandard ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {roofCoverTypeStandard
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          isMasterProduct
                          product={product}
                          masterId={product._id}
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() =>
                            clickEditStandardProduct(product._id)
                          }
                          onClickDelete={clickDeleteProduct}
                        />
                      );
                    })}
                </div>
              )}
            </div>

            <div className="flex flex-col items-center gap-2">
              <ButtonBlue
                onClick={() => {
                  navigate(
                    `/product/${categoryPath}/${subCategoryPath}/add?categoryL2Id_${categoryL2Id}_isRoofCover_standard`
                  );
                }}
                title="เพิ่มสินค้า"
                icon={<ICON_ADD className=" text-white w-5 h-5" />}
              />
              <div className="text-primary_gray text-sm font-light">
                (สามารถเพิ่มสินค้าเองได้)
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
            <div className="font-bold text-primary text-[28px] -mb-7">
              ครอบสันโค้ง
            </div>

            <div className="flex justify-center items-center">
              {!roofCoverTypeCurvedRidge ? (
                <img src="/not-found.png" className=" w-40 h-40" />
              ) : (
                <div className="grid grid-cols-4 gap-5">
                  {roofCoverTypeCurvedRidge
                    ?.sort((a, b) => a.number_index - b.number_index)
                    ?.map((product, index) => {
                      const imageSrc = s3File(product?.image_key);
                      return (
                        <CardProduct
                          isMasterProduct
                          product={product}
                          masterId={product._id}
                          key={index}
                          totalProduct={product.totalProduct}
                          name={product.product_name}
                          img={imageSrc}
                          list={product.number_index}
                          onClick={() => clickViewProduct(product._id)}
                          onClickEdit={() =>
                            clickEditCurvedRidgeProduct(product._id)
                          }
                          onClickDelete={clickDeleteProduct}
                        />
                      );
                    })}
                </div>
              )}
            </div>

            <div className="flex flex-col items-center gap-2">
              <ButtonBlue
                onClick={() => {
                  navigate(
                    `/product/${categoryPath}/${subCategoryPath}/add?categoryL2Id_${categoryL2Id}_isRoofCover_curvedRidge`
                  );
                }}
                title="เพิ่มสินค้า"
                icon={<ICON_ADD className=" text-white w-5 h-5" />}
              />
              <div className="text-primary_gray text-sm font-light">
                (สามารถเพิ่มสินค้าเองได้)
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-12 bg-white p-8 rounded-2xl drop-shadow-xl">
          <div className="font-bold text-primary text-[28px] -mb-7">ครอบ</div>

          <div className="flex justify-center items-center">
            {!roofCoverProducts ? (
              <img src="/not-found.png" className=" w-40 h-40" />
            ) : (
              <div className="grid grid-cols-4 gap-5">
                {roofCoverProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        isMasterProduct
                        product={product}
                        masterId={product._id}
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={clickDeleteProduct}
                      />
                    );
                  })}
              </div>
            )}
          </div>

          <div className="flex flex-col items-center gap-2">
            <ButtonBlue
              onClick={() => {
                navigate(
                  `/product/${categoryPath}/${subCategoryPath}/add?categoryL2Id_${categoryL2Id}_isRoofCover`
                );
              }}
              title="เพิ่มสินค้า"
              icon={<ICON_ADD className=" text-white w-5 h-5" />}
            />
            <div className="text-primary_gray text-sm font-light">
              (สามารถเพิ่มสินค้าเองได้)
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RoofCoverMasterProduct;
