import React, { createContext, useState } from "react";

export const SideBarContext = createContext({});

export const SideBarProvider = ({ ...props }) => {
  const [isRefetch, setIsRefetch] = useState(false);

  return (
    <SideBarContext.Provider value={{ isRefetch, setIsRefetch }}>
      {props?.children}
    </SideBarContext.Provider>
  );
};
