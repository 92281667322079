import { useEffect, useState } from "react";
import { AppApi } from "../../api";
import { s3File } from "../../utils/fileFormater";
import {
  VIDEO_BACKGROUND_COVER_ID,
  YOUTUBE_VIDEO_ID,
  RECOMMEND_PRODUCT_IMAGE_ID,
  CUSTOMER_PRODUCT_IMAGE_ID,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { FunctionGetYouTubeId } from "radium-omnibus";
import Iframe from "react-iframe";
import useSweetAlert from "../../_hooks/useSweetAlert";
import Container from "../../components/Container";
import { STYLE_BG_CONTAIN } from "../../utils/useStyle";

const HomeBanner = () => {
  const navigate = useNavigate();

  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();

  const [video, setVideo] = useState(null);
  const [youtubeVideo, setYouTubeVideo] = useState("");

  const [recommendProducts, setRecommendProducts] = useState([]);
  // console.log("recommendProducts : ", recommendProducts);

  const [ourCustomers, setOurCustomers] = useState([]);
  // console.log("ourCustomers : ", ourCustomers);

  const loadData = async () => {
    try {
      const { data } = await AppApi.get(`media/${VIDEO_BACKGROUND_COVER_ID}`);

      const { data: youtubeData } = await AppApi.get(
        `media/${YOUTUBE_VIDEO_ID}`
      );

      const { data: recommendProductData } = await AppApi.get(
        `media/${RECOMMEND_PRODUCT_IMAGE_ID}`
      );

      const { data: ourCustomerData } = await AppApi.get(
        `media/${CUSTOMER_PRODUCT_IMAGE_ID}`
      );

      setVideo(s3File(data.media.value));
      setYouTubeVideo(youtubeData.media.value);
      setRecommendProducts(recommendProductData.media.values);
      setOurCustomers(ourCustomerData.media.values);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      loadData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const deleteOurCustomer = async (id) => {
    alertConfirm("ยืนยันการลบ ?", "", async () => {
      try {
        const payload = {
          delete_media_id: id,
        };

        await AppApi.put(`media/${CUSTOMER_PRODUCT_IMAGE_ID}`, payload);

        alertSuccess("Delete Success");

        loadData();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const deleteRecommendProduct = async (id) => {
    alertConfirm("ยืนยันการลบ ?", "", async () => {
      try {
        const payload = {
          delete_media_id: id,
        };

        await AppApi.put(`media/${RECOMMEND_PRODUCT_IMAGE_ID}`, payload);

        alertSuccess("Delete Success");

        loadData();
      } catch (error) {
        console.log(error);
      }
    });
  };

  return (
    <div
      className={STYLE_BG_CONTAIN}
      style={{
        backgroundImage: `url('/background.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <div className="flex flex-col gap-8">
          <div className="text-white text-[48px]">BANNER</div>
          <div className="border-2 rounded-full bg-[#f5f5f5] p-1 w-48 text-center">
            Home
          </div>

          <div className="bg-[#f9f9f9] w-full h-full rounded-2xl p-6 shadow-xl flex flex-col gap-6">
            <div className="flex justify-end">
              <div
                className=" flex items-center text-[#A32330] gap-1 cursor-pointer"
                onClick={() => navigate(`/formVideoBanner`)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.33337 1.33325H6.00004C2.66671 1.33325 1.33337 2.66659 1.33337 5.99992V9.99992C1.33337 13.3333 2.66671 14.6666 6.00004 14.6666H10C13.3334 14.6666 14.6667 13.3333 14.6667 9.99992V8.66659"
                    stroke="#A32330"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.6934 2.01326L5.44004 7.26659C5.24004 7.46659 5.04004 7.85992 5.00004 8.14659L4.71337 10.1533C4.60671 10.8799 5.12004 11.3866 5.84671 11.2866L7.85337 10.9999C8.13337 10.9599 8.52671 10.7599 8.73338 10.5599L13.9867 5.30659C14.8934 4.39992 15.32 3.34659 13.9867 2.01326C12.6534 0.679924 11.6 1.10659 10.6934 2.01326Z"
                    stroke="#A32330"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993"
                    stroke="#A32330"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>แก้ไข</div>
              </div>
            </div>

            {/* <div className="flex flex-col gap-4 mb-6">
              <div className="text-primary font-bold text-[24px]">
                Video Banner
              </div>
              <div className="text-gray-500">
                ขนาดวิดีโอ 16:9 / 1440px*1024px ความยาววิดีโอควรอยู่ระหว่าง 10 -
                15 วินาที
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-gray-400">ตัวอย่าง</div>
                <div className="bg-white w-full h-full border-2 rounded-xl flex justify-center">
                  <video
                    src={video}
                    className="w-auto h-[231px] object-cover"
                    controls
                  />
                </div>
              </div>
            </div> */}

            <div className="flex flex-col gap-4">
              <div className="text-primary font-bold text-[24px]">Youtube</div>
              <div className="text-gray-500">
                จะแสดงโชว์ในหน้าแรกตรงส่วนเกี่ยวกับธนานันท์
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-gray-400">ตัวอย่าง</div>
                <div className="bg-white w-full h-full border-2 rounded-xl flex justify-center">
                  <Iframe
                    url={
                      youtubeVideo?.slice(0, 5) === "https"
                        ? `https://www.youtube.com/embed/${FunctionGetYouTubeId(
                            youtubeVideo
                          )}`
                        : `https://www.youtube.com/embed/${youtubeVideo}`
                    }
                    className="w-[412px] h-[231px]"
                    display="initial"
                  />
                </div>
              </div>
            </div>
          </div>

          {/*---------- ผลิตภัณฑ์แนะนำพิเศษ ----------*/}
          <div className="bg-[#f9f9f9] w-full h-full  rounded-2xl p-6 shadow-xl flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <div className="text-primary font-bold text-[24px]">
                ผลิตภัณฑ์แนะนำพิเศษ
              </div>
              <div>ขนาดภาพแนะนำ Ratio 16:9 / 1260px*512px</div>
            </div>

            {recommendProducts.length === 0 ? (
              <div className="flex justify-center">
                <img src="./not-found.png" className="w-44 h-44" />
              </div>
            ) : (
              <div className="grid grid-cols-4 gap-6">
                {recommendProducts
                  ?.sort((a, b) => a.media_path_no - b.media_path_no)
                  ?.map((recommendProduct, index) => {
                    const imageSrc = s3File(recommendProduct.media_path);

                    return (
                      <div
                        key={index}
                        className="p-4 bg-white rounded-lg shadow-md hover:shadow-xl"
                      >
                        <div className="flex justify-end gap-3 items-center mb-2">
                          <div
                            className=" flex items-center text-[#a4a4a4] gap-1 cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/formImageValues/${recommendProduct._id}?product?edit`
                              )
                            }
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.33337 1.33325H6.00004C2.66671 1.33325 1.33337 2.66659 1.33337 5.99992V9.99992C1.33337 13.3333 2.66671 14.6666 6.00004 14.6666H10C13.3334 14.6666 14.6667 13.3333 14.6667 9.99992V8.66659"
                                stroke="#a4a4a4"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.6934 2.01326L5.44004 7.26659C5.24004 7.46659 5.04004 7.85992 5.00004 8.14659L4.71337 10.1533C4.60671 10.8799 5.12004 11.3866 5.84671 11.2866L7.85337 10.9999C8.13337 10.9599 8.52671 10.7599 8.73338 10.5599L13.9867 5.30659C14.8934 4.39992 15.32 3.34659 13.9867 2.01326C12.6534 0.679924 11.6 1.10659 10.6934 2.01326Z"
                                stroke="#a4a4a4"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993"
                                stroke="#a4a4a4"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div>แก้ไข</div>
                          </div>
                          <svg
                            onClick={() =>
                              deleteRecommendProduct(recommendProduct._id)
                            }
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" cursor-pointer"
                          >
                            <path
                              d="M14 4.48665C11.78 4.26665 9.54667 4.15332 7.32 4.15332C6 4.15332 4.68 4.21999 3.36 4.35332L2 4.48665"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.66797 3.81325L5.81464 2.93992C5.9213 2.30659 6.0013 1.83325 7.12797 1.83325H8.87464C10.0013 1.83325 10.088 2.33325 10.188 2.94659L10.3346 3.81325"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.5669 6.59326L12.1336 13.3066C12.0603 14.3533 12.0003 15.1666 10.1403 15.1666H5.86026C4.00026 15.1666 3.94026 14.3533 3.86693 13.3066L3.43359 6.59326"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.88672 11.5H9.10672"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.33203 8.83325H9.66536"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>

                        <div
                          // onClick={() => {
                          //   navigate(`/category/${categoryPath}/${category.path}?view`);
                          // }}
                          className=" cursor-pointer w-full h-full"
                        >
                          <div className="border-2 w-full h-[163px] flex items-center mb-2">
                            <img
                              className="my-4 w-auto h-auto object-cover mx-auto"
                              src={imageSrc}
                            />
                          </div>
                          <div className="flex gap-2 text-gray-500">
                            <div>ลำดับ :</div>
                            <div className="font-bold">
                              {recommendProduct.media_path_no}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}

            <button
              className="flex justify-center items-center text-white bg-primary rounded-full p-2 w-full"
              onClick={() =>
                navigate(
                  `/formImageValues/${RECOMMEND_PRODUCT_IMAGE_ID}?product?add`
                )
              }
            >
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_2949_632)">
                  <g clip-path="url(#clip0_2949_632)">
                    <path
                      d="M12.5 16H9.93C7.64 16 6.5 14.86 6.5 12.57V5.43C6.5 3.14 7.64 2 9.93 2H14.5C16.79 2 17.93 3.14 17.93 5.43"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.0699 22H18.4999C16.2099 22 15.0699 20.86 15.0699 18.57V11.43C15.0699 9.14 16.2099 8 18.4999 8H23.0699C25.3599 8 26.4999 9.14 26.4999 11.43V18.57C26.4999 20.86 25.3599 22 23.0699 22Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.37 15H22.63"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 16.6301V13.3701"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_2949_632"
                    x="0.5"
                    y="0"
                    width="32"
                    height="32"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_2949_632"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_2949_632"
                      result="shape"
                    />
                  </filter>
                  <clipPath id="clip0_2949_632">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(4.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div>เพิ่มรูป</div>
            </button>
          </div>

          {/*---------- ลูกค้าของเรา ----------*/}
          <div className="bg-[#f9f9f9] w-full h-full rounded-2xl p-6 shadow-xl flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <div className="text-primary font-bold text-[24px]">
                ลูกค้าของเรา
              </div>
              <div>ขนาดภาพแนะนำ Ratio 1:1 / 334px*334px</div>
            </div>

            {ourCustomers.length === 0 ? (
              <div className="flex justify-center">
                <img src="./not-found.png" className="w-44 h-44" />
              </div>
            ) : (
              <div className="grid grid-cols-4 gap-6">
                {ourCustomers
                  ?.sort((a, b) => a.media_path_no - b.media_path_no)
                  ?.map((ourCustomer, index) => {
                    const imageSrc = s3File(ourCustomer.media_path);

                    return (
                      <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-xl ">
                        <div className="flex justify-end gap-3 items-center mb-2">
                          <div
                            className=" flex items-center text-[#a4a4a4] gap-1 cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/formImageValues/${ourCustomer._id}?customer?edit`
                              )
                            }
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.33337 1.33325H6.00004C2.66671 1.33325 1.33337 2.66659 1.33337 5.99992V9.99992C1.33337 13.3333 2.66671 14.6666 6.00004 14.6666H10C13.3334 14.6666 14.6667 13.3333 14.6667 9.99992V8.66659"
                                stroke="#a4a4a4"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.6934 2.01326L5.44004 7.26659C5.24004 7.46659 5.04004 7.85992 5.00004 8.14659L4.71337 10.1533C4.60671 10.8799 5.12004 11.3866 5.84671 11.2866L7.85337 10.9999C8.13337 10.9599 8.52671 10.7599 8.73338 10.5599L13.9867 5.30659C14.8934 4.39992 15.32 3.34659 13.9867 2.01326C12.6534 0.679924 11.6 1.10659 10.6934 2.01326Z"
                                stroke="#a4a4a4"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993"
                                stroke="#a4a4a4"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <div>แก้ไข</div>
                          </div>
                          <svg
                            onClick={() => deleteOurCustomer(ourCustomer._id)}
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className=" cursor-pointer"
                          >
                            <path
                              d="M14 4.48665C11.78 4.26665 9.54667 4.15332 7.32 4.15332C6 4.15332 4.68 4.21999 3.36 4.35332L2 4.48665"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.66797 3.81325L5.81464 2.93992C5.9213 2.30659 6.0013 1.83325 7.12797 1.83325H8.87464C10.0013 1.83325 10.088 2.33325 10.188 2.94659L10.3346 3.81325"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.5669 6.59326L12.1336 13.3066C12.0603 14.3533 12.0003 15.1666 10.1403 15.1666H5.86026C4.00026 15.1666 3.94026 14.3533 3.86693 13.3066L3.43359 6.59326"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.88672 11.5H9.10672"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.33203 8.83325H9.66536"
                              stroke="#A32330"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>

                        <div
                          // onClick={() => {
                          //   navigate(`/category/${categoryPath}/${category.path}?view`);
                          // }}
                          className=" cursor-pointer w-full h-full"
                        >
                          <div className="border-2 w-full h-[163px] flex items-center mb-2">
                            <img
                              className="my-4 w-auto h-auto object-cover mx-auto"
                              src={imageSrc}
                            />
                          </div>

                          <div className="flex gap-2 text-gray-500">
                            <div>ลำดับ :</div>
                            <div className="font-bold">
                              {ourCustomer.media_path_no}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}

            <button
              className="flex justify-center items-center text-white bg-primary rounded-full p-2 w-full"
              onClick={() =>
                navigate(
                  `/formImageValues/${CUSTOMER_PRODUCT_IMAGE_ID}?customer?add`
                )
              }
            >
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_2949_632)">
                  <g clip-path="url(#clip0_2949_632)">
                    <path
                      d="M12.5 16H9.93C7.64 16 6.5 14.86 6.5 12.57V5.43C6.5 3.14 7.64 2 9.93 2H14.5C16.79 2 17.93 3.14 17.93 5.43"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.0699 22H18.4999C16.2099 22 15.0699 20.86 15.0699 18.57V11.43C15.0699 9.14 16.2099 8 18.4999 8H23.0699C25.3599 8 26.4999 9.14 26.4999 11.43V18.57C26.4999 20.86 25.3599 22 23.0699 22Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.37 15H22.63"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 16.6301V13.3701"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_2949_632"
                    x="0.5"
                    y="0"
                    width="32"
                    height="32"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_2949_632"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_2949_632"
                      result="shape"
                    />
                  </filter>
                  <clipPath id="clip0_2949_632">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(4.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <div>เพิ่มรูป</div>
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeBanner;
