import { Storage } from "aws-amplify";
import { TypeFile, s3File } from "./fileFormater";
import { S3_URL } from "./constants";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const onImageUploadBefore = (files, info, core, uploadHandler) => {
  if (!files && files === null && files === undefined && files < 0) return;
  (async () => {
    const file = files[0];

    const keyUpload = `${new Date().getTime()}.${TypeFile[file.type]}`;

    await Storage.put(keyUpload, file);

    const responseUpload = {
      result: [
        {
          url: s3File(keyUpload),
          name: files[0].name,
          size: files[0].size,
        },
      ],
    };
    await uploadHandler(responseUpload);
  })();
  uploadHandler();
};

export const updateJsonDataToS3 = async (data, name) => {
  try {
    const fileContent = JSON.stringify(data);
    const result = await Storage.put(name, fileContent, {
      contentType: "application/json",
    });
    console.log(result);
  } catch (error) {
    console.error(error);
  }
};

export const getDataFromS3 = async (name) => {
  try {
    const dataFromS3 = await fetch(`${S3_URL}${name}`, {
      cache: "no-cache",
    });

    const jsonData = await dataFromS3.json();

    return jsonData;
  } catch (error) {
    console.error("ERROR getDataFromS3", error);
  }
};

export const formatBytes = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const fileSize =
    parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + "_" + sizes[i];

  const fileSizeValue = Number(fileSize.split("_")[0]);
  const fileSizeUnit = fileSize.split("_")[1];

  // Format KB to MB
  if (fileSizeUnit === "KB") {
    let result = fileSizeValue / 1024;
    return result;
  }
  // Format GB to MB
  if (fileSizeUnit === "GB") {
    let result = fileSizeValue * 1024;
    return result;
  }

  return fileSizeValue;
};

export const errorScroll = (id) => {
  document.getElementById(id).scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

export const functionFormProductCheckPumpMode = ({
  isMitsubishiConstantPressureMode,
  isMitsubishiAutomaticMode,
  isHitachiXxSeriesMode,
  isHitachiAutomaticInverterMode,
  isHitachiAutomaticXxSeriesMode,
  isHitachiPressureStainlessMode,
  isHitachiSuckSmallMode,
  isGrundfosAutomaticMode,
  isGrundfosAutomaticInverterMode,
  setGroupPumpName,
  setPumpName,
}) => {
  if (isMitsubishiConstantPressureMode) {
    setGroupPumpName("mitsubishi_constant_pressure");
    setPumpName("ปั๊มน้ำแรงดันคงที่");
  } else if (isMitsubishiAutomaticMode) {
    setGroupPumpName("mitsubishi_automatic");
    setPumpName("ปั๊มน้ำอัตโนมัติ");
  } else if (isHitachiXxSeriesMode) {
    setGroupPumpName("hitachi_xx_series");
    setPumpName("ปั๊มน้ำแรงดันคงที่ XX Series");
  } else if (isHitachiAutomaticInverterMode) {
    setGroupPumpName("hitachi_automatic_inverter");
    setPumpName("ปั๊มน้ำอัตโนมัติอินเวอร์เตอร์");
  } else if (isHitachiAutomaticXxSeriesMode) {
    setGroupPumpName("hitachi_automatic_xx_series");
    setPumpName("ปั๊มน้ำอัตโนมัติ XX Series");
  } else if (isHitachiPressureStainlessMode) {
    setGroupPumpName("hitachi_pressure_stainless");
    setPumpName("ปั๊มน้ำแรงดัน (ถังสแตนเลส)");
  } else if (isHitachiSuckSmallMode) {
    setGroupPumpName("hitachi_suck_small");
    setPumpName("ปั๊มดูดน้ำเล็ก");
  } else if (isGrundfosAutomaticMode) {
    setGroupPumpName("grundfos_automatic");
    setPumpName("ปั๊มน้ำอัตโนมัติ");
  } else if (isGrundfosAutomaticInverterMode) {
    setGroupPumpName("grundfos_automatic_inverter");
    setPumpName("ปั๊มน้ำอัตโนมัติอินเวอร์เตอร์");
  }
};

export const functionFormProductCheckIsNaturaWaterPac = ({
  isNaturaWaterPac,
  hasNaturaPumpName,
  setGroupNaturaPumpName,
  setNaturaPumpName,
}) => {
  if (isNaturaWaterPac) {
    if (hasNaturaPumpName === "NaturaGrundfosPump") {
      setGroupNaturaPumpName("natura_grundfos_pump");
      setNaturaPumpName("GRUNDFOS");
    } else if (hasNaturaPumpName === "NaturaHitachiPump") {
      setGroupNaturaPumpName("natura_hitachi_pump");
      setNaturaPumpName("HITACHI");
    } else if (hasNaturaPumpName === "NaturaMitsubishiPump") {
      setGroupNaturaPumpName("natura_mitsubishi_pump");
      setNaturaPumpName("MITSUBISHI");
    }
  }
};

export const functionFormProductCheckSanitaryProductMode = ({
  isOnePieceToiletMode,
  isTwoPieceToiletMode,
  isForPublicRestroomMode,
  isColdWaterFaucetMode,
  isMixerFaucetMode,
  isSoftShowerMode,
  isRainShowerMode,
  isWashBasinPlaceOnCounterMode,
  isWashBasinHungWallMode,
  isWashBasinUnderCounterMode,
  isWashBasinHalfCounterMode,
  isWashBasinTopCounterMode,
  setGroupSanitaryWareName,
  setSanitaryWareName,
}) => {
  if (isOnePieceToiletMode) {
    setGroupSanitaryWareName("one_piece_toilet");
    setSanitaryWareName("สุขภัณฑ์แบบชิ้นเดียว");
  } else if (isTwoPieceToiletMode) {
    setGroupSanitaryWareName("two_piece_toilet");
    setSanitaryWareName("สุขภัณฑ์แบบสองชิ้น");
  } else if (isForPublicRestroomMode) {
    setGroupSanitaryWareName("for_public_restroom");
    setSanitaryWareName("สุขภัณฑ์สำหรับห้องน้ำสาธารณะ");
  } else if (isColdWaterFaucetMode) {
    setGroupSanitaryWareName("cold_water_faucet");
    setSanitaryWareName("ก๊อกน้ำเย็น");
  } else if (isMixerFaucetMode) {
    setGroupSanitaryWareName("mixer_faucet");
    setSanitaryWareName("ก๊อกน้ำผสม");
  } else if (isSoftShowerMode) {
    setGroupSanitaryWareName("soft_shower");
    setSanitaryWareName("ฝักบัวสายอ่อน");
  } else if (isRainShowerMode) {
    setGroupSanitaryWareName("rain_shower");
    setSanitaryWareName("เรนชาวเวอร์");
  } else if (isWashBasinPlaceOnCounterMode) {
    setGroupSanitaryWareName("wash_basin_place_on_counter");
    setSanitaryWareName("อ่างล้างหน้าวางบนเคาน์เตอร์");
  } else if (isWashBasinHungWallMode) {
    setGroupSanitaryWareName("wash_basin_hung_wall");
    setSanitaryWareName("อ่างล้างหน้าแบบแขวนผนัง");
  } else if (isWashBasinUnderCounterMode) {
    setGroupSanitaryWareName("wash_basin_under_counter");
    setSanitaryWareName("อ่างล้างหน้าแบบฝังใต้เคาน์เตอร์");
  } else if (isWashBasinHalfCounterMode) {
    setGroupSanitaryWareName("wash_basin_half_counter");
    setSanitaryWareName("อ่างล้างหน้าแบบฝังครึ่งเคาน์เตอร์");
  } else if (isWashBasinTopCounterMode) {
    setGroupSanitaryWareName("wash_basin_top_counter");
    setSanitaryWareName("อ่างล้างหน้าแบบฝังบนเคาน์เตอร์");
  }
};

export const functionExportPdf = async ({
  fileName = new Date().getTime(),
  id,
  width = 2480,
  height = 3508,
}) => {
  // let element = component({ data, others });

  const doc = new jsPDF("p", "px", [width, height], true);

  const docElement = document.getElementById(`${id}`);

  const canvas = await html2canvas(docElement, { scale: 2, useCORS: true });
  const image = canvas.toDataURL("image/png");

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const widthRatio = pageWidth / canvas.width;
  const heightRatio = pageHeight / canvas.height;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  const canvasWidth = canvas.width * ratio;
  const canvasHeight = canvas.height * ratio;

  const marginX = (pageWidth - canvasWidth) / 2;
  const marginY = 10;
  doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
  doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
  doc.save(`${fileName}.pdf`);
};
