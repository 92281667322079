import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import { STYLE_CARD_PARENT_ADD } from "../../utils/useStyle";
import { useParams } from "react-router-dom";
import { AppApi } from "../../api";
import { s3File } from "../../utils/fileFormater";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export const ContentView = ({ value }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  if (loaded) {
    return (
      <SunEditor
        hideToolbar={true}
        disableToolbar={true}
        defaultValue={value}
        disable={true}
        enableToolbar={false}
        showToolbar={false}
        setContents={value}
        width="100%"
        height="100%"
        setOptions={{
          buttonList: [],
        }}
      />
    );
  } else {
    return (
      <div className="min-h-[500px] flex justify-center mt-20">Loading</div>
    );
  }
};

const BlogView = () => {
  const params = useParams();
  const blogId = params.id;

  const [blog, setBlog] = useState({});

  useEffect(() => {
    const load = async () => {
      try {
        const { data } = await AppApi.get(`blogs/${blogId}`);
        setBlog(data?.blog);
      } catch (error) {
        console.log(error);
      }
    };

    if (blogId) {
      load();
    }
  }, [blogId]);

  return (
    <div className="bg-[#F9F9F9] w-full h-full">
      <Container>
        <div className="text-primary text-5xl font-light mb-8">
          รายละเอียดข่าวสาร
        </div>

        <div className={STYLE_CARD_PARENT_ADD}>
          <div className=" flex flex-col gap-6">
            <div className="flex flex-col gap-1 font-normal relative">
              <div className="font-semibold text-[20px] text-tnn_gray_200">
                รูปภาพตัวอย่าง
              </div>

              <div className=" border border-tnn_gray_50 rounded-md w-full h-60 p-3">
                <img
                  className="w-full h-full object-contain"
                  src={s3File(blog?.image_key)}
                  alt="image"
                />
              </div>
            </div>

            <div className="flex flex-col gap-1 font-normal">
              <div className="font-semibold text-[20px] text-tnn_gray_200">
                หัวข้อ
              </div>
              <div className="border border-tnn_gray_50 rounded-md px-5 py-2">
                {blog.title}
              </div>
            </div>

            <div className="flex flex-col gap-1 font-normal">
              <div className="font-semibold text-[20px] text-tnn_gray_200">
                คำอธิบาย
              </div>
              <div className="border border-tnn_gray_50 rounded-md px-5 py-2">
                {blog.description}
              </div>
            </div>

            <div className="flex flex-col gap-1 font-normal">
              <div className="font-semibold text-[20px] text-tnn_gray_200">
                รายละเอียดเนื้อหา
              </div>
              {/* <div
                className="border border-tnn_gray_50 rounded-md px-5 py-2"
                dangerouslySetInnerHTML={{
                  __html: blog?.content,
                }}
              /> */}
              <ContentView value={blog?.content} />
            </div>

            <div className="flex flex-col gap-1 font-normal">
              <div className="font-semibold text-[20px] text-tnn_gray_200">
                ลำดับ
              </div>
              <div className="border border-tnn_gray_50 rounded-md px-5 py-2">
                {blog.number_index}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BlogView;
