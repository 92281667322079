import React, { useState } from "react";
import { CardProduct } from "../../components/Card/Cardmain";
import { s3File } from "../../utils/fileFormater";

const DiamondDechra = ({
  isDiamondDechraSenatorShingle,
  isDiamondDechraMilano,
  products,
  clickViewProduct,
  clickEditProduct,
  clickDeleteProduct,
}) => {
  // Group Product Diamond By Color Type
  const groupColorDiamondProduct = _.groupBy(products, "color_type");
  const dechraSenatorShingles = groupColorDiamondProduct?.senator_shingle;
  const dechraGerardShaks = groupColorDiamondProduct?.gerard_shake;
  const dechraMilanos = groupColorDiamondProduct?.milano;

  // Group Roof Product
  const groupIsRoofDechraSenatorShingles = _.groupBy(
    dechraSenatorShingles,
    "is_roof_cover"
  );
  const dechraSenatorShingleProducts =
    groupIsRoofDechraSenatorShingles?.is_roof;

  const groupIsRoofDechraGerardShake = _.groupBy(
    dechraGerardShaks,
    "is_roof_cover"
  );
  const dechraGerardShakeProducts = groupIsRoofDechraGerardShake?.is_roof;

  const groupIsRoofDechraMilanos = _.groupBy(dechraMilanos, "is_roof_cover");
  const dechraMilanoProducts = groupIsRoofDechraMilanos?.is_roof;

  return (
    <>
      {isDiamondDechraSenatorShingle ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="font-bold text-primary text-[28px]">
              SENATOR SHINGLE
            </div>
            {dechraSenatorShingleProducts ? (
              <div className="grid grid-cols-4 gap-5">
                {dechraSenatorShingleProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="flex justify-center">
                <img src="/not-found.png" className=" w-40 h-40" />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-5">
            <div className="font-bold text-primary text-[28px]">
              GERARD SHAKE
            </div>
            {dechraGerardShakeProducts ? (
              <div className="grid grid-cols-4 gap-5">
                {dechraGerardShakeProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="flex justify-center">
                <img src="/not-found.png" className=" w-40 h-40" />
              </div>
            )}
          </div>
        </div>
      ) : isDiamondDechraMilano ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="font-bold text-primary text-[28px]">MILANO</div>
            {dechraMilanoProducts ? (
              <div className="grid grid-cols-4 gap-5">
                {dechraMilanoProducts
                  ?.sort((a, b) => a.number_index - b.number_index)
                  ?.map((product, index) => {
                    const imageSrc = s3File(product?.image_key);
                    return (
                      <CardProduct
                        key={index}
                        totalProduct={product.totalProduct}
                        name={product.product_name}
                        img={imageSrc}
                        list={product.number_index}
                        onClick={() => clickViewProduct(product._id)}
                        onClickEdit={() => clickEditProduct(product._id)}
                        onClickDelete={() => clickDeleteProduct(product)}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="flex justify-center">
                <img src="/not-found.png" className=" w-40 h-40" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>Page Not Found.</div>
      )}
    </>
  );
};

export default DiamondDechra;
