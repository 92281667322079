import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppApi } from "../../api";
import { TypeFile, s3File } from "../../utils/fileFormater";
import useSweetAlert from "../../_hooks/useSweetAlert";
import { HiPencil, HiTrash } from "react-icons/hi2";
import { Storage } from "aws-amplify";
import {
  RECOMMEND_PRODUCT_IMAGE_ID,
  CUSTOMER_PRODUCT_IMAGE_ID,
  IMAGE_TYPE_LIST,
} from "../../utils/constants";
import Container from "../../components/Container";
import { InputMain } from "../../components/Input/inputmain";

const FormImageValues = () => {
  const params = useParams();
  const imageUploadId = params.uploadId;

  const location = useLocation();

  const formType = location?.search.split("?")[1];
  const isProductMode = formType === "product";
  const isCustomerMode = formType === "customer";

  const formMode = location?.search.split("?")[2];
  const isEditMode = formMode === "edit";
  const isAddMode = formMode === "add";

  const navigate = useNavigate();

  const inputImageRef = useRef();

  const [hasImage, setHasImage] = useState(false);

  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();

  const [image, setImage] = useState(null);
  const [currentImage, setCerrentImage] = useState(null);
  const [indexNumber, setIndexNumber] = useState(1);
  const [uploading, setUploading] = useState(false);

  const reqId = isProductMode
    ? RECOMMEND_PRODUCT_IMAGE_ID
    : CUSTOMER_PRODUCT_IMAGE_ID;

  const loadMedias = async () => {
    try {
      setUploading(true);
      const { data } = await AppApi.get(`media/${reqId}`);
      const mediaValues = data.media.values;
      const finalValue = mediaValues.filter((e) => e._id === imageUploadId);

      setImage(s3File(finalValue[0]?.media_path));
      setCerrentImage(finalValue[0]?.media_path);
      setIndexNumber(finalValue[0]?.media_path_no);
    } catch (error) {
      console.log("ERROR TO LOAD MEDIAS", error);
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      loadMedias();
    }
  }, []);

  const onChangeImage = (e) => {
    if (!e.target.value) return;
    const type = e.target.files[0].type;
    if (!IMAGE_TYPE_LIST.includes(type)) {
      alertError("รองรับไฟล์ PNG หรือ JPEG");
      return;
    }

    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = function (e) {
      const image = new Image();
      image.onload = function () {
        const width = this.width;
        const height = this.height;
        // console.log(`width: `, width);
        // console.log(`height: `, height);

        if (isProductMode) {
          if (width <= 1260 && height <= 512) {
            // Valid image file
            // Perform further actions here
            setImage(file);
            setHasImage(false);
          } else {
            // Invalid image file

            alertError("รองรับไฟล์ขนาดไม่เกิน 1260x512");
          }
        }

        if (isCustomerMode) {
          if (width <= 334 && height <= 334) {
            // Valid image file
            // Perform further actions here
            setImage(file);
            setHasImage(false);
          } else {
            // Invalid image file

            alertError("รองรับไฟล์ขนาดไม่เกิน 334x334");
          }
        }
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const clearForm = () => {
    setImage(null);
    setIndexNumber(null);
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!image) {
      setHasImage(true);
      return;
    }

    // Add
    if (isAddMode) {
      try {
        setUploading(true);

        if (image?.size > 0) {
          const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;
          await Storage.put(keyUpload, image);

          let payload = {
            media_path: keyUpload,
            media_path_no: indexNumber,
          };

          await AppApi.put(`media/${imageUploadId}`, payload);
        }

        e.target.reset();
        // clearForm();
        alertSuccess("อัพโหลดสำเร็จ", "", () => navigate(-1));
      } catch (error) {
        console.log(error);
        alertError("อัพโหลดไม่สำเร็จ");
      } finally {
        setUploading(false);
      }
    } else {
      // Edit
      try {
        setUploading(true);
        let payload = {
          media_id: imageUploadId,
          media_path_no: indexNumber,
          media_path: "",
        };

        if (image?.size > 0) {
          const keyUpload = `${new Date().getTime()}.${TypeFile[image.type]}`;
          await Storage.put(keyUpload, image);
          payload.media_path = keyUpload;
        } else {
          payload.media_path = currentImage;
        }

        await AppApi.put(`media/${reqId}`, payload);

        e.target.reset();
        // clearForm();
        alertSuccess("อัพโหลดสำเร็จ", "", () => navigate(-1));
      } catch (error) {
        alertError("อัพโหลดไม่สำเร็จ");
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <>
      {isAddMode ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          {isProductMode ? (
            <Container>
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center">
                  <div className="text-primary text-[48px]">
                    เพิ่มรูป - ผลิตภัณฑ์แนะนำพิเศษ
                  </div>
                  <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                    Home
                  </div>
                </div>

                <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                  <form
                    className="flex flex-col gap-20"
                    onSubmit={onSubmitForm}
                  >
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2 relative">
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold text-[20px] text-tnn_gray_200">
                            รูปตัวอย่าง
                          </div>
                          <div className="text-gray-500">
                            รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9 /
                            1260px*512px
                          </div>
                        </div>
                        <input
                          type="file"
                          className="hidden"
                          name="image"
                          onChange={onChangeImage}
                          ref={inputImageRef}
                        />
                        {image ? (
                          <div className=" rounded-lg w-full ">
                            <div className=" border border-tnn_gray_50 rounded-md w-full h-[231px] relative">
                              <img
                                className="w-full h-full object-contain"
                                src={
                                  image?.size > 0
                                    ? URL.createObjectURL(image)
                                    : image
                                }
                                alt="image"
                              />
                              <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                                <HiPencil
                                  size={20}
                                  className=""
                                  onClick={() => {
                                    inputImageRef.current.click();
                                  }}
                                />
                              </div>
                              <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                                <HiTrash
                                  onClick={() => {
                                    setImage(null);
                                    inputImageRef.current.value = null;
                                  }}
                                  size={20}
                                  className=""
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => inputImageRef.current.click()}
                            className="flex flex-col items-center justify-center w-full h-[231px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio
                                16:9 / 1260px*512px
                              </p>
                            </div>
                          </div>
                        )}
                        {hasImage ? (
                          <div className=" absolute right-0">
                            <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                              *กรุณาใส่รูปภาพตัวอย่าง
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex flex-col gap-2">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          ลำดับ
                        </div>
                        <InputMain
                          type="number"
                          name="numberindex"
                          id="numberindex"
                          placeholder="1"
                          value={indexNumber}
                          onChange={(e) => setIndexNumber(e.target.value)}
                        />
                      </div>

                      <button
                        isProcessing={uploading}
                        type="submit"
                        className="bg-primary text-white rounded-full p-2"
                        disabled={uploading}
                      >
                        {uploading ? "Uploading..." : "บันทึก"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Container>
          ) : isCustomerMode ? (
            <Container>
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center">
                  <div className="text-primary text-[48px]">
                    เพิ่มรูป - ลูกค้าของเรา
                  </div>
                  <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                    Home
                  </div>
                </div>

                <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                  <form
                    className="flex flex-col gap-20"
                    onSubmit={onSubmitForm}
                  >
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2 relative">
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold text-[20px] text-tnn_gray_200">
                            รูปตัวอย่าง
                          </div>
                          <div className="text-gray-500">
                            รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                            334px*334px
                          </div>
                        </div>
                        <input
                          type="file"
                          className="hidden"
                          name="image"
                          onChange={onChangeImage}
                          ref={inputImageRef}
                        />
                        {image ? (
                          <div className=" rounded-lg w-full ">
                            <div className=" border border-tnn_gray_50 p-2 rounded-md w-full h-[231px] relative">
                              <img
                                className="w-full h-full object-contain"
                                src={
                                  image?.size > 0
                                    ? URL.createObjectURL(image)
                                    : image
                                }
                                alt="image"
                              />
                              <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                                <HiPencil
                                  size={20}
                                  className=""
                                  onClick={() => {
                                    inputImageRef.current.click();
                                  }}
                                />
                              </div>
                              <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                                <HiTrash
                                  onClick={() => {
                                    setImage(null);
                                    inputImageRef.current.value = null;
                                  }}
                                  size={20}
                                  className=""
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => inputImageRef.current.click()}
                            className="flex flex-col items-center justify-center w-full h-[231px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio
                                1:1 / 334px*334px
                              </p>
                            </div>
                          </div>
                        )}
                        {hasImage ? (
                          <div className=" absolute right-0">
                            <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                              *กรุณาใส่รูปภาพตัวอย่าง
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex flex-col gap-2">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          ลำดับ
                        </div>
                        <InputMain
                          type="number"
                          name="numberindex"
                          id="numberindex"
                          placeholder="1"
                          value={indexNumber}
                          onChange={(e) => setIndexNumber(e.target.value)}
                        />
                      </div>

                      <button
                        isProcessing={uploading}
                        type="submit"
                        className="bg-primary text-white rounded-full p-2"
                        disabled={uploading}
                      >
                        {uploading ? "Uploading..." : "บันทึก"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Container>
          ) : (
            <div>Page Not Found.</div>
          )}
        </div>
      ) : isEditMode ? (
        <div className="bg-[#f9f9f9] w-full h-full">
          {isProductMode ? (
            <Container>
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center">
                  <div className="text-primary text-[48px]">แก้ไขรูป</div>
                  <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                    Home
                  </div>
                </div>

                <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                  <form
                    className="flex flex-col gap-20"
                    onSubmit={onSubmitForm}
                  >
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2 relative">
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold text-[20px] text-tnn_gray_200">
                            รูปตัวอย่าง
                          </div>
                          <div className="text-gray-500">
                            รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 16:9 /
                            1260px*512px
                          </div>
                        </div>
                        <input
                          type="file"
                          className="hidden"
                          name="image"
                          onChange={onChangeImage}
                          ref={inputImageRef}
                        />
                        {image ? (
                          <div className=" rounded-lg w-full ">
                            <div className=" border border-tnn_gray_50 rounded-md w-full h-[231px] relative">
                              <img
                                className="w-full h-full object-contain"
                                src={
                                  image?.size > 0
                                    ? URL.createObjectURL(image)
                                    : image
                                }
                                alt="image"
                              />
                              <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                                <HiPencil
                                  size={20}
                                  className=""
                                  onClick={() => {
                                    inputImageRef.current.click();
                                  }}
                                />
                              </div>
                              <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                                <HiTrash
                                  onClick={() => {
                                    setImage(null);
                                    inputImageRef.current.value = null;
                                  }}
                                  size={20}
                                  className=""
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => inputImageRef.current.click()}
                            className="flex flex-col items-center justify-center w-full h-[231px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio
                                16:9 / 1260px*512px
                              </p>
                            </div>
                          </div>
                        )}
                        {hasImage ? (
                          <div className=" absolute right-0">
                            <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                              *กรุณาใส่รูปภาพตัวอย่าง
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex flex-col gap-2">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          ลำดับ
                        </div>
                        <InputMain
                          type="number"
                          name="numberindex"
                          id="numberindex"
                          placeholder="1"
                          value={indexNumber}
                          onChange={(e) => setIndexNumber(e.target.value)}
                        />
                      </div>

                      <button
                        isProcessing={uploading}
                        type="submit"
                        className="bg-primary text-white rounded-full p-2"
                        disabled={uploading}
                      >
                        {uploading ? "Uploading..." : "บันทึก"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Container>
          ) : isCustomerMode ? (
            <Container>
              <div className="flex flex-col gap-10">
                <div className="flex justify-between items-center">
                  <div className="text-primary text-[48px]">แก้ไขรูป</div>
                  <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
                    Home
                  </div>
                </div>

                <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
                  <form
                    className="flex flex-col gap-20"
                    onSubmit={onSubmitForm}
                  >
                    <div className="flex flex-col gap-8">
                      <div className="flex flex-col gap-2 relative">
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold text-[20px] text-tnn_gray_200">
                            รูปตัวอย่าง
                          </div>
                          <div className="text-gray-500">
                            รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio 1:1 /
                            334px*334px
                          </div>
                        </div>
                        <input
                          type="file"
                          className="hidden"
                          name="image"
                          onChange={onChangeImage}
                          ref={inputImageRef}
                        />
                        {image ? (
                          <div className=" rounded-lg w-full ">
                            <div className=" border border-tnn_gray_50 p-2 rounded-md w-full h-[231px] relative">
                              <img
                                className="w-full h-full object-contain"
                                src={
                                  image?.size > 0
                                    ? URL.createObjectURL(image)
                                    : image
                                }
                                alt="image"
                              />
                              <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                                <HiPencil
                                  size={20}
                                  className=""
                                  onClick={() => {
                                    inputImageRef.current.click();
                                  }}
                                />
                              </div>
                              <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                                <HiTrash
                                  onClick={() => {
                                    setImage(null);
                                    inputImageRef.current.value = null;
                                  }}
                                  size={20}
                                  className=""
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => inputImageRef.current.click()}
                            className="flex flex-col items-center justify-center w-full h-[231px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload
                                </span>
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                รองรับไฟล์ PNG หรือ JPEG ขนาดภาพไม่เกิน Ratio
                                1:1 / 334px*334px
                              </p>
                            </div>
                          </div>
                        )}
                        {hasImage ? (
                          <div className=" absolute right-0">
                            <div className=" bg-white border border-red-600 rounded-sm p-2 text-sm text-red-600 shadow-sm">
                              *กรุณาใส่รูปภาพตัวอย่าง
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="flex flex-col gap-2">
                        <div className="font-semibold text-[20px] text-tnn_gray_200">
                          ลำดับ
                        </div>
                        <InputMain
                          type="number"
                          name="numberindex"
                          id="numberindex"
                          placeholder="1"
                          value={indexNumber}
                          onChange={(e) => setIndexNumber(e.target.value)}
                        />
                      </div>

                      <button
                        isProcessing={uploading}
                        type="submit"
                        className="bg-primary text-white rounded-full p-2"
                        disabled={uploading}
                      >
                        {uploading ? "Uploading..." : "บันทึก"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Container>
          ) : (
            <div>Page Not Found.</div>
          )}
        </div>
      ) : (
        <div>Page Not Found.</div>
      )}
    </>
  );
};

export default FormImageValues;
