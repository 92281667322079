import React from "react";
import {
  OTHER_PRODUCT_CATEGORY_ID,
  TANK_CATEGORY_ID,
  DIAMOND_JIARANAI_TILES_ID,
} from "../../utils/constants";

const formatCartList = (dataCartList) => {
  let temp = [...dataCartList];
  let cartListGroupCategory = _.groupBy(temp, "parent_category_name");
  let result = Object.keys(cartListGroupCategory).map((key) => {
    // const myProductStr = cartListGroupCategory[key].reduce((prev, curr) => {
    //   return prev + `(${curr.product_name} ${curr.product_qty})`;
    // }, "");
    // return `${key} ${myProductStr}`;

    let cartListGroupSupCategory = _.groupBy(
      cartListGroupCategory[key],
      "category_name"
    );

    let result2 = Object.keys(cartListGroupSupCategory).map((key2) => {
      return {
        sup_category_name: key2,
        products: cartListGroupSupCategory[key2],
      };
    });

    return {
      category_name: key,
      productCategorys: result2,
    };
  });
  return result;
};

const QuotationCartList = ({ quotation }) => {
  return (
    <div className="flex flex-col gap-2">
      {formatCartList(quotation?.cart_list)?.map((category, index) => {
        return (
          <div key={index} className="border-b border-tnn_gray_50 pb-2">
            <div className="font-semibold text-primary_gray">
              {category?.category_name}
            </div>

            {category?.productCategorys?.map((productCategory) => {
              return (
                <div className="px-2 py-1">
                  {/* <div className="font-semibold">
                    {productCategory?.sup_category_name ===
                    "เดครา SENATOR SHINGLE - GERARD SHAKE"
                      ? ""
                      : `${productCategory?.sup_category_name}`}
                  </div> */}
                  <div className="font-semibold text-xs">
                    {productCategory?.sup_category_name}
                  </div>

                  {productCategory?.products?.map((product) => {
                    // console.log("product : ", product);
                    return (
                      //----------------------- สินค้า และ ครอบ -----------------------//
                      <div className="border-b py-1.5">
                        {product?.product_name && product?.cover_roof_name ? (
                          <>
                            <div className="flex justify-between">
                              <div className="flex flex-col">
                                {product?.dechra_type && (
                                  <div>{product?.dechra_type}</div>
                                )}
                                <div>
                                  สี - {product?.product_name}{" "}
                                  {product?.type_color_product
                                    ? ` (${product?.type_color_product})`
                                    : ""}
                                  {product?.polish_tiles_type
                                    ? ` (${product?.polish_tiles_type})`
                                    : ""}
                                </div>
                              </div>
                              <div className="flex flex-col justify-end">
                                {product?.product_qty}
                              </div>
                            </div>

                            {/* ครอบ */}
                            <div className="flex justify-between">
                              <div>
                                {product?.cover_roof_name} (สี
                                {product?.cover_roof_color})
                              </div>
                              <div className="flex flex-col justify-end">
                                {product?.amount_cover_roof}
                              </div>
                            </div>

                            {/* แผ่นเริ่ม */}
                            {product?.has_start_tiles && <div>มีแผ่นเริ่ม</div>}
                          </>
                        ) : //----------------------- สินค้าอย่างเดียว -----------------------//
                        product?.product_name ? (
                          product?.parent_category_name === "กระเบื้องหลังคา" ||
                          product?.main_category_id === TANK_CATEGORY_ID ? (
                            // สินค้า tank
                            <div className="flex justify-between">
                              <div className="flex flex-col">
                                <div className="flex flex-col">
                                  {product?.dechra_type && (
                                    <div>{product?.dechra_type}</div>
                                  )}

                                  <div>
                                    สี - {product?.product_name}
                                    {product?.type_color_product
                                      ? ` (${product?.type_color_product})`
                                      : ""}
                                    {product?.polish_tiles_type
                                      ? ` (${product?.polish_tiles_type})`
                                      : ""}
                                  </div>
                                </div>

                                {product?.pump_name ? (
                                  <div>{`ปั้ม - ${product?.pump_name}`}</div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div
                                className={
                                  product?.dechra_type
                                    ? "flex flex-col justify-end"
                                    : ""
                                }
                              >
                                {product?.product_qty}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="flex justify-between">
                                {product?.type_concrete_piles ? (
                                  <div>{product?.type_concrete_piles}</div>
                                ) : product?.main_category_id ===
                                  OTHER_PRODUCT_CATEGORY_ID ? (
                                  product?.color_door_product ? (
                                    <div>รหัส : {product?.product_name}</div>
                                  ) : (
                                    <div>{product?.product_name}</div>
                                  )
                                ) : product?.curr_parent_category_id ===
                                  DIAMOND_JIARANAI_TILES_ID ? (
                                  <div>สี - {product?.product_name}</div>
                                ) : (
                                  <div>{product?.product_name}</div>
                                )}

                                <div>{product?.product_qty}</div>
                              </div>

                              {/* แผ่นเริ่ม */}
                              {product?.has_start_tiles && (
                                <div>มีแผ่นเริ่ม</div>
                              )}
                            </>
                          )
                        ) : (
                          //----------------------- ครอบอย่างเดียว -----------------------//
                          <div className="flex justify-between">
                            <div className="flex flex-col">
                              {product?.category_name && (
                                <div className="font-semibold text-xs">
                                  {product?.category_name}
                                </div>
                              )}
                              <div>
                                {product?.cover_roof_name} (สี
                                {product?.cover_roof_color})
                              </div>
                            </div>

                            <div className="flex flex-col justify-end">
                              {product?.amount_cover_roof}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default QuotationCartList;
