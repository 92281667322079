export const S3_URL = "https://d3ubdgtoezipmx.cloudfront.net/public/";

export const hexColors = {
  primary: "#0e7490",
};

export const IMAGE_TYPE_LIST = ["image/png", "image/jpeg", "image/jpg"];

export const VIDEO_BACKGROUND_COVER_ID = "64d1f1641d17dc32c6d70fcc";
export const YOUTUBE_VIDEO_ID = "64d1f18b1d17dc32c6d70fce";
export const ABOUT_US_IMAGE_BANNER_ID = "64d1f59619d88687cb825c14";
export const CONTACT_US_IMAGE_BANNER_ID = "64d1feb2ad024f4e0302acf4";
export const PRODUCT_IMAGE_BANNER_ID = "64d2004815780a04bc897e61";
export const NEWS_IMAGE_BANNER_ID = "64d312e3c64796cec3ef74db";
export const RECOMMEND_PRODUCT_IMAGE_ID = "64d46e80f1d4c4099e89e670";
export const CUSTOMER_PRODUCT_IMAGE_ID = "64d46eb0f1d4c4099e89e672";

// Mian Category
export const CEMENT_CATEGORY_ID = "64d3230f92e632cfcf7be58c";
export const CONCRETE_CATEGORY_ID = "64d3251c92e632cfcf7be5a0";
export const PUMP_CATEGORY_ID = "64d3255f92e632cfcf7be5aa";
export const TANK_CATEGORY_ID = "64d3254392e632cfcf7be5a4";
export const DIAMOND_CATEGORY_ID = "64d3256e92e632cfcf7be5ae";
export const DIAMOND_BOARD_ID = "652cc2fee6867d835d1b4046";
export const DIAMOND_SYNTHETIC_WOOD_ID = "652cc3e0f9d95317b7642cad";
export const OTHER_PRODUCT_CATEGORY_ID = "64d3257492e632cfcf7be5b2";

// Sub Category OtherProducts
export const OTHER_SANITARY_EQUIPMENT_ID = "652dfa5683661ca13a51b77f";
export const OTHER_CHEMICAL_PRODUCT_ID = "652e0ed1e491c792057d42b2";
export const OTHER_DOOR_AND_FRAMEDOOR_ID = "652e0ee8e491c792057d4389";

// Sub Category Level 3 ของผลิตภัณฑ์สุขภัณฑ์
export const SANITARY_WARE_ID = "652f1e6371694c16810ddf37";
export const HYDRANT_ID = "652f1e8871694c16810ddf49";
export const SHOWER_SET_ID = "652f1ea371694c16810ddf5e";
export const BATH_TUB_ID = "652f1ed571694c16810ddf76";
export const WATER_HEATER_ID = "652f1ef071694c16810ddf91";
export const WASH_BASIN_ID = "652f1f2771694c16810ddfaf";
export const HOSE_CLEANER_ID = "652f1f7b71694c16810ddfd0";
export const BATHROOM_ACCESSORIES_ID = "652f1f9c71694c16810ddff4";

// Sub Category Level 3 ของผลิตภัณฑ์ประตู/วงกบ
// 1. ประตูสำหรับใช้ภายนอกและภายใน
export const DOOR_EXTERIOR_AND_INTERIOR_USE_ID = "652f21ce71694c16810de2a8";
// 2. ประตูสำหรับใช้ภายในเท่านั้น
export const DOOR_INTERIOR_USE_ONLY_ID = "652f220171694c16810de2c9";
// 3. วงกบประตู
export const FRAME_EXTERIOR_AND_INTERIOR_USE_ID = "652f225671694c16810de2de";
// 4. ประตูสำหรับห้องน้ำ
export const DOOR_FOR_BATHROOM_ID = "652f228371694c16810de2f6";

// Concrete Piles
export const CONCRETE_PILES_ID = "64ec125200befebb76e8a6f0";
export const I_PILES_ID = "6512a6e9ee979b9137e4e24e";
export const SQUARE_PILES_ID = "65139e4296de2e04cc76bc21";
export const HOLLOW_HEXAGONAL_PILES_ID = "651bc5863790829683e0c286";

// Group Cement / Mortar
export const GROUP_CEMENT = [
  {
    label: "ปูนซีเมนต์",
    value: "cement",
  },
  {
    label: "ปูนซีเมนต์สำเร็จรูป (มอร์ตาร์)",
    value: "mortar",
  },
];

// Preset Size Concrete Product
export const PRESET_SIZE_CONCRETE_LIST = [
  {
    id: "64e6e0b0c0d6ace19d470fb0",
    name: "ท่อคอนกรีต",
    presetSize: [
      {
        size_name: "30x100 ซม.",
      },
      {
        size_name: "40x100 ซม.",
      },
      {
        size_name: "50x100 ซม.",
      },
      {
        size_name: "60x100 ซม.",
      },
      {
        size_name: "80x100 ซม.",
      },
      {
        size_name: "100x100 ซม.",
      },
      {
        size_name: "120x100 ซม.",
      },
      {
        size_name: "150x100 ซม.",
      },
    ],
  },
  {
    id: "64ec11c000befebb76e8a6dd",
    name: "บ่อพักสำเร็จรูป",
    presetSize: [
      {
        size_name: "30 ซม.",
      },
      {
        size_name: "40 ซม.",
      },
      {
        size_name: "50 ซม.",
      },
      {
        size_name: "60 ซม.",
      },
      {
        size_name: "80 ซม.",
      },
      {
        size_name: "100 ซม.",
      },
      {
        size_name: "120 ซม.",
      },
      {
        size_name: "150 ซม.",
      },
    ],
  },
  {
    id: "64ec120a00befebb76e8a6e3",
    name: "แผ่นพื้นสำเร็จรูป",
    presetSize: [
      {
        size_name: "ลวด 4 เส้น(1M.)",
      },
      {
        size_name: "ลวด 4 เส้น(1.5M.)",
      },
      {
        size_name: "ลวด 4 เส้น(2M.)",
      },
      {
        size_name: "ลวด 4 เส้น(2.5M.)",
      },
      {
        size_name: "ลวด 5 เส้น(3M.)",
      },
      {
        size_name: "ลวด 5 เส้น(3.5M.)",
      },
      {
        size_name: "ลวด 6 เส้น(4M.)",
      },
      {
        size_name: "ลวด 7 เส้น(4.5M.)",
      },
      {
        size_name: "ลวด 8 เส้น(5M.)",
      },
    ],
  },
  {
    id: "64ec125200befebb76e8a6f0",
    name: "เสาเข็ม",
  },
  {
    id: "6512a6e9ee979b9137e4e24e",
    name: "เสาเข็มไอ",
    presetSize: [
      {
        size_name: "I15",
      },
      {
        size_name: "I18",
      },
      {
        size_name: "I22",
      },
      {
        size_name: "I26",
      },
      {
        size_name: "I30",
      },
      {
        size_name: "I35",
      },
    ],
  },
  {
    id: "65139e4296de2e04cc76bc21",
    name: "เสาเข็มเหลี่ยม",
    presetSize: [
      {
        size_name: "SQ15",
      },
      {
        size_name: "SQ18",
      },
      {
        size_name: "SQ22",
      },
      {
        size_name: "SQ26",
      },
      {
        size_name: "SQ30",
      },
      {
        size_name: "SQ35",
      },
      {
        size_name: "SQ40",
      },
    ],
  },
  {
    id: "651bc5863790829683e0c286",
    name: "เสาเข็มหกเหลี่ยมกลวง",
    presetSize: [
      {
        size_name: "2 เมตร",
      },
      {
        size_name: "3 เมตร",
      },
      {
        size_name: "4 เมตร",
      },
      {
        size_name: "5 เมตร",
      },
      {
        size_name: "6 เมตร",
      },
    ],
  },
  {
    id: "64ec128300befebb76e8a6f6",
    name: "Box Curve",
    presetSize: [
      {
        size_name: "120x120 ซม.",
      },
      {
        size_name: "150x150 ซม.",
      },
      {
        size_name: "180x180 ซม.",
      },
      {
        size_name: "210x210 ซม.",
      },
      {
        size_name: "210x240 ซม.",
      },
      {
        size_name: "240x240 ซม.",
      },
    ],
  },
  {
    id: "64ec12b500befebb76e8a6fc",
    name: "รั้ว",
  },
  {
    id: "64ec12f100befebb76e8a709",
    name: "อื่น ๆ",
  },
];

// Type Pump
export const MITSUBISHI_ID = "651e7af832bc2ba28e221781";
export const HITACHI_ID = "651e7b2332bc2ba28e2217ca";
export const GRUNDFOS_ID = "651e7b3932bc2ba28e2217f6";

// Sub Category Tank
export const SEPTIC_TANK_ID = "6523745283c47b53a6f99086";
export const DIAMOND_SEPTIC_TANK_ID = "652374be83c47b53a6f990ae";
export const GREASE_TRAP_TANK_ID = "652374fd83c47b53a6f990d9";
export const ABOVE_GROUND_TANK_ID = "6523754383c47b53a6f99107";
export const NATURA_WATER_PAC_ID = "6523cf794923654fa8e1d238";

// Sub Category Diamond Product
export const DIAMOND_ROOF_TILES_ID = "652653ddf36467942b170969";
export const DIAMOND_TOOLS_ID = "65265457f36467942b170ac0";
export const DIAMOND_DOUBLE_WAVE_ID = "652664259f7c7f0b7f18bc03";

export const DIAMOND_DECHRA_SENATOR_SHINGLE_ID = "652665c19f7c7f0b7f18bda0";
export const DIAMOND_DECHRA_MILANO_ID = "652c9ecc5feb538f9d036b5f";

// Sub Category Diamond Jiaranai Product
export const DIAMOND_JIARANAI_TILES_ID = "6590feedff94d94a1ed66a21";
export const THAI_MODERN_ID = "65917bba0755473ca36080eb";
export const THAI_CLASSIC_ID = "65917e5f0755473ca3608281";
export const ELEGANT_ID = "65917e8b0755473ca3608298";

// Sub Diamond Board
export const DIAMOND_PRINTED_WALL_DECORATION_BOARD_ID =
  "652ccde134741ac031de373c";
export const DIAMOND_CEILING_AND_VENTILATED_CEILING_ID =
  "652cce8934741ac031de3c37";
export const DIAMOND_GYPSUM_BOARD_ID = "652cce2334741ac031de3871";

// Color Type List
export const COLOR_TYPE_LIST = [
  { label: "สีมาตรฐาน", value: "standard_color" },
  { label: "สีเมทัลลิค", value: "metallic_color" },
  { label: "สีแพลตตินัม", value: "platinum_color" },
  { label: "พิเศษ", value: "special_color" },
  { label: "พรีเมี่ยม", value: "premium_color" },
];

// Color Type List Of Dechra
export const COLOR_TYPE_LIST_OF_DECHRA = [
  { label: "SENATOR SHINGLE", value: "senator_shingle" },
  { label: "GERARD SHAKE", value: "gerard_shake" },
];
