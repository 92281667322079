export const MAX_W_1024 = "max-w-[1024px] mx-auto";

export const MAX_W_1062 = "max-w-[1062px] mx-auto";

export const STYLE_CARD = "bg-white rounded-xl p-10 shadowcard";

export const STYLE_CARD_PARENT = "bg-white px-10 py-8 rounded-2xl shadowcard";

export const STYLE_CARD_PARENT_ADD =
  "bg-white px-10 py-8 rounded-2xl shadowcardadd";

export const STYLE_BG_CONTAIN =
  "bg-fixed  w-full min-h-screen object-cover relative";

export const STYLE_LIST_ITEM =
  "py-1.5 px-2.5 group rounded-full  font-normal text-tnn_gray_200 hover:bg-primary hover:text-white focus:bg-primary focus:text-white w-[178px] h-10";
export const STYLE_LIST_ITEMPREFIX = "mr-2";
export const STYLE_ICON_LIST_ITEMPREFIX =
  "group-hover:text-white group-focus:text-white text-tnn_gray_200";
export const STYLE_LIST_ITEM_ACCORDION =
  "p-0 m-0 w-fit bg-transparent hover:underline-offset-4 hover:underline hover:bg-transparent group hover:text-primary";
export const STYLE_LIST_ITEM_ACCORDIONBODY =
  "ml-[15px] text-xs font-NotoSansThai w-fit  bg-transparent hover:underline-offset-4 hover:underline hover:bg-transparent hover:text-primary ";
export const STYLE_ACCORDION_BODY = " py-0 font-NotoSansThai ";
export const STYLE_ACCORDION_BODY_LIST = "flex flex-col gap-0";

export const STYLE_TYPOGRAPHY =
  "mr-auto font-normal text-tnn_gray_200 group-hover:text-white focus:text-white";
export const STYLE_ACCORDION_HEADER_ACTIVE =
  "text-sm text-primary font-medium border-b-0";
export const STYLE_ACCORDION_HEADER_INACTIVE =
  "text-primary_gray border-b-0 font-light";
export const STYLE_ACCORDION_HEADER_TYPO_ACTIVE = "text-primary font-semibold";
export const STYLE_ACCORDION_HEADER_TYPO_INACTIVE = "text-primary_gray";
export const STYLE_ACCORDION_HEADER_TYPO =
  "text-sm font-NotoSansThai group-hover:text-primary";
export const STYLE_ACCORDION_HEADER =
  "border-b-0 group-hover:text-white group-focus:text-white text-tnn_gray_200";
