import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppApi } from "../../api";
import useSweetAlert from "../../_hooks/useSweetAlert";
import Container from "../../components/Container";
import { InputMain, InputMainTextArea } from "../../components/Input/inputmain";

const FormUser = () => {
  const navigate = useNavigate();

  const { alertSuccess, alertError } = useSweetAlert();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const clearForm = () => {
    setUsername("");
    setPassword("");
    setRole("");
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (role === "") {
      alertError("Warning. . .", "กรุณาเลือก Role (admin) or (user)");
    }
    if (password.length < 6) {
      alertError("Warning. . .", "กรุณากรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร");
    }
    try {
      const payload = {
        username,
        password,
        role,
      };

      await AppApi.post(`users`, payload);

      clearForm();
      e.target.reset();

      alertSuccess("Create User Success", "", () => navigate("/user"));
    } catch (error) {
      console.log(error);
    }
  };

  const DropdownSelect = () => {
    const handleSelectChange = (event) => {
      setRole(event.target.value);
    };

    return (
      <select
        value={role}
        onChange={handleSelectChange}
        className="border-2 p-2 rounded-xl"
      >
        <option value="">Select a Role</option>
        <option value="admin">admin</option>
        <option value="user">user</option>
      </select>
    );
  };

  return (
    <Container>
      <form onSubmit={onSubmitForm} className="flex flex-col gap-4">
        <div className="text-xl font-bold text-right">Create user</div>

        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            <div className="font-bold">Username</div>
            <InputMain
              name="username"
              id="username"
              placeholder="username. . ."
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="font-bold">Password</div>
            <InputMain
              name="password"
              id="password"
              placeholder="password. . ."
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <DropdownSelect />
        </div>

        <div className="flex justify-end mt-6">
          <button
            className="border-2 border-primary w-40 bg-primary rounded-full p-2 text-white hover:bg-white hover:text-primary duration-300"
            type="submit"
          >
            Create
          </button>
        </div>
      </form>
    </Container>
  );
};

export default FormUser;
