export const ICON_OVERVIEW = ({ className, onClick }) => {
  <svg
    onClick={onClick}
    className={className}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 6.5C15.4926 6.5 16.5 5.49264 16.5 4.25C16.5 3.00736 15.4926 2 14.25 2C13.0074 2 12 3.00736 12 4.25C12 5.49264 13.0074 6.5 14.25 6.5Z"
      stroke="CurrentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 10.25H9"
      stroke="CurrentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 13.25H12"
      stroke="CurrentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 2H6.75C3 2 1.5 3.5 1.5 7.25V11.75C1.5 15.5 3 17 6.75 17H11.25C15 17 16.5 15.5 16.5 11.75V8"
      stroke="CurrentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>;
};
export const ICON_USER = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M15.1166 18.5166C14.3833 18.7333 13.5166 18.8333 12.5 18.8333H7.49997C6.4833 18.8333 5.61663 18.7333 4.8833 18.5166C5.06663 16.35 7.29163 14.6416 9.99997 14.6416C12.7083 14.6416 14.9333 16.35 15.1166 18.5166Z"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5001 2.1665H7.50008C3.33341 2.1665 1.66675 3.83317 1.66675 7.99984V12.9998C1.66675 16.1498 2.61675 17.8748 4.88342 18.5165C5.06675 16.3498 7.29175 14.6415 10.0001 14.6415C12.7084 14.6415 14.9334 16.3498 15.1167 18.5165C17.3834 17.8748 18.3334 16.1498 18.3334 12.9998V7.99984C18.3334 3.83317 16.6667 2.1665 12.5001 2.1665ZM10.0001 12.3082C8.35008 12.3082 7.01675 10.9665 7.01675 9.31652C7.01675 7.66652 8.35008 6.33317 10.0001 6.33317C11.6501 6.33317 12.9834 7.66652 12.9834 9.31652C12.9834 10.9665 11.6501 12.3082 10.0001 12.3082Z"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9833 9.31684C12.9833 10.9668 11.6499 12.3085 9.99994 12.3085C8.34994 12.3085 7.0166 10.9668 7.0166 9.31684C7.0166 7.66684 8.34994 6.3335 9.99994 6.3335C11.6499 6.3335 12.9833 7.66684 12.9833 9.31684Z"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ICON_LOGOUT = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M6.67505 6.17018C6.90755 3.47018 8.29505 2.36768 11.3325 2.36768H11.43C14.7825 2.36768 16.125 3.71018 16.125 7.06268V11.9527C16.125 15.3052 14.7825 16.6477 11.43 16.6477H11.3325C8.31755 16.6477 6.93005 15.5602 6.68255 12.9052"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2501 9.5H2.71509"
        stroke="CurrentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.3875 6.9873L1.875 9.49981L4.3875 12.0123"
        stroke="CurrentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ICON_EDIT = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33337 1.33325H6.00004C2.66671 1.33325 1.33337 2.66659 1.33337 5.99992V9.99992C1.33337 13.3333 2.66671 14.6666 6.00004 14.6666H10C13.3334 14.6666 14.6667 13.3333 14.6667 9.99992V8.66659"
        stroke="CurrentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6934 2.01326L5.44004 7.26659C5.24004 7.46659 5.04004 7.85992 5.00004 8.14659L4.71337 10.1533C4.60671 10.8799 5.12004 11.3866 5.84671 11.2866L7.85337 10.9999C8.13337 10.9599 8.52671 10.7599 8.73338 10.5599L13.9867 5.30659C14.8934 4.39992 15.32 3.34659 13.9867 2.01326C12.6534 0.679924 11.6 1.10659 10.6934 2.01326Z"
        stroke="CurrentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.93994 2.7666C10.3866 4.35993 11.6333 5.6066 13.2333 6.05993"
        stroke="CurrentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ICON_DELETE = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66663 3.31325L5.81329 2.43992C5.91996 1.80659 5.99996 1.33325 7.12663 1.33325H8.87329C9.99996 1.33325 10.0866 1.83325 10.1866 2.44659L10.3333 3.31325"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5667 6.09326L12.1334 12.8066C12.06 13.8533 12 14.6666 10.14 14.6666H5.86002C4.00002 14.6666 3.94002 13.8533 3.86668 12.8066L3.43335 6.09326"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88672 11H9.10672"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33337 8.33325H9.66671"
        stroke="CurrentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ICON_ADD = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clip-path="url(#clip0_4588_17952)">
        <path
          d="M8.5 16H5.93C3.64 16 2.5 14.86 2.5 12.57V5.43C2.5 3.14 3.64 2 5.93 2H10.5C12.79 2 13.93 3.14 13.93 5.43"
          stroke="CurrentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0699 22H14.4999C12.2099 22 11.0699 20.86 11.0699 18.57V11.43C11.0699 9.14 12.2099 8 14.4999 8H19.0699C21.3599 8 22.4999 9.14 22.4999 11.43V18.57C22.4999 20.86 21.3599 22 19.0699 22Z"
          stroke="CurrentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.37 15H18.63"
          stroke="CurrentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 16.6301V13.3701"
          stroke="CurrentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4588_17952">
          <rect
            width="24"
            height="24"
            fill="CurrentColor"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ICON_EXPORT_QUOTATION = ({ className }) => {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g filter="url(#filter0_d_5032_66248)">
        <path
          d="M10.3203 11.6799L12.8803 14.2399L15.4403 11.6799"
          stroke="CurrentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.8828 4V14.17"
          stroke="CurrentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 12.1799C21 16.5999 18 20.1799 13 20.1799C8 20.1799 5 16.5999 5 12.1799"
          stroke="CurrentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5032_66248"
          x="0.25"
          y="3.25"
          width="25.5"
          height="25.6799"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5032_66248"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5032_66248"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const ICON_LOADING = ({ className }) => {
  return (
    <div aria-label="Loading..." role="status">
      <svg
        className={`${className} animate-spin stroke-gray-500`}
        viewBox="0 0 256 256"
      >
        <line
          x1="128"
          y1="32"
          x2="128"
          y2="64"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="195.9"
          y1="60.1"
          x2="173.3"
          y2="82.7"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="224"
          y1="128"
          x2="192"
          y2="128"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="195.9"
          y1="195.9"
          x2="173.3"
          y2="173.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="128"
          y1="224"
          x2="128"
          y2="192"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="60.1"
          y1="195.9"
          x2="82.7"
          y2="173.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="32"
          y1="128"
          x2="64"
          y2="128"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <line
          x1="60.1"
          y1="60.1"
          x2="82.7"
          y2="82.7"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
      </svg>
    </div>
  );
};
