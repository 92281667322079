import { useEffect, useRef, useState } from "react";
import {
  VIDEO_BACKGROUND_COVER_ID,
  YOUTUBE_VIDEO_ID,
} from "../../utils/constants";
import { AppApi } from "../../api";
import { TypeFile, s3File } from "../../utils/fileFormater";
import { Storage } from "aws-amplify";
import useSweetAlert from "../../_hooks/useSweetAlert";
import { HiPencil, HiTrash } from "react-icons/hi2";
import Container from "../../components/Container";

const checkVideoResolution = async (e) => {
  return new Promise((resolve, reject) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");

    video.onloadedmetadata = () => {
      // Revoke when you don't need the URL anymore to release any reference
      URL.revokeObjectURL(url);

      const width = video.videoWidth;
      const height = video.videoHeight;

      if (width > 1440 || height > 1024) {
        resolve(true);
      } else {
        resolve(false);
      }
    };

    video.onerror = () => {
      // Handle any potential errors
      reject("Error loading video metadata");
    };

    video.src = url;
    video.load(); // Fetches metadata
  });
};

const FormVideoBanner = () => {
  const { alertSuccess, alertError, alertConfirm } = useSweetAlert();

  const inputVideoRef = useRef();

  const videoRef = useRef();

  const [video, setVideo] = useState(null);
  const [youtubeVideo, setYouTubeVideo] = useState("");

  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadingYoutubeVideo, setUploadingYoutubeVideo] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      const load = async () => {
        try {
          const { data } = await AppApi.get(
            `media/${VIDEO_BACKGROUND_COVER_ID}`
          );

          const { data: youtubeData } = await AppApi.get(
            `media/${YOUTUBE_VIDEO_ID}`
          );

          // setVideo(s3File(data.media.value));
          setYouTubeVideo(youtubeData.media.value);
        } catch (error) {
          console.log(error);
        }
      };
      load();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const onChangeVideo = async (e) => {
    if (!e.target.value) return;

    const type = e.target.files[0].type;

    if (!["video/mp4"].includes(type)) {
      alertError("รองรับไฟล์ MP4");
      return;
    }

    const resolutionError = await checkVideoResolution(e);

    if (resolutionError) {
      alertError("รองรับวิดีโอความกว้างไม่เกิน 16:9 / 1440x1024");
      return;
    }

    setVideo(e.target.files[0]);
  };

  const onSaveVideo = async (e) => {
    setUploadingVideo(true);
    try {
      const keyUpload = `${VIDEO_BACKGROUND_COVER_ID}.${TypeFile[video.type]}`;

      await Storage.put(keyUpload, video);

      const payload = {
        value: keyUpload,
      };

      await AppApi.put(`media/${VIDEO_BACKGROUND_COVER_ID}`, payload);

      alertSuccess("อัพโหลดสำเร็จ");

      const { data } = await AppApi.get(`media/${VIDEO_BACKGROUND_COVER_ID}`);

      setVideo(s3File(data.media.value));
    } catch (error) {
      console.log(error);
      alertError("อัพโหลดไม่สำเร็จ");
    } finally {
      setUploadingVideo(false);
    }
  };

  const onSaveYoutubeVideo = async () => {
    setUploadingYoutubeVideo(true);
    try {
      let payload = {
        value: youtubeVideo,
      };

      await AppApi.put(`media/${YOUTUBE_VIDEO_ID}`, payload);

      alertSuccess("อัพโหลดสำเร็จ");

      const { data } = await AppApi.get(`media/${YOUTUBE_VIDEO_ID}`);

      setYouTubeVideo(data.media.value);
    } catch (error) {
      console.log(error);
      alertError("อัพโหลดไม่สำเร็จ");
    } finally {
      setUploadingYoutubeVideo(false);
    }
  };

  return (
    <div className="bg-[#f9f9f9] w-full h-full">
      <Container>
        <div className="flex flex-col gap-10">
          <div className="flex justify-between items-center">
            <div className="text-primary text-[48px]">แก้ไข Video</div>
            <div className="text-gray-500 bg-white border-2 rounded-full text-center p-2 w-48">
              Home
            </div>
          </div>

          <div className="bg-white p-8 rounded-2xl drop-shadow-lg">
            <form className="flex flex-col gap-10">
              {/* <div className="flex flex-col gap-4">
                <div className="text-primary font-bold text-[24px]">
                  Video Banner
                </div>
                <div className="text-gray-500">
                  ขนาดวิดีโอ 16:9 / 1440px*1024px ความยาววิดีโอควรอยู่ระหว่าง 10
                  - 15 วินาที
                </div>
                <div>
                  <input
                    type="file"
                    className="hidden"
                    name="image"
                    onChange={onChangeVideo}
                    ref={inputVideoRef}
                  />
                  {video ? (
                    <div className=" rounded-lg w-full">
                      <div className="relative rounded-lg p-2 w-full h-[231px] border border-tnn_gray_50">
                        <video
                          src={
                            video?.size > 0 ? URL.createObjectURL(video) : video
                          }
                          className="w-full h-full object-contain"
                          controls
                          ref={videoRef}
                        />
                        <div className="absolute -top-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-gray-500 hover:text-gray-500 cursor-pointer z-10">
                          <HiPencil
                            size={20}
                            className=""
                            onClick={() => {
                              inputVideoRef.current.click();
                            }}
                          />
                        </div>
                        <div className="absolute -bottom-3 -right-3 bg-white text-gray-700 shadow border-2 p-2 rounded-full hover:border-red-700 hover:text-red-700  cursor-pointer z-10">
                          <HiTrash
                            onClick={() => {
                              setVideo(null);
                              inputVideoRef.current.value = null;
                            }}
                            size={20}
                            className=""
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => inputVideoRef.current.click()}
                      className="flex flex-col items-center justify-center w-full h-[231px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          16:9 Aspect Ratio (MAX SIZE. 20MB)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    isProcessing={uploadingVideo}
                    className="bg-primary text-white rounded-full p-2 w-60"
                    onClick={onSaveVideo}
                    disabled={uploadingVideo}
                  >
                    {uploadingVideo ? "Uploading..." : "บันทึก"}
                  </button>
                </div>
              </div> */}

              <div className="flex flex-col gap-4">
                <div className="text-primary font-bold text-[24px]">
                  Youtube
                </div>
                <div className="text-gray-500">
                  จะแสดงโชว์ในหน้าแรกตรงส่วนเกี่ยวกับธนานันท์
                </div>
                <div className="flex flex-col gap-2">
                  <div className="font-semibold">Link Youtube</div>
                  <input
                    name="link youtube"
                    placeholder="เช่น https://www.youtube.com"
                    required
                    value={youtubeVideo}
                    className="border-2 p-2 rounded-lg"
                    onChange={(e) => setYouTubeVideo(e.target.value)}
                  />
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    isProcessing={uploadingYoutubeVideo}
                    className="bg-primary text-white rounded-full p-2 w-60"
                    onClick={onSaveYoutubeVideo}
                    disabled={uploadingYoutubeVideo}
                  >
                    {uploadingYoutubeVideo ? "Uploading..." : "บันทึก"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FormVideoBanner;
