import axios from "axios";

// const API_URL = "http://localhost:1337";

const API_URL =
  "https://1m6v7vgr5m.execute-api.ap-southeast-1.amazonaws.com/dev";

//https://1m6v7vgr5m.execute-api.ap-southeast-1.amazonaws.com/dev

const instance = axios.create({
  baseURL: API_URL,
});

export const AppApi = {
  get: (url, params) => instance.get(url, { params }),
  post: (url, data) => instance.post(url, data),
  patch: (url, data) => instance.patch(url, data),
  put: (url, data) => instance.put(url, data),
  delete: (url) => instance.delete(url),
};

export default instance;
