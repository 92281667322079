export const InputMain = ({
  title,
  type = "text",
  name,
  id,
  placeholder,
  value,
  className = "border border-tnn_gray_50 focus:outline-none focus:right-1 focus:border-tnn_gray_200 rounded-[10px] block w-full px-5 py-2 placeholder:text-tnn_gray_50 text-tnn_gray_200 bg-white text-base",
  onChange,
  error,
}) => {
  if (error) {
    className =
      "border-2 border-red-600 focus:outline-none focus:right-1 focus:border-tnn_gray_200 rounded-[10px] block w-full px-5 py-2 placeholder:text-tnn_gray_50 text-tnn_gray_200 bg-white text-base";
  }

  let numberProps = {};

  if (type === "number") {
    numberProps = {
      onWheel: (e) => {
        // e.preventDefault()
        e.target.blur();
      },
    };
  }

  return (
    <div>
      <label
        htmlFor={id}
        className=" mb-[5px] text-sm font-normal text-primary"
      >
        {title}
      </label>
      <input
        {...numberProps}
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        id={id}
        className={className}
        placeholder={placeholder}
        required
      />
    </div>
  );
};

export const InputMainTextArea = ({
  title,
  type = "text",
  name,
  id,
  placeholder,
  value,
  className = "border border-tnn_gray_50 focus:outline-none focus:right-1 focus:border-tnn_gray_200 rounded-[10px] block w-full px-5 py-2 placeholder:text-tnn_gray_50 text-tnn_gray_200 bg-white text-base",
  onChange,
  error,
  rows = 2,
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className=" mb-[5px] text-sm font-normal text-primary"
      >
        {title}
      </label>
      <textarea
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        id={id}
        className={className}
        placeholder={placeholder}
        required
        rows={rows}
      />
    </div>
  );
};
