import { Navigate, Outlet } from "react-router-dom";
import { useUserStore } from "../_store/userStore";

const ProtectedRoute = () => {
  const user = useUserStore((state) => state.user);
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
